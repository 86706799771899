import { ajaxJson } from '@global-js/utils/ajax';

class KeepConnect {
  constructor() {
    this.switcher = document.getElementById('js-security-keepConnect');
    this.keepConnect = document.getElementById('js-keep-connect');
  }

  init() {
    this.switcher.addEventListener('click', (e) => {
      if (e.target.id === 'js-security-keepConnect-yes') {
        ajaxJson('/ajax/remember-me');
      }

      this.keepConnect.classList.add('is-hidden');
    });
  }
}

export default KeepConnect;
