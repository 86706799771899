export const CONFIG = {
  slider: '.slider',
  wrapper: '.slider__wrapper',
  slide: '.slider__slide',
  prev: '.slider__button-prev',
  next: '.slider__button-next',
  dot: '.slider__controls-dot',
};

export const VISIBLE_NAVIGATION = 'slider-navigation--visible';
export const ACTIVE_DOT_NAVIGATION = 'slider__controls-dot--active';

/**
 * @param {HTMLElement} wrapper
 * @return {number}
 */
export const getGap = (wrapper) => {
  const rowGap = window.getComputedStyle(wrapper).rowGap.split('px')[0];

  return parseInt(rowGap, 10);
};

/**
 * @param {HTMLElement} slider
 * @param {string} selector
 * @param {boolean} isHidden
 */
export const hideNavigation = (slider, selector, isHidden) => {
  const navigationElement = slider.querySelector(selector);

  navigationElement.classList.toggle(VISIBLE_NAVIGATION, !isHidden);
  navigationElement.setAttribute('aria-disabled', isHidden ? 'true' : 'false');
  navigationElement.setAttribute('tabindex', isHidden ? '-1' : '0');
};

/**
 * @param {HTMLElement} slider
 * @param {HTMLElement} nextDot
 */
export const selectDotNavigation = (slider, nextDot) => {
  const currentDot = slider.querySelector(`.${ACTIVE_DOT_NAVIGATION}`);
  currentDot.classList.remove(ACTIVE_DOT_NAVIGATION);
  nextDot.classList.add(ACTIVE_DOT_NAVIGATION);

  currentDot?.setAttribute('aria-selected', 'false');
  currentDot?.setAttribute('tabindex', '-1');

  nextDot?.setAttribute('aria-selected', 'true');
  nextDot?.setAttribute('tabindex', '0');

  const currentSlide = slider.querySelector(`${CONFIG.slide}[data-slide="${currentDot.dataset.slide}"]`);
  currentSlide.setAttribute('aria-hidden', 'true');

  const nextSlide = slider.querySelector(`${CONFIG.slide}[data-slide="${nextDot.dataset.slide}"]`);
  nextSlide.setAttribute('aria-hidden', 'false');
};

/**
 * @param {HTMLElement} wrapper
 * @return {boolean}
 */
export const isSliderAtTheStart = (wrapper) => wrapper.scrollLeft === 0;

/**
 * @param {HTMLElement} wrapper
 * @return {boolean}
 */
export const isSliderAtTheEnd = (wrapper) => {
  try {
    const lastSlide = wrapper.querySelector('.slider__slide:last-of-type');
    const rect = lastSlide.getBoundingClientRect();
    const parentRect = lastSlide.offsetParent.getBoundingClientRect();

    const lastSlideRelativeLeft = rect.left - parentRect.left;

    return lastSlideRelativeLeft <= wrapper.parentNode.offsetWidth - lastSlide.offsetWidth;
  } catch (e) {
    return true;
  }
};
