import { toggleFilter } from '../../../../../global/assets/scripts/modules/filter/filter';
import { filterCards } from '../../../../../global/assets/scripts/modules/filter/filterCards';

const CONFIG = { element: null, buttons: null };

/**
 * @private
 */
const _onClickSwitch = () => {
  CONFIG.element.setAttribute('data-open', !(CONFIG.element.dataset.open === 'true'));
};

/**
 * @param {string} filterName
 * @private
 */
const _dispatchTrackingEvents = (filterName) => {
  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: {
      type: 'chapter',
      data: { action: 'theme', name: filterName },
    },
  }));
};

/**
 * @param {HTMLElement} target
 */
const onClickBtn = ({ target }) => {
  const filters = toggleFilter(target, '.selector-tag__btn');
  const filterName = target.dataset.filter;

  document.querySelectorAll('.filters-v2__filter[data-filter="all"]').forEach((filter) => filter.click());

  filterCards(filters, '.content--v2', Object.keys(filters), true);
  _dispatchTrackingEvents(filterName);
};

export const SelectorTag = () => {
  CONFIG.element = document.querySelector('.selector-tag');

  if (CONFIG.element === null) return;

  CONFIG.buttons = document.querySelectorAll('.selector-tag__btn');
  CONFIG.buttons.forEach((btn) => btn.addEventListener('click', onClickBtn, false));

  const switchTag = document.querySelector('.selector-tag__switch');
  switchTag?.addEventListener('click', _onClickSwitch, false);
};
