class MobileDescription {
  static init() {
    const descriptionMsp = document.querySelector('.description-msp');
    const desktopDescription = document.getElementById('desktopDescription');
    const mobileDescription = document.getElementById('mobileDescription');

    if (descriptionMsp === null) {
      return;
    }

    if (window.innerWidth < 576) {
      descriptionMsp.removeChild(desktopDescription);
      mobileDescription.appendChild(desktopDescription);
    }
  }
}

export default MobileDescription;
