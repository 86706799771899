import { FormValidation } from '../../../molecules/security/form/validation';
import { RegistrationConditions } from '../../../molecules/security/registration_conditions/registration_conditions';
import { PasswordVisibility } from '../../../molecules/security/form/password-visibility';

const inputs = {
  password: '#form_password',
};

export const FormResetPassword = () => {
  if (!document.querySelector('.formResetPasswordV2')) return;

  FormValidation(inputs);
  RegistrationConditions();
  PasswordVisibility();
};
