const CONFIG = {
  eyeIcon: '.input__password-eye-icon',
  eyeIconHidden: 'input__password-eye-icon--hidden',
  state: {
    visible: {
      icon: 'visible.svg',
      attr: 'Masquer le mot de passe',
      type: 'text',
    },
    hidden: {
      icon: 'hidden.svg',
      attr: 'Afficher le mot de passe',
      type: 'password',
    },
  },
};

/**
 * @param {HTMLElement} currentTarget
 */
const onClickEyeIcon = ({ currentTarget }) => {
  const isHidden = currentTarget.classList.contains(CONFIG.eyeIconHidden);
  const state = isHidden ? CONFIG.state.visible : CONFIG.state.hidden;

  currentTarget.src = `/images/icons/${state.icon}`;
  currentTarget.setAttribute('alt', state.attr);
  currentTarget.classList.toggle(CONFIG.eyeIconHidden, !isHidden);

  const input = currentTarget.parentNode.querySelector('input');
  input.setAttribute('type', state.type);
  input.focus();
};

export const PasswordVisibility = () => {
  const eyeIcon = document.querySelector(CONFIG.eyeIcon);
  eyeIcon.addEventListener('click', onClickEyeIcon, false);
};
