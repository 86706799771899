export const inputData = () => [
  {
    input: document.getElementById('js-security-email'),
    label: document.getElementById('js-email-label'),
    error: document.getElementById('js-security-email-error'),
    errorEmpty: document.getElementById('js-security-email-error-empty'),
    errorGin: document.getElementById('js-security-mail-gin-error'),
    info: 'mail',
  },
  {
    input: document.getElementById('js-security-password'),
    label: document.getElementById('js-password-label'),
    info: 'password',
    icon: document.getElementById('js-security-password-eye-icon'),
    error: {
      error: document.getElementById('js-password-error'),
      is8: document.getElementById('js-password-error-is8'),
      isMaj: document.getElementById('js-password-error-isMaj'),
      isNum: document.getElementById('js-password-error-isNum'),
      isSpecial: document.getElementById('js-password-error-isSpecial'),
      text: document.getElementById('security.form.error.password.text'),
    },
    errorGin: document.getElementById('js-security-password-gin-error'),
    checkIcon: {
      is8Characters: document.getElementById('js-security-password-8characters-icon'),
      is1Maj: document.getElementById('js-security-password-1maj-icon'),
      is1Number: document.getElementById('js-security-password-1num-icon'),
      is1Special: document.getElementById('js-security-password-1special-icon'),
    },
    checkText: {
      is8Characters: document.getElementById('js-security-password-8characters-text'),
      is1Maj: document.getElementById('js-security-password-1maj-text'),
      is1Number: document.getElementById('js-security-password-1num-text'),
      is1Special: document.getElementById('js-security-password-1special-text'),
    },
  },
  {
    input: document.getElementById('js-security-current-password'),
    label: document.getElementById('js-current-password-label'),
    info: 'currentPassword',
    icon: document.getElementById('js-security-current-password-eye-icon'),
    error: document.getElementById('js-current-password-error'),
    errorGin: document.getElementById('js-security-current-password-confirm-gin-error'),
    firstPassword: document.getElementById('js-security-current-password'),
  },
  {
    input: document.getElementById('js-security-confirmation-password'),
    label: document.getElementById('js-confirmation-password-label'),
    info: 'passwordConfirmation',
    icon: document.getElementById('js-security-confirmation-password-eye-icon'),
    error: document.getElementById('js-confirmation-password-error'),
    errorGin: document.getElementById('js-security-confirmation-password-gin-error'),
    firstPassword: document.getElementById('js-security-password'),
  },
  {
    inputNo: document.getElementById('js-security-newsletterSubscription-no'),
    inputYes: document.getElementById('js-security-newsletterSubscription-yes'),
    separator: document.getElementById('js-security-newsletterSubscription-separator'),
    info: 'newsletterSubscription',
    labelNo: document.getElementById('js-security-newsletterSubscription-label-no'),
    labelYes: document.getElementById('js-security-newsletterSubscription-label-yes'),
  },
  {
    inputNo: document.getElementById('js-security-declarationHonnor-no'),
    inputYes: document.getElementById('js-security-declarationHonnor-yes'),
    separator: document.getElementById('js-security-declarationHonnor-separator'),
    info: 'declarationHonnor',
    labelNo: document.getElementById('js-security-declarationHonnor-label-no'),
    labelYes: document.getElementById('js-security-declarationHonnor-label-yes'),
  },
  {
    inputNo: document.getElementById('js-security-consent-no'),
    inputYes: document.getElementById('js-security-consent-yes'),
    separator: document.getElementById('js-security-consent-separator'),
    info: 'consent',
    labelNo: document.getElementById('js-security-consent-label-no'),
    labelYes: document.getElementById('js-security-consent-label-yes'),
  },
  {
    input: document.getElementById('js-security-pseudo'),
    label: document.getElementById('js-pseudo-label'),
    error: document.getElementById('js-security-pseudo-error'),
    errorGin: document.getElementById('js-security-pseudo-gin-error'),
    info: 'pseudo',
  },
  {
    inputNo: document.getElementById('js-security-parentConsent-no'),
    inputYes: document.getElementById('js-security-parentConsent-yes'),
    separator: document.getElementById('js-security-parentConsent-separator'),
    info: 'parentConsent',
    labelNo: document.getElementById('js-security-parentConsent-label-no'),
    labelYes: document.getElementById('js-security-parentConsent-label-yes'),
  },
  {
    input: document.getElementById('js-security-certifyAge'),
    label: document.getElementById('js-certifyAge-label'),
    error: document.getElementById('js-security-certifyAge-error'),
    errorGin: document.getElementById('js-security-certifyAge-gin-error'),
    info: 'certifyAge',
  },
  {
    input: document.getElementById('js-security-schoolLevel'),
    label: document.getElementById('js-schoolLevel-label'),
    error: document.getElementById('js-security-schoolLevel-error'),
    errorGin: document.getElementById('js-security-schoolLevel-gin-error'),
    info: 'schoolLevel',
  },
];
