import { ajaxJson, ajaxHTML } from '@global-js/utils/ajax';
import { accessibility } from '@global/organisms/popup/accessibility';
import { getCookies, createCookie } from '@global-js/utils/cookies';
import isInputBlank from '@global-js/security/validators/input-not-blank';
import { getSchoolLevel } from '@global-js/modules/security/session/user';

const _dispatchPianoClickEvent = () => {
  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: { type: 'popin_back_to_school' },
  }));
};

const _dispatchPianoImpressionEvent = () => {
  document.dispatchEvent(new CustomEvent('Tracking:impression', {
    detail: { type: 'popin_back_to_school' },
  }));
};

export const updateSuccessPopin = () => {
  const containerPopin = document.querySelector('.updateSchoolLevelSuccess');

  if (window.location.hash === '#update-success') {
    const uri = window.location.toString();
    containerPopin.querySelector('.popup-container').setAttribute('data-active', 'true');
    accessibility(containerPopin);
    const clearUri = uri.substring(0, uri.indexOf('#'));
    window.history.replaceState({}, document.title, clearUri);
  }
};

const updateSchoolLevel = () => {
  const popin = document.querySelector('.containerPopin');
  const inputSchoolLevel = popin.querySelector('#form_schoolLevel');
  const buttonSubmit = popin.querySelector('#update_validate');
  const URL = window.location.origin;
  const cookie = getCookies(['schoolLevel']);

  buttonSubmit.addEventListener('click', () => {
    const data = {
      schoolLevel: inputSchoolLevel.value,
    };

    const formatedSchoolLevel = data.schoolLevel;

    if (cookie.schoolLevel === formatedSchoolLevel) {
      popin.querySelector('.popup-container').setAttribute('data-active', 'false');
      return;
    }

    ajaxJson(`${ URL }/ajax/update/school-level`, false, data)
      .then((result) => {
        if (result.response === 'success') {
          window.location.href = `${ result.levelPage }#update-success`;
        }
      })
      .catch((e) => console.log(e.message));

    _dispatchPianoClickEvent();
  });
};

const dispatchEvents = () => {
  document.dispatchEvent((new CustomEvent('refreshPopup')));
  _dispatchPianoImpressionEvent();
};

export const updateSchoolLevelPopin = (isUserConnected) => {
  const cookieUpdateSchoolLevelPopin = 'lumni_update_schoolLevel_popin';
  const cookies = getCookies([cookieUpdateSchoolLevelPopin]);
  const isPopinShowed = cookies[cookieUpdateSchoolLevelPopin] === 'true';

  const envDateStart = window.BACK_TO_SCHOOL_START;
  const envDateEnd = window.BACK_TO_SCHOOL_END;
  const currentDay = new Date();
  const backSchoolStart = new Date(`${ currentDay.getFullYear() }-${ envDateStart }`);
  const backSchoolEnd = new Date(`${ currentDay.getFullYear() }-${ envDateEnd }`);
  const isBackToSchoolDays = currentDay >= backSchoolStart && currentDay <= backSchoolEnd;
  const containerPopin = document.querySelector('.containerPopin');

  setTimeout(() => {
    const isLevelOther = getSchoolLevel() === 'autre';

    if (!isBackToSchoolDays
      || !isUserConnected
      || (isUserConnected && isPopinShowed)
      || isLevelOther) {
      return;
    }

    ajaxHTML(`${ window.location.origin }/ajax/update/school-level`)
      .then((response) => response.text())
      .then((template) => {
        containerPopin.innerHTML = template;
        const schoolLevelInput = {
          input: document.getElementById('form_schoolLevel'),
          label: document.querySelector('#form_schoolLevel ~ .form__label'),
          error: document.querySelector('#form_schoolLevel ~ .helper--error'),
          info: 'schoolLevel',
        };

        containerPopin.querySelector('.popup-container').setAttribute('data-active', 'true');
        createCookie('lumni_update_schoolLevel_popin', true, 365);

        updateSchoolLevel();
        isInputBlank(schoolLevelInput);
        dispatchEvents();
        accessibility(containerPopin);
      })
      .catch((e) => console.log(e.message));
  }, 2000);
};
