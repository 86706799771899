export const quizGameLandscapeFullScreen = () => {
  const gameFullscreenBtn = document.querySelector('.fullscreen-button');
  const quizFullscreenBtn = document.querySelector('.fullscreen-quiz');
  const portrait = window.matchMedia('(orientation: portrait)');

  if (gameFullscreenBtn || quizFullscreenBtn) {
    portrait.addEventListener('change', () => {
      if (!portrait.matches) {
        if (gameFullscreenBtn && !document.querySelector('body').classList.contains('fullscreen')) {
          gameFullscreenBtn.click();
        }
        if (quizFullscreenBtn && !document.querySelector('body').classList.contains('fullscreen')) {
          quizFullscreenBtn.click();
        }
      }
    }, false);
  }
};
