const clickFeaturedCard = (el) => {
  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: {
      type: 'featuredContent',
      data: {
        slug: el.dataset.slug,
        type: el.dataset.type,
        isNew: el.dataset.newContent,
      },
    },
  }));
};

export const featuredCard = () => {
  const cards = document.querySelectorAll('.card__featured');

  cards.forEach((card) => {
    const link = card.querySelector('.card__featured--btn a');
    link?.addEventListener('click', () => {
      clickFeaturedCard(card);
    });
  });
};
