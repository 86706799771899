/**
 * @param {object} data
 * @private
 */
const _dispatchTrackingEvents = (data) => {
  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: { type: 'chapter', data },
  }));
};

export const setupOnClickChapterItem = () => {
  const chapterItems = document.querySelectorAll('.list-chapter .chapter');

  chapterItems.forEach((chapterItem) => {
    const chapterName = chapterItem.querySelector('.card-link__text').innerHTML;

    chapterItem.addEventListener('click', () => {
      _dispatchTrackingEvents({ action: 'chapter', name: chapterName });
    });
  });
};
