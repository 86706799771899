export function initializeTabProfile() {
  const tabLinks = document.querySelectorAll('.incitation-profile .tab-links');
  const tabsContent = document.querySelectorAll('.incitation-profile .tab-content');

  if (tabLinks.length) {
    tabLinks.forEach((element) => {
      element.addEventListener('click', (item) => {
        tabsContent.forEach((tab) => {
          tab.setAttribute('data-active', 'false');
        });
        tabLinks.forEach((tab) => {
          tab.setAttribute('data-active', 'false');
        });
        item.target.closest('.tab-links').setAttribute('data-active', 'true');

        const targetTab = document.querySelectorAll(`.active-content-${item.target.closest('.tab-links').id}`);
        targetTab.forEach((target) => {
          target?.setAttribute('data-active', 'true');
        });
      });
    });
  }
}
