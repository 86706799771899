const config = {
  article: null,
  halfDescription: null,
};

/**
 * @param {string} slug
 * @private
 */
const _dispatch = (slug) => {
  document.dispatchEvent(new CustomEvent('navigation:article:record', {
    detail: {
      type: 'article',
      data: { slug },
    },
  }));
};

/**
 * @private
 */
const _onScroll = () => {
  if (window.scrollY >= config.halfDescription) {
    _dispatch(config.article.dataset.slug);
    document.removeEventListener('scroll', _onScroll, false);
  }
};

export const articleTracking = () => {
  config.article = document.getElementById('article');

  if (!config.article) return;

  const rect = config.article.getBoundingClientRect();
  config.halfDescription = rect.top + (config.article.offsetHeight / 2);

  document.addEventListener('scroll', _onScroll, false);
};
