import { FormValidation } from '../../../molecules/security/form/validation';
import NewsletterApi from '../../../../../global/components/organisms/newsletter/newsletterApi';
import { getEmail, isConnected } from '../../../../../global/assets/scripts/modules/security/session/user';
import { getCookies, createCookie } from '../../../../../global/assets/scripts/utils/cookies';

const inputs = {
  email: '#form_email',
};

const cookie = 'lumni_nl_registered';
const cookieValidation = 365;
const newsletterApi = new NewsletterApi();

/**
 * @private
 */
const _dispatchEvent = () => {
  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: {
      type: 'newsletter',
      data: {
        click: 's_inscrire',
        zone: 'bandeau',
      },
    },
  }));
};

/**
 * @private
 */
const _dispatchImpressionEvent = () => {
  document.dispatchEvent(new CustomEvent('Tracking:impression', {
    detail: {
      type: 'newsletter',
      data: { zone: 'bandeau' },
    },
  }));
};

/**
 * @param {boolean} isRegistered
 * @param {string} email
 * @private
 */
const _changeBannerNlState = (isRegistered) => {
  const emailField = document.querySelector(inputs.email);
  const userEmail = getEmail();

  if (!isRegistered) {
    emailField.value = userEmail || null;

    if (userEmail !== '') {
      emailField.classList.add('input--filled');
    }

    return;
  }

  emailField
    .closest('.banner-nl__form-container')
    .setAttribute('data-state', 'success');
};

/**
 * @private
 */
const _subscribe = () => {
  const email = document.querySelector(inputs.email).value.trim();
  const bannerContainer = document.querySelector('.banner-nl__form-container');
  const categoryPage = bannerContainer.getAttribute('data-categorypage');
  const screenType = window.innerWidth >= 1200 ? 'desktop' : 'mobile';
  const detailSource = `encartsite-lumni-nl-${categoryPage}-bas-${screenType}`;

  newsletterApi.send(email, true, false, detailSource);

  createCookie(cookie, 'true', cookieValidation);
  bannerContainer.setAttribute('data-state', 'success');

  _dispatchEvent();
};

export const checkUserLumniNlRegistered = () => {
  if (!document.querySelector('.formV2')) return;

  // if (!userEmail) return;

  const nlRegisteredCookie = getCookies([cookie]);

  const isRegistered = nlRegisteredCookie.lumni_nl_registered
    ? JSON.parse(nlRegisteredCookie.lumni_nl_registered)
    : false;

  _changeBannerNlState(isRegistered);
};

export const FormBannerNL = () => {
  if (!document.querySelector('.formV2')) return;

  FormValidation(inputs);

  document.addEventListener('bannerNL:success', _subscribe);
  document.addEventListener('user:logged', checkUserLumniNlRegistered, false);
};

export const trackingBannerNl = () => {
  const element = document.querySelector('.banner-nl__container');
  const observer = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        _dispatchImpressionEvent();
        observer.unobserve(element);
      }
    },
    { threshold: [1] },
  );

  observer.observe(element);
};

export const neswletterConnected = () => {
  const newsletterCo = document.querySelector('.bubble--newsletter');

  // add class if user is connected
  if (isConnected()) {
    newsletterCo.classList.add('bubble--connected');
  }
};
