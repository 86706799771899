import { THEMES_STUDENT } from '@global-js/modules/theme/themes';
import { setHeader } from '@global-js/modules/theme/header';
import { setBreadcrumb } from '@global-js/modules/theme/breadcrumb';
import { setMenuV2 } from '@global-js/modules/theme/menuV2';
import { setFooter } from '@global-js/modules/theme/footer';
import { setSearchContext } from '@global-js/modules/theme/search';

const state = {
  cookies: null,
  theme: null,
  themes: null,
  thematicAttribute: null,
};

/**
 * @param {Object} cookies
 */
const setState = (cookies) => {
  state.cookies = cookies;
  state.themes = THEMES_STUDENT;
  state.theme = state.cookies.establishment;
  state.thematicAttribute = 'thematics';
};

const setTheme = () => {
  const fallbackTheme = window.LUMNI_CONTEXT.route === 'search' && window.ENABLE_SEARCH_BETA ? '' : 'college';
  const newTheme = state.theme || fallbackTheme;
  document.querySelector('body').setAttribute('data-theme', newTheme);
};

/**
 * @param {Object} cookies
 */
export const Theme = (cookies) => {
  setState(cookies);
  setTheme();
  setHeader(state);
  setMenuV2(state);
  setFooter(state);

  if (!window.isPageGamificationLanding) {
    setBreadcrumb(state);
    setSearchContext(state);
  }
};
