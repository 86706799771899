import { FormValidation } from '../../../molecules/security/form/validation';
import { PasswordVisibility } from '../../../molecules/security/form/password-visibility';

const inputs = {
  email: '#form_email',
  password: '#form_password',
};

const errorMessages = {
  'ftv-account-not-exists-error': 'il n y a pas de compte enregistré avec cet email',
  'ftv-bad-credentials-error': 'mauvais mot de passe',
};

/**
 * @param {string} errorMessage
 * @private
 */
const _dispatchTrackingEvents = (errorMessage) => {
  document.dispatchEvent(new CustomEvent('Tracking:login', {
    detail: {
      type: 'security',
      data: { action: 'error', error_message: errorMessage },
    },
  }));
};

export const FormLogin = () => {
  // Temporary
  if (!document.querySelector('.formV2')) return;

  // Dispatch event for Piano Tracking
  if (window.LUMNI_CONTEXT.headers['ftv-account-not-exists-error']) {
    _dispatchTrackingEvents(errorMessages['ftv-account-not-exists-error']);
  }
  if (window.LUMNI_CONTEXT.headers['ftv-bad-credentials-error']) {
    _dispatchTrackingEvents(errorMessages['ftv-bad-credentials-error']);
  }

  FormValidation(inputs);
  PasswordVisibility();
};
