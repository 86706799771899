const _getLink = (url, attr) => `<a href=${url} rel="${attr}" class="pagination__item pagination__item--${attr}-page"></a>`;

const _toggleLink = (pagination, element, attr) => {
  pagination
    .querySelector(`.pagination__item--${attr}-page`)
    .classList
    .toggle(
      'pagination__item--disabled',
      element.classList.contains('pagination__item--disabled'),
    );
};

export const Pagination = () => {
  const pagers = document.querySelectorAll('.pagerfanta-v3');

  if (pagers.length === 0) {
    return;
  }

  pagers.forEach((pagination) => {
    if (parseInt(pagination.dataset.total, 10) > 1 && !(pagination.querySelector('.pagination__item--last-page') || pagination.querySelector('.pagination__item--first-page'))) {
      const prev = pagination.querySelector('.pagination__item--previous-page');
      const next = pagination.querySelector('.pagination__item--next-page');

      const baseUrl = pagination.querySelector('a.pagination__item').href.split('?')[0];
      const lastUrl = `${baseUrl}?page=${pagination.dataset.total}`;

      prev.innerHTML = '';
      next.innerHTML = '';
      pagination.insertAdjacentHTML('afterbegin', _getLink(baseUrl, 'first'));
      pagination.insertAdjacentHTML('beforeend', _getLink(lastUrl, 'last'));

      _toggleLink(pagination, prev, 'first');
      _toggleLink(pagination, next, 'last');
    }
  });
};
