const dispatchSearchCard = (el) => {
  if (window.LUMNI_CONTEXT.route !== 'search') return;

  const noResult = document.querySelector('.no-result');
  const getPagination = document.querySelector('.pagination__item--current-page');
  const currentPage = getPagination ? getPagination.textContent : '1';
  const getKeyWord = document.querySelector('#formQuery').value;

  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: {
      type: 'searchV2',
      data: {
        click: el.dataset.slug,
        position: el.dataset.position,
        ise_page: noResult ? '0' : currentPage,
        ise_keyword: getKeyWord,
        content_type: el.dataset.type,
      },
    },
  }));
};

const dispatchTrackingCardPlaylist = () => {
  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: {
      type: 'playlist_card',
    },
  }));
};

const saveVideoCardInfo = (position, zone) => {
  localStorage.setItem('lumni_video_card_info', JSON.stringify({ position, zone }), 1);
};

const getCardInfo = (card) => {
  const parentCard = card.parentNode;

  // Slider
  if (parentCard.classList.contains('slider__slide')) {
    return {
      position: parentCard.dataset.slide,
      zone: parentCard.closest('.slider').dataset.csOverrideId,
    };
  }

  // List
  if (parentCard.classList.contains('list__container')) {
    const titleSegment = parentCard.parentNode.querySelector('h2');
    return {
      position: card.dataset.position,
      zone: titleSegment ? titleSegment.textContent.trim().replace(/\s+/g, '-') : 'liste',
    };
  }

  // Playlist
  if (parentCard.classList.contains('playlist--container')) {
    return {
      position: card.dataset.position,
      zone: 'playlist',
    };
  }

  // other
  return {};
};

const videoCardInfo = (card) => {
  saveVideoCardInfo(...Object.values(getCardInfo(card)));
};

const redirection = ({ detail = {} }) => {
  const container = detail.container || document;
  const cards = container.querySelectorAll('.card');

  cards.forEach((card) => {
    card.addEventListener('click', ({ target }) => {
      const cardLink = card.querySelector('.card__link-image');
      const cardButtonLink = card.querySelector('.cardButton__link');
      const cardType = card.dataset.type;
      dispatchSearchCard(card);

      if (cardType === 'video') {
        videoCardInfo(card);
      }

      if (card.classList.contains('card--playlist')) {
        dispatchTrackingCardPlaylist();
      }

      if (target !== card.querySelector('.card__btn_playlist') && cardLink) {
        window.location.href = cardLink.href;
      }

      if (cardButtonLink) window.open(cardButtonLink.href, '_blank');
    });
  });
};

export const CardRedirection = () => {
  redirection({});
  document.addEventListener('refreshCardRedirection', redirection, false);
};
