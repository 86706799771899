const toggleHeroImgContainer = (hidden, visible) => {
  hidden.classList.remove('visible');
  hidden.classList.add('hidden');
  visible.classList.remove('hidden');
  visible.classList.add('visible');
};

export const popinActionImage = (cardLink, popin) => {
  const parent = cardLink.closest('.card');
  const imgContainer = popin.querySelector('.card__popup-image');
  const imgHeroContainer = popin.querySelector('.card__popup-hero-image');

  if (parent.dataset.hero) {
    const heroLogo = parent.querySelector('.card--hero__logo');
    const heroPerso = parent.querySelector('.card--hero__perso');
    const heroGradientHigh = parent.dataset.gradientstart;
    const heroGradientLow = parent.dataset.gradientend;
    const popinLogo = popin.querySelector('.card--hero__logo');
    const popinPerso = popin.querySelector('.card--hero__perso');
    const popinGradient = popin.querySelector('.card--hero__background');

    toggleHeroImgContainer(imgContainer, imgHeroContainer);

    popinLogo.src = heroLogo.src;
    popinPerso.src = heroPerso.src;
    popinGradient.style.setProperty('--gradient-start', heroGradientHigh);
    popinGradient.style.setProperty('--gradient-end', heroGradientLow);
  } else {
    const parentImage = parent.querySelector('.card__image');
    const popupImage = popin.querySelector('.card__popup-image img');

    toggleHeroImgContainer(imgHeroContainer, imgContainer);

    popupImage.src = parentImage.src;
    popupImage.alt = parentImage.alt;
  }
};
