import inputError from '../helpers/input-error-state';

const isInputBlank = (widget) => {
  if (widget.input === undefined) {
    return;
  }

  if (widget.input.type === 'select-one') {
    if (widget.input.value !== 'default') {
      widget.label.className = 'c-input__choice-label';
      widget.input.className = 'c-input c-input__choice';
      widget.input.classList.add('c-widget__input--black');
    } else {
      widget.input.classList.remove('c-widget__input--black');
      widget.input.classList.remove('c-input--on');
      widget.label.classList.remove('c-input__label--on');
      widget.label.classList.add('c-input__choice-label');
      widget.label.classList.add('is-hidden');
      return;
    }
  }

  if (widget.input.value.length === 0) {
    widget.label.className = 'c-input__label c-input__label--off';
    widget.input.className = 'c-input';

    if (widget.error !== undefined) {
      const classList = widget.error.classList || widget.error.error.classList;
      classList.add('is-hidden');
      widget.label.classList.add('c-input__label--on');
      widget.label.classList.remove('c-input__label--off');
      inputError(false, widget);
      return;
    }

    return;
  }

  if (['newsletterSubscription', 'declarationHonnor', 'consent', 'certifyAge', 'parentConsent'].includes(widget.info)) {
    return;
  }

  widget.label.classList.remove('c-input__choice-label');
  widget.label.classList.remove('c-input__label--off');
  widget.input.classList.add('c-input--on');
  widget.label.classList.add('c-input__label--on');
};

export default isInputBlank;
