import { isValidEmail } from '../../../assets/scripts/utils/validator';
import NewsletterA11y from './newsletter_a11y';
import NewsletterApi from './newsletterApi';
import { createCookie } from '../../../assets/scripts/utils/cookies';

const CLASS_OVERLAY_OPEN = 'popin-newsletter__overlay--open';
const CLASS_CHOICES_ACTIVE = 'popin-newsletter__choices--active';
const CLASS_ERROR_HIDDEN = 'popin-newsletter__error--hidden';
const CLASS_ERROR_INPUT = 'popin-newsletter__input--error';
const CLASS_SUBMIT_ACTIVE = 'popin-newsletter__submit--active';
const CLASS_BODY_FIXED = 'body-blocked';

const EVENT_SUCCESS = 'newsletter-subscription-success';
const EVENT_ERROR = 'newsletter-subscription-error';

/**
 * @private
 */
const _dispatchTrackingEvents = () => {
  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: {
      type: 'newsletter',
      data: {
        click: 'je_m_abonne',
        zone: 'header',
      },
    },
  }));
};

export const closePopin = () => {
  const popin = document.querySelector('#popinNewsletter');

  if (popin) {
    document.querySelector('.newsletter-button').focus();
  }

  document.querySelector('.container--newsletter').innerHTML = '';
  document.querySelector('body').classList.remove('body-blocked');
  document.querySelector('.metanav-js')?.classList.remove('popin-newsletter--open');
};

class NewsletterPopin {
  constructor(popin) {
    this.popin = popin;
    this.title = popin.querySelector('.popin-newsletter__title');
    this.choices = popin.querySelectorAll('.popin-newsletter__choice');
    this.choicesContainer = popin.querySelector('.popin-newsletter__choices');
    this.errorMessage = popin.querySelector('.popin-newsletter__error');
    this.btnSubmit = popin.querySelector('.popin-newsletter__submit');
    this.close = popin.querySelector('.close-cross--newsletter');
    this.inputEmail = popin.querySelector('#popinNewsletterEmail');
    this.overlay = document.querySelector('.popin-newsletter__overlay');
  }

  init() {
    this.popin.setAttribute('aria-modal', 'true');

    this.displayOverlay();
    this.handleForm();

    const a11y = new NewsletterA11y(this.popin);
    a11y.init();

    [this.close, this.overlay].forEach((element) => {
      element.addEventListener('click', () => closePopin(), false);
    });

    this.popin.addEventListener(EVENT_SUCCESS, () => this.onSuccessResponse(), false);
    this.popin.addEventListener(EVENT_ERROR, () => this.onErrorResponse(), false);
  }

  // UI

  displayOverlay() {
    document.querySelector('body').classList.add(CLASS_BODY_FIXED);
    this.overlay.classList.add(CLASS_OVERLAY_OPEN);
  }

  changeStateBtnSubmit() {
    if (NewsletterPopin.getNbChoicesSelected() > 0 && isValidEmail(this.inputEmail.value)) {
      this.btnSubmit.classList.add(CLASS_SUBMIT_ACTIVE);
      this.btnSubmit.disabled = false;
    } else {
      this.btnSubmit.classList.remove(CLASS_SUBMIT_ACTIVE);
      this.btnSubmit.disabled = true;
    }
  }

  // Form

  handleForm() {
    ['change', 'keydown'].forEach((eventName) => {
      this.inputEmail.addEventListener(eventName, (e) => this.checkEmailValidation(e), false);
      this.inputEmail.addEventListener(eventName, (e) => this.changeStateBtnSubmit(e), false);
    });

    this.choices.forEach((choice) => {
      choice.addEventListener('click', (event) => this.onClickCheckbox(event), false);
    });

    this.btnSubmit.addEventListener('click', (event) => this.onClickSubmit(event), false);
  }

  // Events

  onClickCheckbox(event) {
    event.preventDefault();
    const { target } = event;

    if (!document.querySelector('.popin-newsletter__choices').classList.contains(CLASS_CHOICES_ACTIVE)) {
      return;
    }

    const parentNode = target.classList.contains('popin-newsletter__choice') ? target : target.parentNode;

    // Manually check the hidden input
    const input = parentNode.querySelector('input');
    input.checked = !input.checked;

    // Add Aria attribute on the custom checkbox
    parentNode.querySelector('.popin-newsletter__checkbox').setAttribute('aria-checked', `${input.checked}`);

    this.changeStateBtnSubmit();
  }

  onClickSubmit(event) {
    event.preventDefault();
    const nbChoicesSelected = NewsletterPopin.getNbChoicesSelected();

    if (nbChoicesSelected > 0) {
      this.btnSubmit.querySelector('.spinner').classList.add('spinner--active');
      this.btnSubmit.disabled = true;

      const newsletterApi = new NewsletterApi(this.popin);
      const screenType = window.innerWidth >= 1200 ? 'desktop' : 'mobile';
      const detailSource = `enveloppe-lumni-nl-header--${screenType}`;
      const educationNl = this.popin.querySelector('#lumni-student-checkbox').checked;

      newsletterApi.send(
        this.inputEmail.value,
        educationNl,
        this.popin.querySelector('#lumni-teacher-checkbox').checked,
        detailSource,
      );

      if (educationNl) {
        createCookie('lumni_nl_registered', 'true', 365);
      }

      _dispatchTrackingEvents();
    }
  }

  onSuccessResponse() {
    this.displayMessage('Félicitations ! Vous êtes bien inscrit aux newsletters :');
  }

  onErrorResponse() {
    this.displayMessage('Une erreur est survenue, veuillez contacter les équipes Lumni.', true);
  }

  displayMessage(message, removeAllInputs = false) {
    this.title.textContent = message;
    this.inputEmail.remove();
    this.btnSubmit.remove();

    document.querySelectorAll('.popin-newsletter__description').forEach((element) => element.remove());
    document.querySelectorAll('.popin-newsletter__checkbox').forEach((element) => element.remove());

    document.querySelectorAll('.popin-newsletter__choice input').forEach((input) => {
      if (!input.checked || removeAllInputs) {
        input.parentNode.remove();
      }
    });

    setTimeout(() => closePopin(), 10000);
  }

  // Utils

  checkEmailValidation({ target }) {
    if (isValidEmail(target.value)) {
      this.errorMessage.classList.add(CLASS_ERROR_HIDDEN);
      this.choicesContainer.classList.add(CLASS_CHOICES_ACTIVE);
      target.classList.remove(CLASS_ERROR_INPUT);
    } else {
      this.errorMessage.classList.remove(CLASS_ERROR_HIDDEN);
      this.choicesContainer.classList.remove(CLASS_CHOICES_ACTIVE);
      target.classList.add(CLASS_ERROR_INPUT);
    }
  }

  static getNbChoicesSelected() {
    return document.querySelectorAll('.popin-newsletter__choice input:checked').length;
  }
}

export default NewsletterPopin;
