let controllerHTML;
let signalHTML;

let controller;
let signal;

const REQUEST_TIMEOUT = 3000;

export const METHOD_HTTP = ['post', 'put', 'delete'];

/**
 * @param {string} url
 * @param {string} method
 * @param {*} body
 * @param {boolean} isAbort
 * @param {string} cacheControl
 * @returns {Promise<any>}
 */
export const ajaxHTML = async (url, method = 'get', body = null, isAbort = false, cacheControl = null) => {
  let timeoutRequest = null;

  if (isAbort) {
    if (controllerHTML !== undefined) {
      controllerHTML.abort(); // Cancel the previous request
    }

    controllerHTML = new AbortController();
    /* eslint prefer-destructuring: ["error", {VariableDeclarator: {object: false}}] */
    signalHTML = controllerHTML.signal;
    timeoutRequest = setTimeout(() => controllerHTML.abort(), REQUEST_TIMEOUT);
  }

  const headers = new Headers();
  headers.append('X-Requested-With', 'XMLHttpRequest');
  if (cacheControl === 'no-cache') {
    headers.append('cache-control', 'no-cache');
  }

  const parameters = {
    method,
    headers,
  };

  if (METHOD_HTTP.includes(method.toLowerCase()) && body !== null) {
    parameters.body = JSON.stringify(body);
  }

  if (isAbort) {
    parameters.signal = signalHTML;
  }

  const response = await fetch(url, parameters);
  const data = response.status === 200 ? response : '';

  if (timeoutRequest) {
    clearTimeout(timeoutRequest);
  }

  return data;
};

/**
 * @param url
 * @param isAbort
 * @param body
 * @param method
 *
 * @returns {Promise<any>}
 */
export const ajaxJson = async (url, isAbort = false, body = null, method = 'get') => {
  let timeoutRequest = null;

  if (isAbort) {
    if (controller !== undefined) {
      controller.abort(); // Cancel the previous request
    }

    controller = new AbortController();
    /* eslint prefer-destructuring: ["error", {VariableDeclarator: {object: false}}] */
    signal = controller.signal;
    timeoutRequest = setTimeout(() => controller.abort(), REQUEST_TIMEOUT);
  }

  const headers = { 'X-Requested-With': 'XMLHttpRequest' };
  const parameters = isAbort ? { method, signal, headers } : { method, headers };

  if (body !== null) {
    parameters.body = JSON.stringify(body);
    parameters.method = 'post';
  }

  const response = await fetch(url, parameters);
  const data = await response.status < 204 ? response.json() : '';

  if (timeoutRequest) {
    clearTimeout(timeoutRequest);
  }

  return data;
};
