import { securityChecker, inputListValidator } from '../validators/form';

// Accessibility with enter & tab key on swith button

const handleTabClick = (widget) => {
  widget.labelYes.addEventListener('focus', () => {
    widget.labelYes.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        widget.labelYes.click();
        widget.inputNo.checked = false;
        widget.inputYes.checked = true;
        inputListValidator(widget);
        securityChecker(widget);
      }
    });
  });

  widget.labelNo.addEventListener('focus', () => {
    widget.labelNo.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        widget.labelNo.click();
        widget.inputNo.checked = true;
        widget.inputYes.checked = false;
        inputListValidator(widget);
        securityChecker(widget);
      }
    });
  });
};

export default handleTabClick;
