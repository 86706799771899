const config = {
  menu: null,
  back: null,
  close: null,
};

const _displayMenu = () => {
  config.menu.classList.add('menu-user-v2--open');
};

const _closeMenu = () => {
  config.menu.classList.remove('menu-user-v2--open');
};

const _enableEvents = () => {
  config.back.addEventListener('click', _displayMenu, false);
  config.close.addEventListener('click', _closeMenu, false);
};

const _openMenu = () => {
  const url = new URL(window.location.href);

  if (url.searchParams.get('menu-ouvert') && window.innerWidth < 1200) {
    _displayMenu();
  }
};

export const UserMenuMyContents = () => {
  config.menu = document.querySelector('.menu-user-v2');

  if (!config.menu) {
    return;
  }

  config.back = document.querySelector('.user-contents__arrow');
  config.close = document.querySelector('.menu-user-v2__close');

  _enableEvents();
  _openMenu();
};
