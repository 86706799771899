import { ajaxHTML } from '../../utils/ajax';
import { getQueryParams, getUrl } from '../../utils/queryParameters';
import Lazy from '../../Lazy';

const MAX_RESULTS_COUNT = 10000;
const PATH = '/ajax/search-results';
const NO_RESULT = 'Aucun résultat';
const RESULTS_SELECTOR = '#searchResults';

/**
 * @param {HTMLElement} container
 * @private
 */
const _dispatchEvents = (container) => {
  document.dispatchEvent((new CustomEvent('refreshAddPlaylist', { detail: { container } })));
  document.dispatchEvent((new CustomEvent('refreshCardRedirection', { detail: { container } })));
  document.dispatchEvent((new CustomEvent('switchFormatEvent')));
  document.dispatchEvent((new CustomEvent('noResponsiveGameCardsInList', { detail: { container } })));

  const getPagination = document.querySelector('.pagination__item--current-page');
  const currentPage = getPagination ? getPagination.textContent : '0';
  const getResultCount = document.querySelector('.results-count');
  const resultCount = getResultCount.textContent.startsWith('Aucun') ? '0' : '1';

  document.dispatchEvent(
    new CustomEvent('Tracking:view', {
      detail: {
        data: { ise_page: currentPage, isResultFound: resultCount },
      },
    }),
  );
};

/**
 * @param {string} support
 * @param {number} total
 * @return {string}
 * @private
 */
const _getTextSearchResult = (support, total) => {
  if (total === 0) return NO_RESULT;

  const hasResults = total > 1;

  if (support === 'all') {
    return hasResults
      ? `${MAX_RESULTS_COUNT === total ? '+ de ' : ''}${total} résultats`
      : `${total} résultat`;
  }

  if (support === 'mobile') {
    return hasResults
      ? `Afficher les ${total < MAX_RESULTS_COUNT ? `${total}` : ''} résultats`
      : 'Afficher le résultat';
  }

  return hasResults ? 'Voir les résultats' : 'Voir le résultat';
};

/**
 * @param {number} total
 * @private
 */
const _displayTotal = (total) => {
  document.querySelector('.results-count').textContent = _getTextSearchResult('all', total);
  document.querySelector('.totalSearchMobile').textContent = _getTextSearchResult('mobile', total);
  document.querySelector('.filter-result__text').textContent = _getTextSearchResult('desktop', total);
  document.querySelector('.filter-result__count').textContent = total < MAX_RESULTS_COUNT ? `(${total})` : '';
};

/**
 * @param {string} state
 * @private
 */
export const toggleLoader = (state) => {
  const loader = document.querySelector('.search-loading');

  if (state === 'show') loader.classList.add('overlay-open');
  if (state === 'hide') loader.classList.remove('overlay-open');
};

/**
 * @param {Event} event
 * @param {Object} filters
 * @private
 */
const _onClickPagination = (event, filters) => {
  event.preventDefault();

  const url = new URL(event.currentTarget.href);
  const data = getQueryParams(url);

  // eslint-disable-next-line no-use-before-define
  getSearchResults(data);

  const page = event.currentTarget.textContent;
  const newUrl = new URL(window.location.href);
  const queryString = url.search;
  const searchParams = new URLSearchParams(queryString);

  searchParams.set('page', page);
  newUrl.search = searchParams.toString();

  window.history.pushState(filters, null, newUrl);
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

/**
 * @param {Object} filters
 * @private
 */
const _enableSearchPagination = (filters) => {
  const searchResults = document.querySelector(RESULTS_SELECTOR);
  const pagerfanta = window.ENABLE_NEW_DA ? searchResults?.querySelector('.pagerfanta-v3') : searchResults?.querySelector('.pagerfanta-v2');
  const pagination = pagerfanta || searchResults?.querySelector('.pagination-block');

  if (!pagination) {
    return;
  }

  [].forEach.call(pagination.querySelectorAll('a'), (element) => {
    element.addEventListener('click', (event) => _onClickPagination(event, filters), false);
  });
};

/**
 * @param {Object} params
 */
export const getSearchResults = (params) => {
  const filters = { ...params };
  const apiUrl = `${window.location.origin}${PATH}`;

  toggleLoader('show');

  ajaxHTML(getUrl(filters, apiUrl))
    .then((response) => {
      _displayTotal(parseInt(response.headers.get('total'), 10));
      return response.text();
    })
    .then((template) => {
      const element = document.querySelector(RESULTS_SELECTOR);
      element.innerHTML = template;

      toggleLoader('hide');
      _enableSearchPagination(filters);
      _dispatchEvents(element);

      Lazy.init();
    });
};
