const enableBtn = (btn) => {
  btn.removeAttribute('disabled');
};

const disabledBtn = (btn) => {
  btn.setAttribute('disabled', true);
};

const handleSuccessCardInteraction = (event) => {
  const clickedElement = event.target;
  const cardElement = clickedElement.closest('.card.card__success');
  const btnFlipCard = cardElement.querySelector('.card__success__back--return');

  if (cardElement) {
    const cards = document.querySelectorAll('.card.card__success');

    cards.forEach((card) => {
      const btnReturn = card.querySelector('.card__success__back--return');

      if (card !== cardElement) {
        if (event.type === 'click') {
          card.blur();
        }
        disabledBtn(btnReturn);
        card.classList.remove('card__success__active');
      }
    });

    if (event.type === 'click') {
      cardElement.blur();
    }
    cardElement.classList.toggle('card__success__active');
    if (cardElement.classList.contains('card__success__active')) {
      enableBtn(btnFlipCard);
    }
  }
};

export const successCard = () => {
  const cards = document.querySelectorAll('.card__success');

  cards.forEach((card) => {
    const btnFlipCard = card.querySelector('.card__success__back--return');

    card.addEventListener('click', handleSuccessCardInteraction, false);

    card.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        handleSuccessCardInteraction(event);
      }
    });

    btnFlipCard.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        handleSuccessCardInteraction(event);
        setTimeout(() => {
          card.focus();
          disabledBtn(btnFlipCard);
        }, 100);
      }
    });
  });
};
