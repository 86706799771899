const displayableRoutes = ['article', 'game', 'quiz', 'video', 'brand', 'program', 'serie', 'folder', 'gamification_landing', 'notifications'];
const backArrow = document.querySelector('.metanav-header__offers .back');
const homePageUrl = `${document.location.origin }/`;
const currentRoute = window.LUMNI_CONTEXT?.route;
const { referrer } = document;

const isSearchPageReferencedToHomePage = () => currentRoute === 'search' && referrer === homePageUrl;

const allowBackIcon = () => {
  backArrow.classList.add('back__active');

  backArrow.addEventListener('click', (event) => {
    event.preventDefault();

    if (isSearchPageReferencedToHomePage()) {
      window.location = homePageUrl;
    } else {
      window.history.back();
    }
  });
};

const disallowBackIcon = () => {
  if (!backArrow) return;

  backArrow.classList.remove('back__active');
};

const domain = window.location.host;

export const GoBackArrow = () => {
  if ((displayableRoutes.includes(currentRoute) && referrer && referrer.includes(domain))
    || isSearchPageReferencedToHomePage()
  ) {
    allowBackIcon();
  } else {
    disallowBackIcon();
  }
};
