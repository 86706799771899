export const hiddenIframes = () => {
  const iframes = document.querySelectorAll('body iframe[name="__tcfapiLocator"]', 'iframe[src="javascript:void(0)"]');

  iframes.forEach((iframe) => {
    iframe.setAttribute('aria-hidden', true);
    iframe.removeAttribute('role');
  });
};

export const accessibilityWysiwygLinks = () => {
  const ckeEditable = document.querySelector('.cke_editable ');
  const links = ckeEditable.querySelectorAll('a[target="_blank"]');

  links.forEach((link) => {
    const isDocumentCard = link.classList.contains('cardButton__link');

    if (!isDocumentCard) {
      const texte = link.textContent;
      if (texte) {
        link.setAttribute('title', `${texte.trim()} (nouvelle fenêtre)`);
        link.innerHTML = `<span aria-hidden='true'>${texte}</span>`;
      }
    }
  });
};
