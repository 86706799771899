const CONFIG = {
  student: {
    loginSelector: '.user__login-v2',
  },
};

export const TRANSLATE_LEVELSLABEL = {
  'label.level.1': 'Curieux',
  'label.level.2': 'Apprenti',
  'label.level.3': 'Explorateur',
  'label.level.4': 'Découvreur',
  'label.level.5': 'Savant',
  'label.level.6': 'Expert',
  'label.level.7': 'Génie',
  'label.level.8': 'Maître',
  'label.level.9': 'Titan',
  'label.level.10': 'Légende',
};

/**
 * @param {Object} data
 * @private
 */
const _displayUserMenuStudent = (data) => {
  const linksSelector = '.user-menu-v2 > li:not(.user__lumniz)';

  const allUserLogin = document.querySelectorAll(CONFIG.student.loginSelector);
  allUserLogin.forEach((element) => {
    if (element.querySelectorAll(linksSelector).length > 1) {
      element.querySelector(linksSelector).remove();
    }
    element.classList.add('user__logged');
    element.querySelector('.user-menu__arrow')?.classList.remove('hidden');
    element.querySelector('.header__sublist--user')?.classList.remove('hidden');
    element.querySelector('a').setAttribute('title', 'Mon profil');
    element.querySelector('span').innerHTML = data.firstName || '';
  });
};

/**
 * @param {Object} data
 * @private
 */
const _displayUserMenu = (data) => {
  if (document.querySelector(CONFIG.student.loginSelector)) {
    _displayUserMenuStudent(data);
  }
};

/**
 * @private
 */
const _userMenuTracking = () => {
  const menuItems = document.querySelectorAll('.header__sublist--user a');

  menuItems.forEach((menuItem) => {
    menuItem.addEventListener('click', () => {
      document.dispatchEvent(new CustomEvent('Tracking:click', { detail: { type: 'user_menu' } }));
    }, false);
  });
};

const _accessLumnizBtn = (isUserAuthenticated = false) => {
  if (isUserAuthenticated) {
    const lumnizBtn = document.querySelectorAll('.btn_lumniz__state--user');
    lumnizBtn.forEach((item) => {
      item.innerHTML = 'Mon profil, mes succès.';
    });
  }
};

/**
 * @param {Object|null} data
 * @param {boolean} isUserAuthenticated
 */
export const displayUsername = (data, isUserAuthenticated = false) => {
  if (isUserAuthenticated) {
    _userMenuTracking();
    _displayUserMenu(data);
    _accessLumnizBtn(isUserAuthenticated);
  }
};

export const displayPoints = (data, isUserAuthenticated = false) => {
  if (isUserAuthenticated) {
    const lumniz = document.querySelectorAll('.btn_lumniz__score');
    lumniz.forEach((point) => {
      point.innerHTML = data.points;
    });
  }
};

export const displayLevel = (data, isUserAuthenticated = false) => {
  const emojiIcons = document.querySelectorAll('.emoji-user');
  emojiIcons.forEach((icon) => {
    if (isUserAuthenticated && data.level === '0') {
      return;
    }
    const textElement = document.createElement('p');
    textElement.classList.add('lumniz__level');
    textElement.textContent = data.level;
    icon.parentNode.insertBefore(textElement, icon.nextSibling);
  });
};

export const renderLumnizButton = (isUserAuthenticated = false) => {
  if (isUserAuthenticated) {
    const btns = document.querySelectorAll('a.btn_lumniz, a.card-lumniz--see-more, a.know-more');
    const pathMyInfos = `${window.location.origin}/mon-profil`;
    btns.forEach((btn) => {
      btn.setAttribute('href', pathMyInfos);
    });
  }
};

export const gameLevel = (data, isUserAuthenticated = false) => {
  if (isUserAuthenticated) {
    const progressCurrent = document.getElementById('progressbar-current');
    const progressAcquired = document.getElementById('progressbar-acquired');
    const nextLevel = document.getElementById('next-level-number');
    const currentSublevelPercent = data.percentageCurrentSubLevel;
    let percentSublevel;
    let checkedDots;

    if (progressCurrent) {
      if (currentSublevelPercent >= 33.33 && currentSublevelPercent < 66.66) {
        percentSublevel = '33.33%';
        checkedDots = ['true', 'false'];
      } else if (currentSublevelPercent >= 66.66 && currentSublevelPercent < 99.99) {
        percentSublevel = '66.66%';
        checkedDots = ['true', 'true'];
      } else if (currentSublevelPercent >= 99.99) {
        percentSublevel = '100%';
        checkedDots = ['true', 'true'];
      } else {
        percentSublevel = '0';
        checkedDots = ['false', 'false'];
      }

      progressCurrent.setAttribute('style', `width: ${currentSublevelPercent}%;`);
      progressAcquired.setAttribute('style', `width: ${percentSublevel};`);
      data.level === 10 ? nextLevel.innerHTML = `${data.level}` : nextLevel.innerHTML = `${data.nextLevel}`;

      const templateCheck = `
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_8394_311033" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="2" width="10" height="6">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.90355 2.27462C9.10694 2.47802 9.10694 2.80779 8.90355 3.01119L4.1895 7.72523C3.9861 7.92863 3.65633 7.92863 3.45293 7.72523L1.09591 5.36821C0.89251 5.16481 0.89251 4.83504 1.09591 4.63164C1.29931 4.42824 1.62908 4.42824 1.83248 4.63164L3.82122 6.62038L8.16698 2.27462C8.37037 2.07122 8.70015 2.07122 8.90355 2.27462Z" fill="white"/>
          </mask>
          <g mask="url(#mask0_8394_311033)">
            <rect width="10" height="10" fill="black"/>
            <rect width="10" height="10" fill="white"/>
          </g>
        </svg>
      `;

      checkedDots.forEach((checkedDot, index) => {
        document.getElementById(`dot-${index + 1}`).setAttribute('aria-checked', `${checkedDot}`);
        checkedDot === 'true' ? document.getElementById(`dot-${index + 1}`).innerHTML = templateCheck : '';
      });
    }
  }
};

export const gameInformation = (data, isUserAuthenticated = false) => {
  if (isUserAuthenticated) {
    const currentPoint = document.getElementById('game-points');
    const currentGoal = document.getElementById('game-goal');
    const levelLabel = document.getElementById('label-level');
    const nextLevelBloc = document.getElementById('game-next');
    const nextLevelText = document.getElementById('text-next-level');

    if (currentPoint) {
      data.level === 10 ? nextLevelBloc.remove() : '';
      levelLabel.innerHTML = TRANSLATE_LEVELSLABEL[`label.level.${data.level}`];
      currentPoint.innerHTML = `${data.points}`;
      currentGoal.innerHTML = `${data.goalPoints}`;
      nextLevelText.innerHTML = TRANSLATE_LEVELSLABEL[`label.level.${data.nextLevel}`];
    }
  }
};
