let previousActiveTab = null;

const setActiveIcon = (element) => {
  if (!element) return;

  const image = element.querySelector('img');
  const index = image.src.lastIndexOf('.');

  image.src = `${image.src.substring(0, index) }-active${ image.src.substring(index)}`;
};

const unsetActiveIcon = (element) => {
  if (!element) return;

  const image = element.querySelector('img');

  image.src = image.src.replace('-active', '');
};

const toggleClassNavBar = ({ target }) => {
  const panel = document.querySelector('.thematic-level');
  panel.classList.toggle('thematic-level--open');
  document.querySelector('body').classList.toggle('body-blocked');

  const tab = target.closest('li');
  const isTabActive = tab.classList.contains('active') && !panel.classList.contains('thematic-level--open');
  previousActiveTab = isTabActive ? previousActiveTab : document.querySelector('.nav-bottom ul li.active');

  const tabs = document.querySelectorAll('.nav-bottom ul li');
  const levelPanel = document.querySelector('.top-bar');

  tabs.forEach((element) => {
    element.classList.remove('active');
  });

  if (isTabActive) {
    tab.classList.remove('active');
    unsetActiveIcon(tab);
    previousActiveTab?.classList.add('active');
    setActiveIcon(previousActiveTab);
  } else {
    tab.classList.add('active');
    unsetActiveIcon(previousActiveTab);
    setActiveIcon(tab);
    if (levelPanel.classList.contains('top-bar--open')) {
      levelPanel.classList.remove('top-bar--open');
      document.querySelector('body').classList.toggle('body-blocked');
    }
  }

  document.dispatchEvent((new CustomEvent('activeThematicPosition')));

  if (panel.classList.contains('thematic-level--open')) {
    document.querySelector('.container--header-v2').classList.add('scrolling-top');
  }
};

const toggleNavBar = () => {
  const navBottom = document.querySelector('.nav-bottom-thematic');

  if (!navBottom) return;

  navBottom.addEventListener('click', toggleClassNavBar, false);

  if (['game', 'quiz'].includes(window.LUMNI_CONTEXT?.route)
    || ['tous-les-jeux', 'tous-les-quiz'].includes(window.LUMNI_CONTEXT?.routeParameters?.contentType)
  ) {
    const customTab = document.querySelectorAll('.nav-bottom ul li')[2];
    customTab.classList.add('active');
    unsetActiveIcon(customTab);
    setActiveIcon(customTab);
  }
};

export const MenuNavBarV2 = () => {
  toggleNavBar();
  document.addEventListener('reloadMenu', toggleNavBar, false);
};
