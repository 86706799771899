import Lazy from '@global-js/Lazy';
import { ajaxHTML } from '@global-js/utils/ajax';
import { SliderV2 } from '../../../../../v2/components/organisms/slider/v2/slider';

/**
 * @param {string} id
 */
const initPlugins = (id) => {
  Lazy.init(`${id} .lazy`);
  SliderV2(document.querySelector(id));
};

/**
 * @param {Element} container
 */
const dispatchEvents = (container) => {
  document.dispatchEvent((new CustomEvent('refreshAddPlaylist', { detail: { container } })));
  document.dispatchEvent((new CustomEvent('refreshCardRedirection', { detail: { container } })));
  document.dispatchEvent((new CustomEvent('blockedVideosInList', { detail: { container } })));
};

/**
 * @param {Element} container
 * @param {boolean} updateClassList
 */
export const displaySegment = (container, updateClassList = true) => {
  if (updateClassList) {
    container.classList.add('wrapper--with-menu');
    container.classList.remove('hidden');
  }

  if (!container.querySelector('.card')) {
    container.remove();
  }
};

/**
 * @param {string} url
 * @param {string} type
 * @param {string} id
 * @param {function|null} callback
 */
export const ajax = (url, type, id, callback = null) => {
  ajaxHTML(`${window.location.origin}/ajax/${url}`)
    .then((response) => response.text())
    .then((template) => {
      const container = document.querySelector(id);
      container.innerHTML = template;

      if (callback !== null) {
        callback(type);
      }

      initPlugins(id);
      dispatchEvents(container);
    })
    .catch((e) => console.log(e.message));
};
