const CONFIG = {
  dropdown: 'select',
  dropdownClosed: 'input__select-dropdown-icon--closed',
  state: {
    opened: {
      attr: 'Fermer le menu déroulant',
    },
    closed: {
      attr: 'Ouvrir le menu déroulant',
    },
  },
};

/**
 * @param {HTMLElement} currentTarget
 */
const onClickselectDropdown = ({ currentTarget }) => {
  const targetIcon = currentTarget.parentNode.querySelector('.input__select-dropdown-icon');
  const isClosed = targetIcon.classList.contains(CONFIG.dropdownClosed);
  const state = isClosed ? CONFIG.state.closed : CONFIG.state.opened;

  targetIcon.setAttribute('alt', state.attr);
  targetIcon.classList.toggle(CONFIG.dropdownClosed, !isClosed);
};

export const SelectDropdown = () => {
  const dropdown = document.querySelector(CONFIG.dropdown);
  dropdown.addEventListener('click', onClickselectDropdown, false);
};
