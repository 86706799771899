import Lazy from '@global-js/Lazy';
import { ajaxHTML } from '@global-js/utils/ajax';
import { openPopup } from '@global/organisms/popup/popup';
import { popinActionImage } from '@v2/molecules/card/card_hero/card_hero';
import Favorite from '../../../assets/scripts/favorite/Favorite';

const CLASS_SAVED_ITEM = 'saved-item';
const AJAX_URL_MY_CONTENTS = '/ajax/mes-contenus';

const MAPPING = {
  favorite: {
    cookieName: 'favorites',
    style: 'favorite',
    add: 'Favoris',
    remove: 'Retirer des favoris',
    ariaLabelAdd: 'Ajouter à mes favoris',
    ariaLabelRemove: 'Retirer de mes favoris',
    addSrLabel: 'Ajouter %title% aux favoris',
    removeSrLabel: 'Retirer %title% des favoris',
  },
  watchLater: {
    cookieName: 'watchLater',
    style: 'watch-later',
    add: 'Voir + tard',
    remove: 'Retirer de "Voir + tard"',
    ariaLabelAdd: 'Ajouter à ma liste Voir + tard',
    ariaLabelRemove: 'Retirer de ma liste Voir + tard',
    addSrLabel: 'Voir %title% plus tard',
    removeSrLabel: 'Retirer %title% des voir plus tard',
  },
};

/**
 * @param {Object} data
 */
const trackingClick = (data) => {
  document.dispatchEvent(new CustomEvent('Tracking:click', { detail: { type: 'popin_favorites', data } }));
};

/**
 * @private
 */
const _dispatchTrackingImpressionEvents = () => {
  document.dispatchEvent(new CustomEvent('Tracking:impression', {
    detail: { type: 'popin_favorites' },
  }));
};

class ButtonAction {
  /**
   * @param {boolean} isUserConnected
   */
  constructor(isUserConnected) {
    this.isUserConnected = isUserConnected;
    this.cardHeaderSelector = '.card__header';
    this.cardImageSelector = '.tuile-image img';
    this.setVariables();

    this.popup = document.querySelector('.card__popup');
    this.toolbox = document.querySelector('.toolbox-block');
    this.userContent = document.querySelector('#userContent');
    this.buttonsSaveItem = document.querySelectorAll('.btn-save-item');
  }

  /**
   * @param {Element|null} container
   */
  setVariables(container = null) {
    this.favorite = this.isUserConnected ? new Favorite() : null;

    if (document.querySelector('.card')) {
      this.cardSelector = '.card';
      this.cardHeaderSelector = '.card__more';
      this.cardImageSelector = '.card__image';
    }

    const cardContainer = container || document;
    this.cardLinks = cardContainer.querySelectorAll(this.cardSelector);
  }

  init() {
    if (!this.popup) {
      return;
    }

    if (this.toolbox) {
      this.checkBtnState(this.toolbox);
    }

    this.onClickCardLink();
    this.onClickBtn();

    document.addEventListener('refreshFavoriteWatchLater', this.onRefreshFavoriteWatchLater.bind(this));
    document.addEventListener('popup-closed', this.onClosePopin.bind(this));
  }

  /**
   * @param {Element} container (popup or toolbox)
   */
  checkBtnState(container) {
    if (!this.isUserConnected) return;

    this.updateBtnState(container, 'favorite');
    this.updateBtnState(container, 'watchLater');
  }

  // EVENTS

  onClickCardLink() {
    this.cardLinks.forEach((cardLink) => {
      const actionBtn = cardLink.querySelector(this.cardHeaderSelector);

      if (!actionBtn) {
        return;
      }

      actionBtn.addEventListener('click', (e) => {
        e.preventDefault();
        const parent = cardLink.closest(this.cardSelector);

        this.popup.dataset.type = parent.dataset.originaltype;
        this.popup.dataset.slug = parent.dataset.slug;

        // display popup
        this.popup.querySelector('.card__popup-title').innerHTML = parent.querySelector('.card__title').innerHTML;

        // display image
        popinActionImage(cardLink, this.popup);

        const currentPopup = this.popup.closest('.popup-container');
        openPopup(currentPopup);
        this.checkBtnState(this.popup);
        _dispatchTrackingImpressionEvents();
      });
    });
  }

  onClickBtn() {
    this.buttonsSaveItem.forEach((button) => {
      button.addEventListener('click', () => {
        if (!this.isUserConnected) {
          document.dispatchEvent(new CustomEvent('Popin:Accompany:Freemium'));
          return;
        }

        const { type, slug } = button.closest('.save-item').dataset;
        const { list: listName } = button.dataset;

        if (!slug || !type) {
          return;
        }

        const isItemInList = button.classList.contains(CLASS_SAVED_ITEM);
        const text = isItemInList ? MAPPING[listName].add : MAPPING[listName].remove;
        // eslint-disable-next-line max-len
        const ariaLabel = isItemInList ? MAPPING[listName].ariaLabelAdd : MAPPING[listName].ariaLabelRemove;

        const title = slug.replace(/-+/g, ' ');
        const srText = isItemInList ? MAPPING[listName].addSrLabel.replace('%title%', title) : MAPPING[listName].removeSrLabel.replace('%title%', title);
        const element = button.querySelector('.sr-only');
        if (element) element.textContent = srText;

        if (isItemInList) {
          this.removeItem(listName, slug, type, button, text, ariaLabel);
        } else {
          this.addItem(listName, slug, type, button, text, ariaLabel);
        }
      });
    });
  }

  onReloadMyContents() {
    if (!this.userContent) {
      return;
    }

    const tab = document.querySelector('.tab-links.active-tab');

    ajaxHTML(`${window.location.origin}${AJAX_URL_MY_CONTENTS}`, 'get')
      .then((response) => response.text())
      .then((template) => {
        this.userContent.innerHTML = template;
        document.dispatchEvent(new CustomEvent('user:refresh:contents'));
        this.onRefreshFavoriteWatchLater();
        tab.click();
        document.querySelector(`.tab-links[data-tab="${tab.dataset.tab}"]`).classList.add('active-tab');
      })
      .catch((e) => console.error(e.message));
  }

  /**
   * @param {Event} event
   */
  onRefreshFavoriteWatchLater(event = null) {
    this.setVariables(event?.detail.container);
    this.onClickCardLink();

    if (event?.detail.lazySelector) {
      const selector = event.detail.lazySelector;
      Lazy.init(`${selector} .lazy`, `${selector} .lazy-picture`);
    } else {
      Lazy.init();
    }
  }

  onClosePopin() {
    this.resetPopinBtn('favorite');
    this.resetPopinBtn('watchLater');

    const popupImage = this.popup.querySelector('img');
    popupImage.src = '';
    popupImage.alt = '';

    this.popup.dataset.type = '';
    this.popup.dataset.slug = '';
  }

  // FAVORITE

  /**
   * @param {string} listName
   * @param {string} slug
   * @param {string} type
   * @param {Element} button
   * @param {string} text
   * @param {string} ariaLabel
   */
  addItem(listName, slug, type, button, text, ariaLabel) {
    const zone = this._isPopinActive() ? 'popin' : 'inline';

    trackingClick({
      action: 'ajout', listName, slug, type, button, zone,
    });

    this.favorite
      .addItemToList(listName, slug, type)
      .then((result) => {
        const isSuccess = result.success;
        this._callbackFavorite(button, text, isSuccess, isSuccess, ariaLabel, slug);

        if (result.action && Object.keys(result.action).length > 0) {
          button.classList.add('gamificationActive');
          document.dispatchEvent(new CustomEvent('Gamification', { detail: result.action }));
        }
      });
  }

  /**
   * @param {string} listName
   * @param {string} slug
   * @param {string} type
   * @param {Element} button
   * @param {string} text
   * @param {string} ariaLabel
   */
  removeItem(listName, slug, type, button, text, ariaLabel) {
    const zone = this._isPopinActive() ? 'popin' : 'inline';

    trackingClick({
      action: 'retrait', listName, slug, type, button, zone,
    });

    this.favorite.removeItemFromList(listName, slug, type)
      .then(() => this._callbackFavorite(button, text, true, false, ariaLabel, slug));
  }

  /**
   * @param {Element} button
   * @param {string} text
   * @param {boolean} isSuccess
   * @param {boolean} isItemInList
   * @param {string} ariaLabel
   * @param {string} slug
   *
   * @private
   */
  _callbackFavorite(button, text, isSuccess, isItemInList, ariaLabel, slug) {
    if (isSuccess) {
      ButtonAction.toggleButtonState(button, text, isItemInList, ariaLabel);
      this.onReloadMyContents();
      this._activeFavorite(slug, true);
    }

    if (!isItemInList) {
      this._activeFavorite(slug, false);
    }
  }

  /**
   * @param {string} slug
   * @param {boolean} add
   * @private
   */
  _activeFavorite(slug, add) {
    const cards = document.querySelectorAll(`.card[data-slug=${slug}]`);
    cards.forEach((card) => {
      if (this.popup.dataset.slug === card.dataset.slug) {
        if (add) card.classList.add('favorite-svg--active');
        else card.classList.remove('favorite-svg--active');
      }
    });
  }

  // UI

  _isPopinActive() {
    return this.popup.closest('.popup-container ').dataset.active === 'true';
  }

  /**
   * @param {Element} container
   * @param {string} listName
   */
  updateBtnState(container, listName) {
    const button = container.querySelector(`.${MAPPING[listName].style}`);
    const { type, slug } = container.dataset;

    this.favorite.isItemInList(slug, type, MAPPING[listName].cookieName)
      .then((response) => {
        const isItemInList = 'slug' in response;
        const text = isItemInList ? MAPPING[listName].remove : MAPPING[listName].add;

        const title = slug.replace(/-+/g, ' ');
        const srText = isItemInList ? MAPPING[listName].removeSrLabel.replace('%title%', title) : MAPPING[listName].addSrLabel.replace('%title%', title);
        const element = button.querySelector('.sr-only');
        if (element) element.textContent = srText;

        // eslint-disable-next-line max-len
        const ariaLabel = isItemInList ? MAPPING[listName].ariaLabelRemove : MAPPING[listName].ariaLabelAdd;
        ButtonAction.toggleButtonState(button, text, isItemInList, ariaLabel);
      });
  }

  /**
   * @param {string} listName
   */
  resetPopinBtn(listName) {
    const button = this.popup.querySelector(`.${MAPPING[listName].style}`);
    const text = MAPPING[listName].add;
    const ariaLabel = MAPPING[listName].ariaLabelAdd;
    ButtonAction.toggleButtonState(button, text, false, ariaLabel);
  }

  /**
   * @param {Element} button
   * @param {string} text
   * @param {boolean} isItemInList
   * @param {string} ariaLabel
   */
  static toggleButtonState(button, text, isItemInList, ariaLabel) {
    const btnClassList = button.classList;

    if (isItemInList) {
      btnClassList.add(CLASS_SAVED_ITEM);
      button.setAttribute('aria-label', ariaLabel);
    } else {
      btnClassList.remove(CLASS_SAVED_ITEM);
      button.setAttribute('aria-label', ariaLabel);
    }

    button.querySelector('span').textContent = text;
  }
}

export default ButtonAction;
