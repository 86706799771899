let PlayerMagneto = null;

const CONFIG = {
  cardPreview: null,
  Player: null,
};

const OPTIONS = {
  intro: false,
  adRequireFocus: false,
  showAd: false,
  pip: false,
  origin: 'france.tv',
  preload: true,
  mute: true,
  preferences: {
    mute: true, // mute the player, also seems to prevent the player to not start on ios
  },
  startTimecode: 15, // start the video after 15s to get feel like this is a preview
  autostart: true,
  noUI: true, // hide ui
  webservices: {
    gateway: window.PLAYER_WEBSERVICE || 'https://k7.ftven.fr/videos/',
  },
  consent: {
    estat: 'exempted',
    analytics: 'exempted',
    npaw: 'exempted',
  },
};

let previewTimeout = null;
let hoverTimeout = null;
let onHover = false;

// Module

const playerInitialisation = () => {
  if (PlayerMagneto === null && window.magnetoscope) {
    PlayerMagneto = window.magnetoscope;
    CONFIG.Player = new PlayerMagneto(CONFIG.cardPreview.querySelector('.card-preview__player'), OPTIONS);
  }
};

/**
 * @param previewTimer
 * @param hoverTimer
 * @private
 */
const _clearTimeouts = (previewTimer, hoverTimer) => {
  clearTimeout(previewTimer);
  clearTimeout(hoverTimer);
};

/**
 * @private
 */
const _onLeave = () => {
  _clearTimeouts(previewTimeout, hoverTimeout);
  onHover = false;
};

/**
 * @private
 */
const _displayPlayer = () => {
  CONFIG.cardPreview
    .querySelector('.card-preview__player')
    .classList.add('card-preview__player--active');
};

/**
 * @param {HTMLElement} card
 * @private
 */
const _updateCardPreviewContent = (card) => {
  // Clone the current card to get all the info
  const cloneCard = card.cloneNode(true);
  const cardPreviewCard = CONFIG.cardPreview.querySelector('.card-preview__card');

  // Clean the previous content
  cardPreviewCard.innerHTML = '';
  // Insert the cloned card
  cardPreviewCard.insertAdjacentElement('afterbegin', cloneCard);
};

/**
 * @param {HTMLElement} card
 * @private
 */
const _updateCardPreviewPosition = (card) => {
  // Get position of the card in the page to place the card preview over it
  const cardPosition = card.getBoundingClientRect();

  const cardPreviewTop = `${Math.floor(cardPosition.top + window.scrollY)}px`;
  const cardPreviewLeft = `${Math.floor((cardPosition.left + window.scrollX) - 40)}px`;

  CONFIG.cardPreview.setAttribute('style', `top: ${cardPreviewTop}; left: ${cardPreviewLeft}`);
};

/**
 * @private
 */
const _onLeavePreview = () => {
  // Clear all the timeouts
  _clearTimeouts(previewTimeout, hoverTimeout);
  onHover = false;

  const { cardPreview } = CONFIG;
  cardPreview.classList.remove('card-preview--active');

  hoverTimeout = setTimeout(() => {
    // Move the cardPreview
    cardPreview.setAttribute('style', 'top: -1000px; left: -1000px');
    // Reset the player visibility
    cardPreview.querySelector('.card-preview__player').classList.remove('card-preview__player--active');
    // Stop the video
    CONFIG.Player.stop();
  }, 300); // After X ms to avoid brutal switch
};

/**
 * @param {HTMLElement} currentTarget
 * @private
 */
const _onHover = ({ currentTarget }) => {
  playerInitialisation();

  if (!window.magnetoscope) return;

  const card = currentTarget.closest('.card');
  const cardFormats = ['square', 'horizontal-s-fluid desktop-square'];

  // Stop If we are not hover a video
  if (card.dataset.type !== 'video' || !cardFormats.includes(card.dataset.format)) return;

  // Clear all the timeouts
  _clearTimeouts(previewTimeout, hoverTimeout);

  // Set a global variable to know if we are still hover the card after the timeout
  onHover = true;

  previewTimeout = setTimeout(() => {
    if (!onHover) return;

    _updateCardPreviewPosition(card);
    _updateCardPreviewContent(card);

    // Play the video
    CONFIG.Player.on('video_start', () => _displayPlayer());
    CONFIG.Player.load({ src: card.dataset.factoryid });
    CONFIG.cardPreview.classList.add('card-preview--active');
  }, 800);
};

export const CardPreview = (event = null) => {
  let container = document;

  if (event !== null) {
    container = event.detail.container || event.detail.element;
  }

  const imageAreaOfCards = container.querySelectorAll('.card__imagearea');

  imageAreaOfCards.forEach((imageArea) => {
    imageArea.addEventListener('mouseenter', _onHover, false);
    imageArea.addEventListener('mouseleave', _onLeave, false);
  });

  CONFIG.cardPreview.addEventListener('mouseleave', _onLeavePreview, false);
};

// TIMER

export const initCardPreview = () => {
  if (window.innerWidth < 1200) return;

  CONFIG.cardPreview = document.querySelector('.card-preview');
  if (CONFIG.cardPreview === null) return;

  CardPreview();
  playerInitialisation();
  document.addEventListener('refreshFavoriteWatchLater', CardPreview, false);
};
