import { ajaxJson } from '@global-js/utils/ajax';

const BASE_URL = window.location.origin;
const PATH = '/ajax/mon-historique/video';

/**
 * @param {string} slug
 */
export const getProgress = (slug) => {
  ajaxJson(`${BASE_URL}${PATH}/${slug}`)
    .then((result) => {
      document.dispatchEvent(new CustomEvent('video:seek', {
        detail: { time: result.time, progress: result.progress },
      }));
    })
    .catch((e) => console.log(e.message));
};
