import isInputBlank from '../validators/input-not-blank';

const tooglePasswordVisibility = (widget) => {
  if (widget.info === 'password' || widget.info === 'currentPassword' || widget.info === 'passwordConfirmation') {
    widget.icon.addEventListener('click', (e) => {
      e.target.value = widget.input.value;
      isInputBlank(widget);

      if (widget.input.type === 'password') {
        widget.input.type = 'text';
        widget.icon.style.opacity = '1';
        widget.icon.setAttribute('aria-label', 'Masquer le mot de passe');

        return;
      }

      widget.input.type = 'password';
      widget.icon.style.opacity = '.3';
      widget.icon.setAttribute('aria-label', 'Afficher le mot de passe');
    });
  }
};

export default tooglePasswordVisibility;
