import { isConnected } from '../../../../../global/assets/scripts/modules/security/session/user';
import { ajaxJson } from '../../../../../global/assets/scripts/utils/ajax';
import { createCookie, getCookies } from '../../../../../global/assets/scripts/utils/cookies';
import IndexDB from './connector/IndexDB';

/**
 * @param {object} item
 * @return {`${string}_${string}_${string}`}
 * @private
 */
const _buildName = (item) => `${item.type}_${item.slug}_${item.category}`;

class SavedItems {
  constructor() {
    this.baseUrl = window.location.origin;
    this.path = '/ajax/tous-mes-contenus';
    this.db = null;
    this.cookieName = 'lumni_saved_items_sync';
  }

  init() {
    // Listens events to initDB, Add an item, Remove an item from the DB
    document.addEventListener('IndexDB:ready:favorites', this._getSavedItems.bind(this), false);
    document.addEventListener('Favorite:API:add', this._addItem.bind(this), false);
    document.addEventListener('Favorite:API:remove', this._removeItem.bind(this), false);

    // Listen to new cards added on the page dynamically to update their status
    document.addEventListener('refreshFavoriteWatchLater', this._updateNewCardsOnPage.bind(this));

    // Create uniq instance of IndexDB
    this.db = new IndexDB(isConnected());
  }

  /**
   * @param {object} detail
   * @private
   */
  _addItem({ detail }) {
    this.db.addItem({ name: _buildName(detail) });
  }

  /**
   * @param {object} detail
   * @private
   */
  _removeItem({ detail }) {
    this.db.deleteItem(_buildName(detail));
  }

  _getSavedItems() {
    // If the user does not have the cookie 'lumni_saved_items_sync'
    // we call the api to get the saved items, and we store them in IndexedDB
    // This will prevent from making http request in each pages
    if (isConnected() && Object.keys(getCookies([this.cookieName])).length === 0) {
      this.db.clear();

      ajaxJson(`${this.baseUrl}${this.path}`).then((data) => {
        this._saveItemsInDB(data);
        // Once everything is store we update the cards
        this._updateCards();
      });

      return;
    }

    // If IndexedDB is already sync we can update directly the cards.
    this._updateCards();
  }

  /**
   * @param {array} items
   * @private
   */
  _saveItemsInDB(items) {
    // Store items in IndexedDB
    items.forEach((item) => {
      this.db.addItem({
        name: _buildName(item),
      });
    });

    // Create a cookie for 1 day
    createCookie(this.cookieName, 'true', 1);
  }

  /**
   * @param {HTMLElement} container
   * @private
   */
  _updateCards(container = document) {
    const cards = container.querySelectorAll('.card');
    const storage = this.db.getAll();

    // Storage will return an "onsuccess" event with the data in "result"
    storage.onsuccess = (e) => {
      const savedItemsKeys = e.target.result;

      cards.forEach((card) => {
        const { type, slug } = card.dataset;
        const prefix = `${type}_${slug}`;

        if (savedItemsKeys.includes(`${prefix}_favorite`)
          || savedItemsKeys.includes(`${prefix}_watchLater`)) {
          card.classList.add('favorite-svg--active');
        }
      });
    };
  }

  /**
   * @param {object} detail
   * @private
   */
  _updateNewCardsOnPage({ detail }) {
    this._updateCards(detail.container);
  }
}

export default SavedItems;
