import SliderObserver from './observer';
import { enableSliderControlsArrows } from './sliderControlsArrow';
import { enableSliderControlsDots } from './sliderControlsDot';
import { record } from '../../../../../global/assets/scripts/modules/navigation-recorder/CommonNavigationRecorder';

const CONFIG = {
  slider: '.slider',
  slide: '.slider__slide',
};

const STATES = {
  started: 'started',
  inProgress: 'in_progress',
  finished: 'finished',
};

/**
 * @param {number} position
 * @param {string} slug
 * @param {boolean} isNew
 * @private
 */
const _dispatchClickEvent = (position, slug, isNew) => {
  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: {
      type: 'highlight',
      data: { position, slug, isNew },
    },
  }));

  record({
    type: 'MEA',
    slug,
    state: STATES.finished,
    thematic: '',
  });
};

/**
 * @param {HTMLElement} currentTarget
 * @private
 */
const _onClick = ({ currentTarget }) => {
  const meaSlider = 'slider--mea';
  const link = currentTarget.querySelector(`.${meaSlider} .card-highlight__link-image, .${meaSlider} .card__clickarea`);
  const isNew = currentTarget.querySelector('.card')?.dataset?.newContent;

  if (!link) return;

  const { slug } = link.dataset;
  const position = parseInt(currentTarget.dataset.slide, 10);

  _dispatchClickEvent(position, slug, isNew);
};

export const SliderV2 = (container = null) => {
  const segmentContainer = container || document;

  if (segmentContainer.querySelectorAll(CONFIG.slider).length === 0) {
    return;
  }

  segmentContainer.querySelectorAll(CONFIG.slider).forEach((slider) => {
    if (container === null && slider.parentNode.classList.contains('slide-menu-item_child')) {
      return;
    }

    slider.querySelectorAll(CONFIG.slide).forEach((slide) => {
      slide.addEventListener('click', (event) => _onClick(event), false);
    });

    const observer = new SliderObserver(slider);
    observer.observe();

    const sliderType = slider.dataset.type;

    if (sliderType === 'arrow') enableSliderControlsArrows(slider);
    if (sliderType === 'dot') enableSliderControlsDots(slider);
  });
};
