import { checkEmail } from './rules/email';
import { checkPassword } from './rules/password';
import { checkText } from './rules/text';
import { checkSchoolLevel } from './rules/schoolLevel';
import { STATE_DEFAULT } from './utils';

/**
 * @param input
 * @private
 */
const _removeErrorFromSubmit = (input) => {
  const inputGroup = input.closest('.input-group');
  inputGroup.querySelector('.helper--on-submit')?.remove();
  inputGroup.querySelector('.helper--error')?.classList.remove('hidden');
};

/**
 * @param input
 * @private
 */
const _handleEmptyInput = (input) => {
  const inputGroup = input.closest('.input-group');
  inputGroup.setAttribute('data-state', STATE_DEFAULT);
};

/**
 * @param {HTMLInputElement} input
 * @param onSubmit
 */
export const check = (input, onSubmit) => {
  const { rule } = input.dataset;
  _removeErrorFromSubmit(input);

  if (input.value.trim().length === 0 && !onSubmit) {
    _handleEmptyInput(input);
    return;
  }

  switch (rule) {
    case 'email':
      checkEmail(input);
      break;
    case 'password':
      checkPassword(input);
      break;
    case 'text':
      checkText(input);
      break;
    case 'schoolLevel':
      checkSchoolLevel(input);
      break;
    default:
      break;
  }
};
