import { isUserAbroad } from '@global-js/modules/security/session/user';

/**
 * Indicator display on cards
 * @param {array} cards
 * @param {string} label
 */
const indicatorDisplay = (cards, label) => {
  if (!cards || !label) return;

  cards.forEach((card) => {
    card.querySelector('.card__imagearea').classList.add('card__imagearea--restricted');
    card.querySelector('.card__label').textContent = label;
  });
};
/**
 * Display geoblocked cards
 * @param {object} detail
 */
const blockedRestrictedCards = ({ detail = {} }) => {
  const container = detail.container || document;
  const cards = container.querySelectorAll('.card--geoblocked');

  if (!cards || cards.length === 0) return;

  if (isUserAbroad()) {
    indicatorDisplay(cards, 'accès restreint');
  }
};

export const RestrictedCards = () => {
  blockedRestrictedCards({});
  document.addEventListener('blockedVideosInList', blockedRestrictedCards, false);
};

/**
 * Display of cards not compatible on mobile
 * @param {object} detail
 */
const noResposiveGame = ({ detail = {} }) => {
  const container = detail.container || document;
  const cards = container.querySelectorAll('.card--ludo[data-type="game"][data-responsive="false"]');

  if (!cards || cards.length === 0 || window.innerWidth >= 1199) return;

  indicatorDisplay(cards, 'jeu non compatible');
};

export const noResposiveGameCards = () => {
  noResposiveGame({});
  document.addEventListener('noResponsiveGameCardsInList', noResposiveGame, false);
};
