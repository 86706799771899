import { ajax, displaySegment } from './utils';
import { sliderSegmentObserver } from '../../../../../v2/components/organisms/slider/v2/trackingSliderImpression';

const TYPES_WITH_BG = ['article', 'game'];

const state = {
  cookies: {},
  recommendationId: '#likeAlso',
  recommendation: null,
};

/**
 * @param {string} type
 */
const callback = (type) => {
  if (TYPES_WITH_BG.includes(type)) {
    displaySegment(state.recommendation, false);
  }
  sliderSegmentObserver(state.recommendation);
};

/**
 * @param {string} schoolLevel
 * @param {string} type
 * @param {string} slug
 */
const getRecommendation = (schoolLevel, type, slug) => {
  if (typeof schoolLevel === 'undefined') {
    return;
  }

  const containerSelector = TYPES_WITH_BG.includes(type)
    ? `${state.recommendationId} .wrapper--with-menu`
    : state.recommendationId;

  ajax(
    `recommendation/containers/${schoolLevel}/${type}/${slug}`,
    type,
    containerSelector,
    callback,
  );
};

/**
 * @param {Object} cookies
 * @constructor
 */
export const Containers = (cookies) => {
  state.recommendation = document.querySelector(state.recommendationId);

  if (!state.recommendation) {
    return;
  }

  state.cookies = cookies;

  const { schoolLevel } = state.cookies || '';
  const { type, slug } = state.recommendation.dataset;

  getRecommendation(schoolLevel, type, slug);
};
