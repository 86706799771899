const topPosition = () => {
  const blockLevel = document.querySelector('.nav-panel__section');
  const activeThematic = document.querySelector('.select-thematic');

  if (activeThematic === null || blockLevel === null) {
    return;
  }

  if (activeThematic.title !== 'Accueil') {
    blockLevel.scrollTop = activeThematic.offsetTop - 80;
  }
};

let CONFIG = {};
const CLASS_CHILDREN_CONTAINER = '.nav-panel__children--container';

const closeChildrenPanels = () => {
  document.querySelectorAll(CLASS_CHILDREN_CONTAINER).forEach((element) => {
    element.setAttribute('data-state', 'close');
  });
};

const removeHoverOnChildrenLinks = () => {
  document.querySelectorAll('.has-children').forEach((element) => {
    element.classList.remove('hover');
  });
};

const setupFocusLoop = (panel) => {
  const elementsFocusable = 'button, [href]';
  const firstElementFocusable = panel.querySelectorAll(elementsFocusable)[0];
  const contentFocusable = panel.querySelectorAll(elementsFocusable);
  const lastElementFocusable = contentFocusable[contentFocusable.length - 1];

  panel.addEventListener('keydown', (e) => {
    const tab = e.key === 'Tab' || e.keyCode === 9;
    if (!tab) return;

    if (e.shiftKey && document.activeElement === firstElementFocusable) {
      lastElementFocusable.focus();
      e.preventDefault();
    } else if (document.activeElement === lastElementFocusable) {
      firstElementFocusable.focus();
      e.preventDefault();
    }
  });
  firstElementFocusable.focus();
};

const openPanel = (event) => {
  event.preventDefault();

  const { target } = event;
  const slug = target.getAttribute('data-slug');
  const panel = document.querySelector(`${CLASS_CHILDREN_CONTAINER}[data-slug="${slug}"]`);

  closeChildrenPanels();
  removeHoverOnChildrenLinks();

  CONFIG.menu.setAttribute('data-state', 'open');
  panel.setAttribute('data-state', 'open');

  if (target.classList.contains('has-children')) {
    target.classList.add('hover');
    panel.blur();
  }

  const closeButton = panel.querySelector('.nav-panel__children--title');
  if (closeButton) {
    closeButton.focus();
  }

  setupFocusLoop(panel);
};

const closePanel = () => {
  event.preventDefault();

  const parentActive = document.querySelector('.has-children.nav-panel__section--link.hover');

  if (parentActive) {
    parentActive.focus();
  }

  closeChildrenPanels();
  removeHoverOnChildrenLinks();

  CONFIG.menu.setAttribute('data-state', 'close');
};

const activeSelectedThematic = () => {
  const selectedThematic = document.querySelector('.select-thematic');

  if (selectedThematic === null) return;

  const slug = selectedThematic.getAttribute('data-slug');
  const childrenParent = document.querySelector(`.has-children[data-slug="${slug}"]`);

  if (selectedThematic.classList.contains('nav-panel__section--link--children')) {
    childrenParent.classList.add('select-thematic');
  }
};

const activeWindowsCustomScrollbar = () => {
  const section = document.querySelector('.nav-panel__section');
  const children = document.querySelector('.nav-panel__children');

  if (navigator.appVersion.indexOf('Mac') !== -1) {
    section?.classList.remove('custom-scrollbar');
    children?.classList.remove('custom-scrollbar');
  }
};

const enableMenuV2 = () => {
  CONFIG = {
    menu: document.querySelector('.menuV2'),
    hasChildrenItem: document.querySelectorAll('.has-children'),
    closePanel: document.querySelectorAll('.nav-panel__children--title'),
    navOverlay: document.querySelector('.nav-overlay'),
  };

  CONFIG.hasChildrenItem?.forEach((item) => item.addEventListener('click', openPanel, false));
  CONFIG.closePanel?.forEach((item) => item.addEventListener('click', closePanel, false));
  CONFIG.navOverlay?.addEventListener('click', closePanel, false);

  activeSelectedThematic();
  topPosition();
  document.addEventListener('activeThematicPosition', topPosition, false);
  activeWindowsCustomScrollbar();
};

export const MenuV2 = () => {
  enableMenuV2();
  document.addEventListener('reloadMenu', enableMenuV2, false);
};
