import { getCookies, createCookie, removeCookie } from '../../../../../global/assets/scripts/utils/cookies';

const profilCheckbpx = document.querySelector('#success-popin__input');

const _setGamificationPopinConsent = () => {
  const cookies = getCookies();

  if (profilCheckbpx) {
    profilCheckbpx.checked = !['false', 'undefined'].includes(cookies.lumni_gamification_popin);
  }
};

const _toggleGamificationPopinConsent = (target) => {
  if (target.checked) {
    removeCookie('lumni_gamification_popin');
  } else {
    createCookie('lumni_gamification_popin', 'false');
  }
};

const _enableEvents = () => {
  profilCheckbpx?.addEventListener('change', ({ target }) => {
    _toggleGamificationPopinConsent(target);
  });
};

export const profilGamificationPopinConsent = () => {
  const { pathname } = window.location;
  const isMyProfil = pathname === '/mon-profil';

  if (!isMyProfil) {
    return;
  }

  _setGamificationPopinConsent();
  _enableEvents();
};
