const CLASS_CHOICES_ACTIVE = 'popin-newsletter__choices--active';

class NewsletterA11y {
  constructor(popin) {
    this.popin = popin;
    this.inputEmail = this.popin.querySelector('#popinNewsletterEmail');
    this.title = popin.querySelector('.popin-newsletter__title');
    this.choicesContainer = popin.querySelector('.popin-newsletter__choices');
    this.firstFocusableElement = popin.querySelector('.popin-newsletter__a11y--start');
    this.lastFocusableElement = popin.querySelector('.popin-newsletter__a11y--end');
    this.confidentialityLink = popin.querySelector('.popin-newsletter__confidentiality');
    this.lumniCheckBox = popin.querySelector('#lumni-student-checkbox');
    this.close = popin.querySelector('.close-cross--newsletter');
  }

  init() {
    this.title.focus();

    this.popin.addEventListener('keydown', (event) => {
      const { key } = event;

      if ([' ', 'Enter'].includes(key)) this.onSpaceEnterKey(event);
      if (key === 'Tab') this.onTabKey(event);
    });

    this.lastFocusableElement.addEventListener('focus', () => this.close.focus());
    this.firstFocusableElement.addEventListener('focus', () => {
      [].slice.call(this.popin.querySelectorAll('[href]')).pop().focus();
    });
  }

  onSpaceEnterKey(event) {
    const { target } = event;

    if (target === this.inputEmail) {
      event.preventDefault();
    }

    if (target.classList.contains('popin-newsletter__checkbox')) {
      target.parentNode.click();
    }
  }

  onTabKey(event) {
    const { target } = event;
    const isChoicesActive = this.choicesContainer.classList.contains(CLASS_CHOICES_ACTIVE);

    if (target === this.inputEmail && !isChoicesActive && !event.shiftKey) {
      this.confidentialityLink.focus();
    }

    if (target === this.confidentialityLink.querySelector('a') && !isChoicesActive && event.shiftKey) {
      this.lumniCheckBox.focus();
    }
  }
}

export default NewsletterA11y;
