/**
 * @param {HTMLElement} iframe
 * @param {string} type
 */
export const loadIframe = (iframe, type) => {
  const { src, height } = iframe.dataset;
  const defaultHeight = iframe.getAttribute('height');

  if (defaultHeight !== height && window.innerWidth > 1199 && type === 'game') {
    iframe.style.height = `${height}px`;
  }

  iframe.setAttribute('src', src);
  iframe.style.display = 'inline';

  document.querySelector(`.${type}`).classList.add(`${type}--loaded`);
};
