import isInputBlank from '../validators/input-not-blank';
import { securityChecker, inputListValidator } from '../validators/form';
import { notifsHandler } from '../../modules/notification/listeners/notifications';
import { forgotPassword } from '../datas/bottom-panels-data';
import handleTabClick from './handle-tab-click';

const inputCheck = (widget) => {
  isInputBlank(widget);
  securityChecker(widget);
  inputListValidator(widget);
};

const inputListener = (widget) => {
  if (widget.input !== undefined && widget.info !== 'parentConsent') {
    if (widget.input && widget.input.options) {
      widget.input.addEventListener('focusout', () => {
        inputCheck(widget);
      });
      return;
    }

    if (widget.input.value.length !== 0) {
      inputCheck(widget);
    }

    widget.input.addEventListener('change', () => {
      inputCheck(widget);
    });

    widget.input.addEventListener('focusin', () => {
      notifsHandler('hide');
    });
  }

  if (widget.info === 'certifyAge') {
    widget.input.addEventListener('change', () => {
      securityChecker(widget);
      inputListValidator(widget);
    });
  }

  if (widget.info === 'newsletterSubscription'
      || widget.info === 'declarationHonnor'
      || widget.info === 'consent'
      || widget.info === 'parentConsent'
  ) {
    handleTabClick(widget);

    widget.inputYes.addEventListener('click', () => {
      inputListValidator(widget);
      securityChecker(widget);
    });

    widget.inputNo.addEventListener('click', () => {
      inputListValidator(widget);
      securityChecker(widget);
    });

    return;
  }

  if (widget.info !== 'password' && forgotPassword === null) {
    widget.input.addEventListener('focusout', () => {
      inputCheck(widget);
    });

    return;
  }

  if ((widget.info === 'password' && forgotPassword === null)) {
    widget.input.addEventListener('focusout', () => {
      inputCheck(widget);
    });
  }

  // ux, button have to show up on input in forgot & reset password case.
  if (forgotPassword !== null && (widget.info === 'password' || widget.info === 'mail')) {
    isInputBlank(widget);

    widget.input.addEventListener('input', () => {
      inputCheck(widget);
    });
  }
};

export default inputListener;
