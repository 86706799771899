import {
  isConnected,
  getFirstName,
  getSchoolLevel,
  getAvatarId,
} from '@global-js/modules/security/session/user';
import { SliderV2 } from '../../slider/v2/slider';
import alts from '../../popup/avatar/avatar__alts';

class HeaderSlideMenu {
  constructor() {
    this.headerLevel = document.querySelector('.header-level');
    this.headerSlider = this.headerLevel.querySelector('.slider--header-level');
    this.withSubMenu = this.headerSlider.querySelectorAll('.menu-item__with-children');
    this.childMenu = this.headerLevel.querySelector('.slide-menu-item__children');
    this.backBtn = this.childMenu.querySelector('.slide-menu__back-btn');
    this.clickedItemClass = 'menu-clicked';
    this.menuSlideOut = 'menu-slide--out';
    this.menuSlideIn = 'menu-slide--in';
    this.subMenuSlideOut = 'sub-menu-slide--out';
    this.subMenuSlideIn = 'sub-menu-slide--in';
    this.sliderOptIn = 'slider--opt-in';
    this.menuHidden = 'menu-hidden';
    this.subMenuActive = 'active-child';
    this.welcomeLabel = this.headerLevel.querySelector('.title-1 span');
  }

  init() {
    this.onClickMenu();
    this.onClickBackBtn();
    this.userConnectedToHisHpn();
  }

  onClickMenu() {
    this.withSubMenu.forEach((item) => {
      item.addEventListener('click', () => {
        const menuOf = item.getAttribute('data-slug');
        const subMenu = this.childMenu.querySelector(`div[data-child-of=${menuOf}`);

        item.parentNode.classList.add(this.clickedItemClass);
        this.headerSlider.classList.add(this.menuSlideOut);
        this.headerSlider.classList.remove(this.menuSlideIn);
        subMenu.classList.add(this.subMenuActive);

        this.hideSliderMenu(subMenu);
        setTimeout(() => {
          this.childMenu.querySelector('.slide-menu__back-btn').focus();
          item.parentNode.classList.add(this.clickedItemClass);
        }, 1000);
      }, false);
    });
  }

  /**
   * @param {HTMLDivElement} subMenu
   */
  hideSliderMenu(subMenu) {
    setTimeout(() => {
      this.headerSlider.classList.add(this.menuHidden);
      this.headerSlider.classList.remove(this.menuSlideOut);
      this.headerSlider.querySelector(`.${this.clickedItemClass}`).classList.remove(this.clickedItemClass);
      this.childMenu.classList.add(this.subMenuSlideIn);

      if (!subMenu.classList.contains(this.sliderOptIn)) {
        SliderV2(subMenu);
        subMenu.classList.add(this.sliderOptIn);
      }
    }, 1000);
  }

  onClickBackBtn() {
    this.backBtn.addEventListener('click', () => {
      this.childMenu.classList.add(this.subMenuSlideOut);

      setTimeout(() => {
        this.childMenu.classList.remove(this.subMenuSlideOut, this.subMenuSlideIn);
        this.childMenu.querySelector(`.${this.subMenuActive}`).classList.remove(this.subMenuActive);
        this.headerSlider.classList.remove(this.menuHidden);
        this.headerSlider.classList.add(this.menuSlideIn);
        document.querySelector(`.${this.clickedItemClass}`).querySelector('button').focus();
        this.headerSlider.querySelector(`.${this.clickedItemClass}`).classList.remove(this.clickedItemClass);
      }, 500);
    }, false);
  }

  userConnectedToHisHpn() {
    setTimeout(() => {
      if (isConnected() && window.LUMNI_CONTEXT?.routeParameters.schoolLevel === getSchoolLevel()) {
        this.welcomeLabel.innerHTML = `Bienvenue ${getFirstName()}`;

        const titleNewDa = this.headerLevel.querySelector('.title-1 span.newDa');
        const avatarItem = this.headerLevel.querySelector('.title-1 .avatar-display');
        const avatarImg = this.headerLevel.querySelector('.title-1 .avatar-display img');
        const emojiImg = this.headerLevel.querySelector('.title-1 .emoji-hpn');
        if (titleNewDa) {
          titleNewDa.innerHTML = `Bonjour ${getFirstName()}`;
          const avatarId = getAvatarId();
          if (avatarId) {
            avatarImg.src = `${window.location.origin}/images/avatars/avatar-${avatarId}.png`;
            avatarItem.title = alts[`avatar-${avatarId}.alt`];
            avatarItem.style.display = 'flex';
            emojiImg.style.display = 'none';
          }
        }
      }
    }, 1000);
  }
}

export default HeaderSlideMenu;
