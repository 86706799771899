// eslint-disable-next-line import/no-cycle
import { securityChecker, inputListValidator } from './form';
import isInputBlank from './input-not-blank';
import inputError from '../helpers/input-error-state';

/*
 * individuals inputs checks.
 */

export const check1character = (widget) => {
  const iconClassList = widget.errorCharacter.classList;
  const textClassList = widget.errorCharacter.classList;

  iconClassList.remove('c-is-8-characters__checkimg--checked');
  iconClassList.add('c-is-8-characters__checkimg--unchecked');
  textClassList.remove('c-is-8-characters__text--checked');
  textClassList.add('c-is-8-characters__text--unchecked');

  return false;
};

export const check8character = (widget) => {
  const iconClassList = widget.checkIcon.is8Characters.classList;
  const textClassList = widget.checkText.is8Characters.classList;

  if (widget.input.value.length >= 8) {
    iconClassList.remove('c-is-8-characters__checkimg--unchecked');
    iconClassList.add('c-is-8-characters__checkimg--checked');
    textClassList.remove('c-is-8-characters__text--unchecked');
    textClassList.add('c-is-8-characters__text--checked');

    return true;
  }

  iconClassList.remove('c-is-8-characters__checkimg--checked');
  iconClassList.add('c-is-8-characters__checkimg--unchecked');
  textClassList.remove('c-is-8-characters__text--checked');
  textClassList.add('c-is-8-characters__text--unchecked');

  return false;
};

export const checkOneMaj = (widget) => {
  const OneMajRegex = new RegExp(/^(?=.*?[A-Z]).{1,}$/g);
  const isOneMaj = OneMajRegex.exec(widget.input.value);
  const iconClassList = widget.checkIcon.is1Maj.classList;
  const textClassList = widget.checkText.is1Maj.classList;

  if (isOneMaj !== null) {
    iconClassList.remove('c-is-one-maj__checkimg--unchecked');
    iconClassList.add('c-is-one-maj__checkimg--checked');
    textClassList.remove('c-is-one-maj__text--unchecked');
    textClassList.add('c-is-one-maj__text--checked');

    return true;
  }

  iconClassList.remove('c-is-one-maj__checkimg--checked');
  iconClassList.add('c-is-one-maj__checkimg--unchecked');
  textClassList.remove('c-is-one-maj__text--checked');
  textClassList.add('c-is-one-maj__text--unchecked');

  return false;
};

export const checkOneNumber = (widget) => {
  const OneNumberRegex = new RegExp(/^(?=.*?\d).{1,}$/g);
  const isOneNumber = OneNumberRegex.exec(widget.input.value);
  const iconClassList = widget.checkIcon.is1Number.classList;
  const textClassList = widget.checkText.is1Number.classList;

  if (isOneNumber !== null) {
    iconClassList.remove('c-is-one-num__checkimg--unchecked');
    iconClassList.add('c-is-one-num__checkimg--checked');
    textClassList.remove('c-is-one-num__text--unchecked');
    textClassList.add('c-is-one-num__text--checked');

    return true;
  }

  iconClassList.remove('c-is-one-num__checkimg--checked');
  iconClassList.add('c-is-one-num__checkimg--unchecked');
  textClassList.remove('c-is-one-num__text--checked');
  textClassList.add('c-is-one-num__text--unchecked');

  return false;
};

export const checkOneSpecial = (widget) => {
  const OneSpecialRegex = new RegExp(/^(?=.*?[$@%_\W]).{1,}$/g);
  const isOneSpecial = OneSpecialRegex.exec(widget.input.value);
  const iconClassList = widget.checkIcon.is1Special.classList;
  const textClassList = widget.checkText.is1Special.classList;

  if (isOneSpecial !== null) {
    iconClassList.remove('c-is-one-special__checkimg--unchecked');
    iconClassList.add('c-is-one-special__checkimg--checked');
    textClassList.remove('c-is-one-special__text--unchecked');
    textClassList.add('c-is-one-special__text--checked');

    return true;
  }

  iconClassList.remove('c-is-one-special__checkimg--checked');
  iconClassList.add('c-is-one-special__checkimg--unchecked');
  textClassList.remove('c-is-one-special__text--checked');
  textClassList.add('c-is-one-special__text--unchecked');

  return false;
};

// eslint-disable-next-line consistent-return
export const checkPassword = (widget, firstCheck) => {
  if (firstCheck) {
    return false;
  }

  if (widget.checkIcon !== undefined) {
    const is8 = check8character(widget);
    const isMaj = checkOneMaj(widget);
    const isNum = checkOneNumber(widget);
    const isSpecial = checkOneSpecial(widget);
    const allCheck = {
      is8,
      isMaj,
      isNum,
      isSpecial,
    };

    if ((widget.input.value.length === 0)) {
      widget.error.error.classList.remove('is-hidden');
      widget.error.text.classList.add('is-hidden');
      inputError(true, widget);
    }

    Object.entries(allCheck).forEach(([check, value]) => {
      if (!value && widget.input.value.length > 0) {
        if (widget.input.value.length !== 0) {
          widget.error.error.classList.add('is-hidden');
          widget.error.text.classList.remove('is-hidden');
          widget.error[check].classList.remove('is-hidden');
          inputError(true, widget);
        }
      }
      if (value) {
        widget.error[check].classList.add('is-hidden');
      }
    });

    if ((is8 === true && isMaj === true && isNum === true && isSpecial === true)
      && widget.input.value.length > 0) {
      inputError(false, widget);
      widget.error.error.classList.add('is-hidden');
      widget.error.text.classList.add('is-hidden');
      return true;
    }
  }

  return false;
};

// eslint-disable-next-line consistent-return
export const checkPasswordConfirmation = (widget, firstCheck) => {
  if (firstCheck) {
    return false;
  }

  if (widget.input.value.length > 0 && widget.input.value === widget.firstPassword.value) {
    widget.error.classList.add('is-hidden');
    return true;
  }

  widget.input.addEventListener('focusout', () => {
    if (widget.input.value.length !== 0 && widget.input.value !== widget.firstPassword.value) {
      widget.error.classList.remove('is-hidden');
      inputError(true, widget);
      isInputBlank(widget);
      inputListValidator(widget);
      securityChecker(widget);
    }

    if ((widget.input.value.length === 0)) {
      widget.error.classList.add('is-hidden');
      inputError(false, widget);
    }
  });

  return false;
};

export const checkMail = (widget, firstCheck) => {
  if (firstCheck) {
    return false;
  }

  if (widget.info === 'mail' && widget.input.value !== '') {
    const mailRegex = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,6}$/g);
    const isMail = mailRegex.exec(widget.input.value)
      && widget.input.value.indexOf('..') === -1
      && widget.input.value.indexOf('.@') === -1
      && widget.input.value.indexOf('@voila.fr') === -1
      && widget.input.value.indexOf('gmail.fr') === -1;

    if (isMail) {
      widget.error.classList.add('is-hidden');
      widget.errorEmpty.classList.add('is-hidden');
      widget.input.setAttribute('aria-invalid', 'false');
      inputError(false, widget);

      return true;
    }
    widget.errorEmpty.classList.add('is-hidden');
    widget.error.classList.remove('is-hidden');
    widget.input.setAttribute('aria-describedby', 'js-security-mail-error');
    inputError(true, widget);
    return false;
  }
  widget.errorEmpty.classList.remove('is-hidden');
  widget.input.setAttribute('aria-invalid', 'true');
  widget.input.setAttribute('aria-describedby', 'js-security-mail-error-empty');
  inputError(true, widget);

  return false;
};

// eslint-disable-next-line consistent-return
export const checkIsNumber = (widget, firstCheck) => {
  if (firstCheck) {
    return false;
  }

  return false;
};

export const checkNewsLetter = (widget, firstCheck) => {
  if (firstCheck && (widget.inputYes === undefined || widget.inputNo === undefined)) {
    return false;
  }

  if ((['newsletterSubscription', 'declarationHonnor', 'consent'].includes(widget.info) && widget.inputYes !== undefined && widget.inputYes.checked) || widget.inputNo.checked) {
    widget.separator.classList.add('is-hidden');
    return true;
  }

  return false;
};

export const fieldRequired = (widget, maxChar = null, firstCheck = false) => {
  if (firstCheck && (widget.input.value.length === 0 || widget.input.value === 'default')) {
    return false;
  }

  const option = widget.input.options;
  let validateFieldRes = true;
  if (!option && maxChar) {
    const repeat = `1,${maxChar}`;
    const validateField = new RegExp(`^[-'a-zA-ZàâäèéêëîïôöùûüÿçæœñÀÂÄÈÉÊËÎÏÔÖÙÛÜŸÇÆŒÑ\\s]{${repeat}}$`);
    validateFieldRes = validateField.exec(widget.input.value);
  }

  if (widget.input.value.length === 1) {
    widget.error.classList.add('is-hidden');
    widget.errorCharacter.classList.remove('is-hidden');
    inputError(true, widget);

    return false;
  }

  if (firstCheck && widget.info === 'schoolLevel' && widget.input.value !== 'default' && widget.input.value.length > 0) {
    return true;
  }

  if ((widget.input.value.length > 0 && validateFieldRes && !option)
    || (option !== undefined && option.selectedIndex > 0)) {
    widget.error.classList.add('is-hidden');
    inputError(false, widget);

    return true;
  }

  widget.error.classList.remove('is-hidden');
  widget.errorCharacter.classList.add('is-hidden');
  inputError(true, widget);

  return false;
};

export const ageConsent = (widget, firstCheck) => {
  const parentDiv = document.getElementById('parentConsent');
  const parentConsentError = document.querySelector('.parent-error');
  const age = document.getElementById('js-security-certifyAge');
  const parentConsent = document.getElementById('js-security-parentConsent-yes');

  if (firstCheck) {
    return false;
  }

  if (parentDiv !== null) {
    parentDiv.classList.remove('is-hidden');
    parentDiv.querySelector('#js-security-parentConsent-no').removeAttribute('required');
    parentDiv.querySelector('#js-security-parentConsent-yes').removeAttribute('required');
  }
  if (parentConsentError !== null) parentConsentError.classList.add('is-hidden');
  if (widget.info === 'certifyAge' && widget.input.checked) {
    parentDiv.querySelector('#js-security-parentConsent-no').removeAttribute('required');
    parentDiv.querySelector('#js-security-parentConsent-yes').removeAttribute('required');
    parentDiv.classList.add('is-hidden');
    return true;
  }

  if (widget.info === 'certifyAge' && widget.input && !widget.input.checked && parentConsent.checked) {
    return true;
  }

  if (widget.info === 'parentConsent' && widget.inputNo.checked && !age.checked) {
    parentConsentError.classList.remove('is-hidden');
    return false;
  }

  return (widget.info === 'parentConsent' && widget.inputYes.checked) || age.checked;
};
