/**
 * @param {Object} filters
 * @param {NodeListOf} cards
 * @private
 */
const _toggleCards = (filters, cards) => {
  cards.forEach((card) => {
    // Get data attributes
    const { type, audio, tags } = card.dataset;
    // Handle card video as audio
    const typeCard = (audio || 'false') === 'true' ? 'audio' : type;

    const filteredTags = filters.tags.filter((tag) => tags.split(',').includes(tag));
    const hasSameTags = filters.tags.length === 0 || filteredTags.length > 0;
    const hasSameType = filters.types.length === 0 || filters.types.includes(typeCard);

    if (hasSameTags && hasSameType) {
      card.classList.remove('hidden');
    } else {
      card.classList.add('hidden');
    }

    if (filters.tags.length === 0 && filters.types.length === 0) {
      card.classList.remove('hidden');
    }
  });
};

/**
 * @param {Object} filters
 * @param {Array} keyFilters
 * @return {{types: *[], tags: *[]}}
 */
export const setFiltersValues = (filters, keyFilters) => {
  const filtersValues = { types: [], tags: [] };

  // Store all filters selected in "filtersValues"
  Object.entries(filters).forEach((filter) => {
    const key = filter[0];

    if (keyFilters.includes(key)) {
      const keyFilter = key === 'tags' ? 'tags' : 'types';
      filtersValues[keyFilter] = [...filter[1].values()];
    }
  });

  return filtersValues;
};

/**
 * @param {Array} filterTags
 * @param {NodeListOf} cards
 * @return {number}
 * @private
 */
const _filterByTags = (filterTags, cards) => [...cards].filter((card) => {
  const { tags } = card.dataset;
  const filteredTags = filterTags.filter((filterTag) => tags.split(',').includes(filterTag));
  return filteredTags.length > 0;
}).length;

/**
 * @param {HTMLElement} list
 * @param {string} filter
 * @return {NodeListOf<HTMLElementTagNameMap[string]>}
 * @private
 */
const _getCardsByType = (list, filter) => {
  if (filter === 'all') return list.querySelectorAll('.card');
  if (filter === 'audio') return list.querySelectorAll('.card[data-type="video"][data-audio="true"]');
  if (filter === 'video') return list.querySelectorAll('.card[data-type="video"][data-audio="false"]');
  if (filter === 'serie') return list.querySelectorAll('.card[data-type="serie"]');

  return list.querySelectorAll(`.card[data-type="${filter}"]`);
};

/**
 * @param {Array} filterTags
 * @param {HTMLElement} list
 */
const _updateCountOnFilters = (filterTags, list) => {
  const btnFilters = list.querySelectorAll('.filters-v2__filter');

  btnFilters.forEach((btnFilter) => {
    const { filter } = btnFilter.dataset;
    const cards = _getCardsByType(list, filter);

    const cardsByType = filterTags.length > 0 ? _filterByTags(filterTags, cards) : cards.length;
    const count = cardsByType.toString();

    btnFilter.setAttribute('data-count', count);
    btnFilter.querySelector('span').innerHTML = count;

    if (filter === 'all') {
      if (cardsByType === 0) {
        list.querySelector('.list__no-content').classList.remove('hidden');
      } else {
        list.querySelector('.list__no-content').classList.add('hidden');
      }
    }
  });
};

/**
 * @param {string} parentSelector
 * @param {Object} filtersValues
 * @param {boolean} updateCount
 * @private
 */
const _filterCardsByList = (parentSelector, filtersValues, updateCount) => {
  const parent = document.querySelector(parentSelector);

  if (!parent) return;

  const lists = parent.querySelectorAll('.list');

  // Filter cards by list according to the types of cards inside each list
  // to allow multiselect of different types in different lists
  lists.forEach((list) => {
    const cards = list.querySelectorAll('.card');
    const typesOfCards = new Set();

    cards.forEach((card) => {
      const { type } = card.dataset;
      typesOfCards.add(type);
      // Also add audio if type is video
      if (type === 'video') typesOfCards.add('audio');
    });

    const filtersByList = {
      tags: filtersValues.tags,
      // Here we Re-filter the filters by type to only keep the types of cards inside the list
      types: filtersValues.types.filter((type) => [...typesOfCards.values()].includes(type)),
    };

    _toggleCards(filtersByList, cards);

    // Only update count if the user has clicked on a tag
    // The count is calculated from the tags and not the types selected
    if (updateCount) {
      _updateCountOnFilters(filtersByList.tags, list);
    }
  });
};

/**
 * @param {Object} filters
 * @param {string} parentSelector
 * @param {Array} keyFilters
 * @param {boolean} updateCount
 * @constructor
 */
export const filterCards = (filters, parentSelector, keyFilters, updateCount = false) => {
  const filtersValues = setFiltersValues(filters, keyFilters);

  _filterCardsByList(parentSelector, filtersValues, updateCount);
};
