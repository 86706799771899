import { ajaxHTML } from '@global-js/utils/ajax';

/**
 * @param {string} url
 * @param {function} callback
 */
export const ajax = (url, callback) => {
  ajaxHTML(url)
    .then((response) => response.text())
    .then((template) => callback(template))
    .catch((e) => console.log(e.message));
};

/**
 * @param {Object} state
 * @param {string|null} type
 * @param {boolean} isFooter
 * @return {string}
 */
export const buildUrl = (state, type = null, isFooter = false) => {
  const { cookies, theme } = state;
  let url = `${theme}`;

  if (cookies.schoolLevel) url += `/${cookies.schoolLevel}`;
  if (cookies.thematic && !isFooter) url += `/${cookies.thematic}`;
  if (type !== null) url += `/${type}`;

  return url;
};
