import { getParameters } from '../../../assets/scripts/utils/cookiesParameters';
import { ajaxJson } from '../../../assets/scripts/utils/ajax';
import AutoCompletionAccessibility from './autocompletion_accessibility';

class AutoCompletion {
  constructor(input, completionContainer, cookies) {
    this.cookies = cookies;
    this.input = document.querySelector(input);
    this.idChoiceContainer = completionContainer;
    this.choiceContainer = document.querySelector(this.idChoiceContainer);
    this.alert = document.querySelector('#alert');
    this.options = [];
  }

  init() {
    this.searchOnChange();
    this.closeOnClick();
  }

  searchOnChange() {
    let timeout = null;
    this.input.addEventListener('keyup', (e) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        const self = e.target;

        if ((this.options !== null && self.value.length === 0)
          || ([13, 27, 38, 40].includes(e.keyCode))) {
          return false;
        }

        if (this.options !== null && this.options.xrh !== undefined) {
          this.options.xrh.abort();
        }

        const param = getParameters(this.cookies);

        ajaxJson(`${window.location.origin}/ajax/autocompletion/${param.schoolLevel || 'home'}?query=${self.value}`, false)
          .then((data) => {
            this.options.oldQuery = self.value;

            if (!this.choiceContainer.classList.contains('open') && data.length > 0) {
              this.choiceContainer.classList.remove('hide');
              this.choiceContainer.classList.remove('hidden');
              this.choiceContainer.classList.add('open');
            }

            if (data.length === 0) {
              this.choiceContainer.classList.add('hide');
              this.choiceContainer.classList.add('hidden');
              this.choiceContainer.classList.remove('open');
              this.choiceContainer.innerHTML = '';
            } else {
              this.choiceContainer.innerHTML = '<ul tabindex=""></ul>';
              this.choiceContainer.querySelector('ul').innerHTML = '';
              data.forEach((item) => {
                this.choiceContainer.querySelector('ul').innerHTML += `<li>${item}</li>`;
              });
              this.alert.textContent = `vous avez ${data.length} suggestions`;
              this.searchOnClick();
              const autoCompletionAccessibility = new AutoCompletionAccessibility(this.choiceContainer.querySelector('ul'));
              autoCompletionAccessibility.init();
            }
          })
          .catch((jqXHR, status, error) => {
            console.log(error);
          });

        return true;
      }, 500);
    }, false);
  }

  searchOnClick() {
    const choiceUl = this.choiceContainer.querySelectorAll('ul li');
    Array.from(choiceUl).forEach((elem) => {
      elem.addEventListener('click', (e) => {
        const $this = e.target;
        this.input.value = $this.textContent;

        if (document.querySelector('#searchForm')) {
          document.getElementById('searchForm').submit();
        } else {
          document.getElementById('search-form').submit();
        }
      }, false);
    });
  }

  closeOnClick() {
    document.querySelector('body').addEventListener('click', () => {
      this.choiceContainer.classList.add('hide');
      this.choiceContainer.classList.remove('open');
    }, false);
  }
}

export default AutoCompletion;
