import { updateState } from '../utils';
import { CONFIG } from '../config';

/**
 * @param {string} value
 * @param {Object} prev
 * @private
 */
const _has8Characters = (value, prev) => {
  prev.valid = value.length >= 8;
};

/**
 * @param {string} value
 * @param {Object} prev
 * @private
 */
const _hasOneCapitalLetter = (value, prev) => {
  const OneCapitalLetterRegex = new RegExp(/^(?=.*?[A-Z]).{1,}$/g);
  prev.valid = OneCapitalLetterRegex.exec(value) !== null;
};

/**
 * @param {string} value
 * @param {Object} prev
 * @private
 */
const _hasOneLowerLetter = (value, prev) => {
  const OneLowerLetterRegex = new RegExp(/^(?=.*?[a-z]).{1,}$/g);
  prev.valid = OneLowerLetterRegex.exec(value) !== null;
};

/**
 * @param {string} value
 * @param {Object} prev
 * @private
 */
const _hasOneNumber = (value, prev) => {
  const OneNumberRegex = new RegExp(/^(?=.*?\d).{1,}$/g);
  prev.valid = OneNumberRegex.exec(value) !== null;
};

/**
 * @param {string} value
 * @param {Object} prev
 * @private
 */
const _hasOneSpecialCharacter = (value, prev) => {
  const OneSpecialRegex = new RegExp(/^(?=.*?[$@%_\W]).{1,}$/g);
  prev.valid = OneSpecialRegex.exec(value) !== null;
};

const rules = {
  has8Characters: { valid: false, callback: _has8Characters },
  hasOneCapitalLetter: { valid: false, callback: _hasOneCapitalLetter },
  hasOneLowerLetter: { valid: false, callback: _hasOneLowerLetter },
  hasOneNumber: { valid: false, callback: _hasOneNumber },
  hasOneSpecialCharacter: { valid: false, callback: _hasOneSpecialCharacter },
};

export const resetPasswordHints = () => {
  const hints = document.querySelectorAll('.helper--password');
  hints.forEach((hint) => {
    hint.classList.remove('helper--valid', 'helper--invalid');
  });

  const prefixTextPasswordHelper = document.querySelector(
    CONFIG.selectors.prefixTextPasswordHelper,
  );
  prefixTextPasswordHelper.classList.remove(CONFIG.classNames.helperOnError);
};

/**
 * @param {HTMLInputElement} input
 */
export const checkPassword = (input) => {
  const value = input.value.trim();
  const hintsBlock = document.querySelector('.input-group__helpers--password');

  hintsBlock?.classList.remove('hidden');
  resetPasswordHints();

  Object.entries(rules).forEach((rule) => {
    rule[1].callback(value, rule[1]);
  });

  const errors = Object.entries(rules).filter((rule) => !rule[1].valid);
  const isValid = errors.length === 0;

  updateState(input, isValid, errors);
};
