import { SESSION_NAMES } from './user';

/**
 * @param {string} key
 * @param {string} value
 * @private
 */
const _addToSession = (key, value) => {
  sessionStorage.setItem(key, value);
};

export const resetUserSession = () => {
  Object.values(SESSION_NAMES).forEach((value) => sessionStorage.removeItem(value));
};

/**
 * @param {Object} data
 */
export const setUserSessionStorage = (data) => {
  if (!data) return;

  Object.keys(SESSION_NAMES).forEach((key) => {
    if (data[key] !== undefined) {
      _addToSession(SESSION_NAMES[key], data[key]);
    }
  });
};
