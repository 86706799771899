import { getCookies, createCookie, removeCookie } from '../../../../../global/assets/scripts/utils/cookies';

let formData = {};

const config = {
  btnValidateData: null,
  btnValidatePassword: null,
  firstName: null,
  schoolLevel: null,
  newPassword: null,
  currentPassword: null,
  confirmationPassword: null,
};

const _setConfig = () => {
  config.btnValidateData = document.querySelector('#update_infos_validate');
  config.btnValidatePassword = document.querySelector('#update_password_validate');
  config.firstName = document.querySelector('#js-security-firstName');
  config.schoolLevel = document.querySelector('#js-security-schoolLevel');
  config.newPassword = document.querySelector('#js-security-password');
  config.currentPassword = document.querySelector('#js-security-current-password');
  config.confirmationPassword = document.querySelector('#js-security-confirmation-password');
  config.profileCheckbox = document.querySelector('#success-popin__input');
};

const _setFormData = () => {
  formData = {
    firstName: config.firstName.value,
    schoolLevel: config.schoolLevel.value,
  };
};

/**
 * @param {string} value
 * @return {boolean}
 * @private
 */
const _validatePassword = (value) => (value.length >= 8
  && /^(?=.*?[A-Z]).{1,}$/g.test(value)
  && /^(?=.*?\d).{1,}$/g.test(value)
  && /^(?=.*?[$@%_\W]).{1,}$/g.test(value)
);

/**
 * @param {boolean} disabled
 * @private
 */
const _toggleBtnValidate = (disabled = false) => {
  if (disabled) {
    config.btnValidateData.setAttribute('disabled', 'disabled');
  } else {
    config.btnValidateData.removeAttribute('disabled');
  }
};

const _flipUpdatePasswordButton = () => {
  config.btnValidatePassword.disabled = !(config.currentPassword.value.length !== 0
    && config.newPassword.value.length !== 0
    && config.confirmationPassword.value.length !== 0
    && _validatePassword(config.newPassword.value)
    && config.newPassword.value === config.confirmationPassword.value
  );
};

const _setGamificationPopinConsent = () => {
  const cookies = getCookies();

  if (config.profileCheckbox) {
    config.profileCheckbox.checked = !['false', 'undefined'].includes(cookies.lumni_gamification_popin);
  }
};

const _toggleGamificationPopinConsent = (target) => {
  if (target.checked) {
    removeCookie('lumni_gamification_popin');
  } else {
    createCookie('lumni_gamification_popin', 'false');
  }
};

const _enableEvents = () => {
  config.firstName.addEventListener('change', ({ target }) => {
    if (target.value !== formData.firstName.value) _toggleBtnValidate();
  });

  config.schoolLevel.addEventListener('change', ({ target }) => {
    if (target.value !== formData.schoolLevel.value) _toggleBtnValidate();
  });

  config.currentPassword.addEventListener('focusout', _flipUpdatePasswordButton, false);
  config.newPassword.addEventListener('focusout', _flipUpdatePasswordButton, false);
  config.confirmationPassword.addEventListener('focusout', _flipUpdatePasswordButton, false);

  config.profileCheckbox?.addEventListener('change', ({ target }) => {
    _toggleGamificationPopinConsent(target);
  });
};

export const UserValidate = () => {
  config.btnValidateData = document.querySelector('#update_infos_validate');

  if (!config.btnValidateData) {
    return;
  }

  _setConfig();
  _setFormData();
  _enableEvents();
  _toggleBtnValidate(true);
  _setGamificationPopinConsent();
};
