export const SEE_MORE = 'voir plus';
export const SEE_LESS = 'voir moins';

export const CLASS_ACTIVE_FILTER = 'active-filter';
export const CLASS_GROUP_FILTER = 'group-filter';
export const CLASS_SECTION_OPEN = 'opened-block';

export const ESTABLISHMENTS = {
  primaire: 'label.school-levels.school',
  college: 'label.school-levels.middle-school',
  lycee: 'label.school-levels.high-school',
};

export const TRANSLATE_ESTABLISHMENTS = {
  'label.school-levels.school': 'primaire',
  'label.school-levels.middle-school': 'college',
  'label.school-levels.high-school': 'lycee',
};

export const SCHOOLLEVELS = {
  maternelle: 'label.school-levels.school.infant-school',
  cp: 'label.school-levels.school.cp',
  ce1: 'label.school-levels.school.ce1',
  ce2: 'label.school-levels.school.ce2',
  cm1: 'label.school-levels.school.cm1',
  cm2: 'label.school-levels.school.cm2',
  sixieme: 'label.school-levels.middle-school.sixth',
  cinquieme: 'label.school-levels.middle-school.fifth',
  quatrieme: 'label.school-levels.middle-school.fourth',
  troisieme: 'label.school-levels.middle-school.third',
  seconde: 'label.school-levels.high-school.second',
  premiere: 'label.school-levels.high-school.first',
  terminale: 'label.school-levels.high-school.terminal',
};

export const DEFAULT_SECTIONS_OPEN = {
  seemore: [],
  filters: [],
};

export const DEFAULT_FILTERS = {
  institutions: [],
  schoolLevels: [],
  partners: [],
  thematics: [],
  collections: [],
  videotype: [],
};
