export const CONFIG = {
  classNames: {
    inputFilled: 'input--filled',
    eyeIcon: 'input__password-eye-icon',
    passwordHelperHidden: 'input-group__helpers--password--hidden',
    validRule: 'helper--valid',
    invalidRule: 'helper--invalid',
    helperOnError: 'helper--error',
  },
  selectors: {
    inputGroup: '.input-group',
    inputGroupError: '.input-group[data-state*="error"]',
    passwordHelper: '.input-group__helpers--password',
    passwordInput: 'form_password',
    prefixTextPasswordHelper: '.helper--password__prefixText',
  },
};
