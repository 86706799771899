class Search {
  constructor() {
    this.crossClose = document.querySelector('.ftve-search .cross') || document.querySelector('#searchForm .close');
    this.crossCloseSecond = document.querySelector('.search-secondary .cross');
    this.searchBar = document.querySelector('#resource_query');
    this.searchBarSecond = document.querySelector('#resource_query_secondary');
    this.completion = document.querySelector('#completion') || document.querySelector('#searchCompletion');
  }

  init() {
    this.openList(this.searchBar, this.crossClose);
    this.openList(this.searchBarSecond, this.crossCloseSecond);

    this.closeCross(this.crossClose, false);
    this.closeCross(this.crossCloseSecond, true);
  }

  openList(target, closeCross) {
    if (!target) {
      return;
    }

    target.addEventListener('click', () => {
      if (this.completion) this.completion.classList.add('search-open');
      closeCross.classList.add('close');
    });

    target.addEventListener('keydown', () => {
      closeCross.classList.add('close');
    });

    if (target.value !== '') {
      closeCross.classList.add('close');
    }
  }

  closeCross(cross, resetSearchPage) {
    if (!cross) {
      return;
    }

    cross.addEventListener('click', (e) => {
      this.closeAction(e, resetSearchPage);
    });

    cross.addEventListener('keyup', (e) => {
      if (e.keyCode === 13) {
        this.closeAction(e, resetSearchPage);
      }
    });
  }

  closeAction(e, resetSearchPage) {
    if (this.completion) {
      this.completion.classList.remove('search-open');
    }

    const input = document.querySelector('.input-search') || document.querySelector('.search__input');
    input.value = '';

    if (resetSearchPage) {
      document.getElementById('search-form').submit();
    }
  }
}

export default Search;
