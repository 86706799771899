import { ajax, buildUrl } from '@global-js/modules/theme/utils';

let state = {};
const baseUrl = '/ajax/footer-v2/';

/**
 * @param {Object} stateTheme
 */
export const setFooter = (stateTheme) => {
  state = stateTheme;

  const footer = document.querySelector('footer');
  const { schoolLevel } = window.LUMNI_CONTEXT.qualification;

  if (!footer || !schoolLevel) {
    return;
  }

  // If the school level of the item is the same as the cookie we just display the footer
  if (state.cookies.schoolLevel === schoolLevel) {
    footer.classList.remove('hidden');
    return;
  }

  // Else we make call ajax to get the footer for the correct school level
  ajax(`${baseUrl}${buildUrl(state, null, true)}`, (template) => {
    footer.querySelector('div:first-child').remove();
    footer.insertAdjacentHTML('afterbegin', template);
    footer.classList.remove('hidden');
  });
};
