const getAttributeValue = (attribute) => (typeof attribute === 'undefined' ? null : attribute);

const getDataset = () => {
  const themeV1 = document.querySelector('.custom-theme');

  if (themeV1) {
    return themeV1.dataset;
  }

  if (window.LUMNI_CONTEXT) {
    return { ...window.LUMNI_CONTEXT.qualification, ...window.LUMNI_CONTEXT.item };
  }

  return null;
};

export const getParameters = (cookies = {}) => {
  const dataset = getDataset();

  if (dataset === null) {
    return {};
  }

  const itemSlug = getAttributeValue(dataset.slug);
  const itemType = getAttributeValue(dataset.type);
  const itemSchoolLevel = getAttributeValue(dataset.schoolLevel);
  const homePage = getAttributeValue(dataset.homepage);

  /*
   * Case 1.1 : Navigation from Homepage Establishment > SchoolLevel
   * Case 1.2 : Case 1.1 > Thematic
   * Case 1.3 : Case 1.2 > Chapter
   *  In that case the var "schoolLevel" will take the schoolLevel from the cookies
   *
   * Case 2 : Navigation from Homepage Establishment
   *  In that case the var "schoolLevel" will be the value of the establishment
   *  (i.e primaire, college, lycee). The API will return items with schoolLevels
   *  from this establishment
   *
   * Case 3 : When the user comes from Google or directly via a link
   *  In that case cookies are cleaned so we take main schoolLevel of the item
   *
   * Case 4 : Case 3 and the item has no schoolLevel
   *  In that case the var "schoolLevel" will be the default value
   *
   * Case 5 : Navigation from Homepage global
   *  In that case the var "schoolLevel" will be eleve
   */

  const schoolLevel = homePage !== null ? homePage : cookies.schoolLevel
    || cookies.establishment
    || itemSchoolLevel;

  return {
    itemSlug,
    itemType,
    schoolLevel,
  };
};
