import thematics from './data/thematics.json';

const mappingUniverses = {
  primaire: 'Primaire',
  college: 'Collège',
  lycee: 'Lycée',
};

export const mappingSchoolLevels = {
  primaire: ['maternelle', 'cp', 'ce1', 'ce2', 'cm1', 'cm2'],
  college: ['sixieme', 'cinquieme', 'quatrieme', 'troisieme'],
  lycee: ['seconde', 'premiere', 'terminale'],
};

const mappingThematicsBySchoolLevel = thematics;

/**
 * @param {string} establishment
 */
export const universeName = (establishment) => (mappingUniverses[establishment]);

/**
 * @param {string} establishment
 */
export const getThematicsBySchoolLevel = (level) => (mappingThematicsBySchoolLevel[level]);
