import { ajaxJson } from '../../../assets/scripts/utils/ajax';

const EVENT_SUCCESS = 'newsletter-subscription-success';
const EVENT_ERROR = 'newsletter-subscription-error';
const URL = '/ajax/newsletter/register';

class NewsletterApi {
  /**
   * @param {Element} popin
   */
  constructor(popin = null) {
    this.popin = popin;
  }

  /**
   * @param {string} email
   * @param {boolean} isSubscriptionLumni
   * @param {boolean} isSubscriptionTeacher
   * @param {string} detailSource
   */
  send(
    email,
    isSubscriptionLumni = false,
    isSubscriptionTeacher = false,
    detailSource = 'page-abo',
  ) {
    const data = {
      email: email.trim(),
      opt1: isSubscriptionLumni,
      opt2: isSubscriptionTeacher,
      detailSource,
    };

    ajaxJson(`${window.location.origin}${URL}`, false, data)
      .then((result) => {
        const eventName = result.length === 0 ? EVENT_ERROR : EVENT_SUCCESS;
        this.popin?.dispatchEvent(new Event(eventName));

        if (result.action && Object.keys(result.action).length > 0) {
          document.dispatchEvent(new CustomEvent('Gamification', { detail: result.action }));
        }
      })
      .catch((e) => console.log(e.message));
  }
}

export default NewsletterApi;
