import { accessibility } from './accessibility';

const body = document.querySelector('body');

export const closePopup = (element) => {
  const popup = element.closest('.popup-container');
  const lastFocus = document.querySelector('.lastFocus');

  popup.setAttribute('data-active', 'false');
  lastFocus?.focus();
  lastFocus?.classList.remove('lastFocus');

  document.dispatchEvent((new CustomEvent('popup-closed', {
    detail: popup,
  })));
};

export const openPopup = (popup) => {
  const activeFocus = document.activeElement;

  if (!popup) return;

  activeFocus?.classList.add('lastFocus');
  popup.setAttribute('data-active', 'true');
  accessibility(popup);
};

const isBodyBlocked = () => {
  if (body.classList.contains('body-blocked')) {
    body.classList.remove('body-blocked');
  }
};

export const popup = () => {
  const popupContainers = document.querySelectorAll('.popup-container');
  const closePopupCross = document.querySelectorAll('.close-cross--popup');
  const closePopupButtons = document.querySelectorAll('.card__popup-close-button');
  const closePopupButton = document.querySelector('.close-popup');
  const overlays = document.querySelectorAll('.popup-overlay-visible');

  if (popupContainers.length === 0) {
    return;
  }

  [].forEach.call(closePopupCross, (element) => {
    element.addEventListener('click', ({ target }) => {
      closePopup(target);
      isBodyBlocked();
    });
  });

  [].forEach.call(closePopupButtons, (element) => {
    element.addEventListener('click', (e) => {
      closePopup(e.target);
    });
  });

  if (closePopupButton) {
    closePopupButton.addEventListener('click', (e) => {
      closePopup(e.target);
    });
  }

  window.addEventListener('keydown', (event) => {
    if (event.keyCode === 27) {
      [].forEach.call(popupContainers, (element) => {
        closePopup(element);
        isBodyBlocked();
      });
    }
  });

  [].forEach.call(overlays, (overlay) => {
    overlay.addEventListener('click', ({ target }) => {
      closePopup(target);
      isBodyBlocked();
    });
  });

  document.addEventListener('refreshPopup', popup, false);
};
