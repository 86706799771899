const CONFIG = {
  state: {
    expanded: {
      attr: 'Voir moins',
    },
    closed: {
      attr: 'Voir plus',
    },
  },
};

/**
 * @param {HTMLElement} currentTarget
 */
const onClickRegistrationConditionsButton = ({ currentTarget }) => {
  const content = document.querySelector('.registration-conditions__content');
  const hiddenContent = content.querySelector('.registration-conditions__content__more');
  const expanded = currentTarget.getAttribute('aria-expanded') === 'true' || false;
  const state = expanded ? CONFIG.state.closed : CONFIG.state.expanded;

  currentTarget.setAttribute('aria-expanded', !expanded);
  currentTarget.setAttribute('alt', state.attr);
  currentTarget.querySelector('span').innerHTML = state.attr;
  content.classList.toggle('registration-conditions__content__hidden');
  hiddenContent.setAttribute('aria-hidden', expanded);
  hiddenContent.hidden = expanded;
  hiddenContent.focus();
};

export const RegistrationConditions = () => {
  const registrationConditionsButton = document.querySelector('.registration-conditions__button');

  registrationConditionsButton.addEventListener('click', onClickRegistrationConditionsButton, false);
};
