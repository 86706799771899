import { ajaxHTML } from '@global-js/utils/ajax';
import { isMobile } from '@global-js/utils/browser';
import { getCountLoadedPage } from '@global-js/utils/countPageLoaded';
import { getCookies, createCookie } from '../../../assets/scripts/utils/cookies';
import NewsletterPopin, { closePopin } from './newsletterPopin';

const ALREADY_ASK_NEWSLETTER = 'lumni_already_ask_newsletter';
const ASK_NEWSLETTER_POPIN_DURATION = 365;

/**
 * @private
 */
const _dispatchTrackingEvents = () => {
  document.dispatchEvent(new CustomEvent('Tracking:impression', {
    detail: {
      type: 'newsletter',
      data: { zone: 'header' },
    },
  }));
};

/**
 * @param {string} action
 * @private
 */
const _dispatchEventsClick = (action) => {
  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: {
      type: 'incitation_newsletter',
      data: { action },
    },
  }));
};

/**
 * @private
 */
const _dispatchEventsImpression = () => {
  document.dispatchEvent(new CustomEvent('Tracking:impression', {
    detail: {
      type: 'incitation_newsletter',
    },
  }));
};

class Newsletter {
  constructor() {
    this.selectorPopin = '#popinNewsletter';
    this.grid = document.querySelector('.grid');
    this.footer = document.querySelector('footer');
    this.metanav = document.querySelector('.metanav-js');
    this.btnMetanav = document.querySelector('.newsletter-button');
    this.btnMenu = document.querySelector('.newsletters-menu');
    this.btnFooter = document.querySelector('.newsLetterFooter');
    this.btnBottomBar = document.querySelector('.bottomBarNewsLetter');
    this.containerNewsLetter = document.querySelector('.container--newsletter');
    this.stickyBannerNewsletter = document.querySelector('.sticky-banner-nl');
    this.ignoreStickyBanner = this.stickyBannerNewsletter?.querySelector('.ignore-button');
    this.btnDisplayNewsletter = this.stickyBannerNewsletter?.querySelector('.display-newsletter-popin');
  }

  init() {
    // Events to open the popin

    if (window.location.hash === '#abonnement-newsletters') {
      this.openNewsletter();
    }

    setTimeout(() => {
      if (
        !isMobile()
        && this.stickyBannerNewsletter
        && !getCookies([ALREADY_ASK_NEWSLETTER]).lumni_already_ask_newsletter
        && !getCookies(['lumni_nl_registered']).lumni_nl_registered
        && getCountLoadedPage() > 2
      ) {
        this.openStickyBannerNewsletter();
      }

      [this.ignoreStickyBanner, this.btnDisplayNewsletter].forEach((selector) => {
        if (selector) {
          const action = selector.getAttribute('data-action');
          selector.addEventListener('click', () => {
            _dispatchEventsClick(action);
            this.closeStickyBannerNewsletter();
          });
        }
      });
    }, 100);

    [
      this.btnMetanav,
      this.btnMenu,
      this.btnFooter,
      this.btnDisplayNewsletter,
    ].forEach((selector) => {
      if (selector) {
        selector.addEventListener('click', () => this.openNewsletter());
      }
    });

    if (this.btnBottomBar) {
      this.btnBottomBar.addEventListener('click', () => {
        this.openNewsletter();
        this.isLevelPanelOpen();
      });
    }

    if (this.btnFooter) {
      this.btnFooter.addEventListener('keydown', (event) => {
        if ([' ', 'Enter'].includes(event.key)) this.openNewsletter();
      });
    }

    // Events to close the popin

    window.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape') {
        closePopin();
      }
    });
  }

  isLevelPanelOpen() {
    const levelPanel = document.querySelector('.top-bar');
    if (levelPanel.classList.contains('top-bar--open')) {
      levelPanel.classList.remove('top-bar--open');
    }
  }

  openNewsletter() {
    window.scrollTo(0, 0);

    ajaxHTML(`${window.location.origin}/ajax/newsletter`)
      .then((response) => response.text())
      .then((template) => {
        if (this.metanav) {
          this.metanav.classList.add('popin-newsletter--open');
        }
        this.containerNewsLetter.innerHTML = template;
        const newsletterBody = new NewsletterPopin(document.querySelector(this.selectorPopin));
        newsletterBody.init();

        this.grid.setAttribute('aria-hidden', 'true');
        this.btnMetanav.setAttribute('aria-expanded', 'true');
      })
      .catch((e) => console.log(e.message));

    _dispatchTrackingEvents();
  }

  openStickyBannerNewsletter() {
    this.stickyBannerNewsletter.setAttribute('data-active', 'true');
    _dispatchEventsImpression();
  }

  closeStickyBannerNewsletter() {
    this.stickyBannerNewsletter.setAttribute('data-active', 'false');
    createCookie(ALREADY_ASK_NEWSLETTER, true, ASK_NEWSLETTER_POPIN_DURATION);
  }
}

export default Newsletter;
