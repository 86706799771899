import { ajax, displaySegment } from '@global-js/modules/recommendation/utils';

const state = {
  cookies: {},
  playlistId: '#playlist--v2',
  playlist: null,
};

const callback = () => {
  displaySegment(state.playlist);
};

/**
 * @param {string} schoolLevel
 * @param {string} type
 * @param {string} slug
 */
const getPlaylist = (schoolLevel, type, slug) => {
  if (typeof schoolLevel === 'undefined') {
    return;
  }

  ajax(
      `playlist/${schoolLevel}/${type}/${slug}`,
      type,
      state.playlistId,
      callback,
  );
};

/**
 * @param {Object} cookies
 * @constructor
 */
export const Playlist = (cookies) => {
  state.playlist = document.querySelector(state.playlistId);

  if (!state.playlist) {
    return;
  }

  state.cookies = cookies;

  const { schoolLevel } = state.cookies || '';
  const { type, slug } = state.playlist.dataset;

  getPlaylist(schoolLevel, type, slug);
};
