const classCollapse = '.collapse-v2';

/**
 * @param {Element} target
 */
const onClick = ({ target }) => {
  const element = target.closest(classCollapse);
  const parentId = element.getAttribute('aria-controls');
  const description = document.querySelector(`#${parentId}`);
  const descriptionHidden = description.querySelector('.preFooter__description--hidden');

  description.setAttribute('aria-expanded', `${!(description.getAttribute('aria-expanded') === 'true')}`);
  description.classList.toggle('collapsed');
  description?.focus();
  descriptionHidden?.focus();
  element.classList.toggle('rotate');
};

export const Collapse = () => {
  const elements = document.querySelectorAll(classCollapse);

  Array.from(elements).forEach((element) => {
    element.addEventListener('click', onClick, false);
  });
};
