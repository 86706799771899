import { getCookies, createCookie } from '../../utils/cookies';

const FTV_GEO_LOCALIZATION_URL = 'https://geo-info.ftven.fr/ws/edgescape.json';
const USER_IS_ABROAD_COOKIE_NAME = 'lumni_is_user_abroad';
const COOKIE_DURATION_DAYS = 1;
const FRANCE_COUNTRY_CODE = ['FR', 'BL', 'GF', 'GP', 'MF', 'MQ', 'NC', 'PF', 'PM', 'RE', 'TF', 'WF', 'YT'];

const DEFAULT_RESPONSE = {
  reponse: {
    geo_info: {
      country_code: 'FR',
    },
  },
};

/**
 * @param {boolean} isUserAbroad
 * @private
 */
const _dispatchEvent = (isUserAbroad) => {
  document.dispatchEvent((new CustomEvent('userAbroad', { detail: isUserAbroad })));
};

/**
 * @param {Object} jsonData
 * @private
 */
const _setCookie = (jsonData) => {
  const isUserAbroad = !FRANCE_COUNTRY_CODE.includes(jsonData.reponse.geo_info.country_code);

  createCookie(USER_IS_ABROAD_COOKIE_NAME, isUserAbroad ? 'true' : 'false', COOKIE_DURATION_DAYS);
  _dispatchEvent(isUserAbroad);
};

export const SetIsUserAbroad = () => {
  const cookies = getCookies([USER_IS_ABROAD_COOKIE_NAME]);

  if (typeof cookies[USER_IS_ABROAD_COOKIE_NAME] !== 'undefined') {
    return;
  }

  fetch(FTV_GEO_LOCALIZATION_URL)
    .then((response) => {
      if (response.ok) return response.json();
      throw new Error('error');
    })
    .then((json) => _setCookie(json))
    .catch(() => _setCookie(DEFAULT_RESPONSE));
};
