import { isConnected } from '../../../../../global/assets/scripts/modules/security/session/user';
import { ajaxHTML } from '../../../../../global/assets/scripts/utils/ajax';

export const userRanking = () => {
  if (!isConnected) return;
  const btn = document.querySelector('#rank-tab');
  btn.addEventListener('click', () => {
    ajaxHTML(`${window.location.origin}/ajax/ranking`, 'get', null, false, 'no-cache')
      .then((response) => response.text())
      .then((template) => {
        const container = document.querySelector('#container-ranking');
        container.innerHTML = template;

        const currentLevel = sessionStorage.getItem('lumni_student_user_level');
        const lumnizLevels = document.querySelectorAll('.lumniz__level--profil');
        lumnizLevels.forEach((level) => {
          level.innerHTML = currentLevel;
        });
      })
      .catch((e) => console.log(e.message));
  });
};
