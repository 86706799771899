import { FormValidation } from '../../../molecules/security/form/validation';
import { RegistrationConditions } from '../../../molecules/security/registration_conditions/registration_conditions';

const inputs = {
  email: '#form_email',
};

const _dispatchTrackingEvents = (data) => {
  document.dispatchEvent(new CustomEvent('Tracking:login', {
    detail: { type: 'security', data },
  }));
};

export const FormForgotPassword = () => {
  // Temporary
  if (!document.querySelector('.formForgotPasswordV2')) return;

  // Dispatch event for Piano Tracking
  if (window.LUMNI_CONTEXT.headers['ftv-account-not-exists-error']) {
    _dispatchTrackingEvents({
      action: 'error',
      error_message: 'il n y a pas de compte enregistré avec cet email',
    });
  }

  if (window.LUMNI_CONTEXT.headers['ftv-pwd-email-sent']) {
    _dispatchTrackingEvents({ action: 'success', click: 'reinitialiser-le-mot-de-passe' });
  }

  FormValidation(inputs);
  RegistrationConditions();
};
