export const btnLumniz = () => {
  const lumnizCoinBtn = document.querySelectorAll('.btn_lumniz');

  lumnizCoinBtn.forEach((btn) => btn.addEventListener('click', () => {
    document.dispatchEvent(new CustomEvent('Tracking:click', {
      detail: {
        type: 'lumnizCoin',
      },
    }));
  }));
};
