export const ThematicTitleEmoji = () => {
  const title = document.querySelector('.content__title-v2.crooked-title-multi');
  const emoji = document.querySelector('img.crooked-title-multi__emoji');
  const firstLine = title.querySelector('span:first-child').offsetWidth;
  const secondLine = title.querySelector('span:nth-child(2)').offsetWidth;
  const translateX = `${firstLine * 0.0625}rem`;
  const translateXBackup = `${secondLine * 0.0625}rem`;
  const fullVw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

  if (!title) {
    return;
  }

  if (firstLine + emoji.offsetWidth >= fullVw) {
    emoji.style.left = translateXBackup;
    emoji.style.top = '3rem';
  } else {
    emoji.style.left = translateX;
  }
};
