const AT_INTERNET_ID = 'c:atinterne-D22mcTNf';
const ESTAT_ID = 'c:estat-P2GUgJcz';
const YOUBORA_ID = 'c:youbora-YdPrzZLh';

/**
 * @param {String} label
 * @param {String} consentId
 */
const addToLocalStorage = (label, consentId) => {
  window.localStorage.setItem(
    label,
    window.didomiState?.didomiVendorsConsent?.includes(consentId).toString() ?? 'false',
  );
};

/*
  # CMP Optout popin

  ## Parameters (optionnal):
  - link `Link selector to show popin`
  - target `Target container selector to wrap the popin`
  - storage `Name of the LocalStorage variable to store the user choice`
  - contentTitle `Title of the popin`
  - contentText `Text of the popin`
  - checkbox `Text of the label's checkbox`
  - button `Text of the back button`
  - popinId `Id of the popin`
  - css `CSS replacement`

  ## Call example:
  `
  cmpOptoutPopin('#popin-link','','storageVar','Popin title')
  `
*/
export const cmpOptoutPopin = (
  link,
  target,
  storage,
  contentTitle,
  contentText,
  checkbox,
  button,
  popinId,
  css,
) => {
  const module = {
    default: {
      link: '[href="https://www.francetelevisions.fr/groupe/confidentialite/gestion-des-cookies#cookie-usage"]',
      target: '#didomi-host>div',
      storage: 'optoutAT',
      contentTitle: 'Les traceurs de mesure d\'audience eStat et AT internet',
      contentText: `
        <p>Notre site utilise des traceurs de mesure d’audience afin de mesurer l’audience de nos services et de nos contenus (les statistiques peuvent être par exemple : le nombre de visites, de pages consultées, le temps passé sur le site, la région de connexion, le type d'appareil et de navigateur utilisés, etc.).</p>
        <p><b>Conformément à l’article 82 de la loi Informatique et Libertés, complété par la délibération de la CNIL n°2020-091 du 17 septembre 2020, ces traceurs peuvent, sous certaines conditions, entrer dans la catégorie des traceurs <u>strictement nécessaires au fonctionnement de nos services</u> et bénéficier d’une exemption de consentement.</b></p>
        <p>En consentant au dépôt et à la lecture des traceurs de mesure d’audience, vous nous permettez de mesurer l'audience des contenus présents sur le site internet ou l’application utilisé et d'en améliorer le fonctionnement à partir des données de navigation et de profil vous concernant.</p>
        <p>En ne consentant pas au dépôt des traceurs de mesure d’audience, notre mesure d’audience est limitée aux critères définis par la CNIL pour être strictement nécessaire au bon fonctionnement de nos services et donc exemptée de consentement ; ainsi, les données utilisées ne permettent que la production de statistiques de visite de nos sites et contenus anonymes.</p>
        <p><u><b>Quels sont les traceurs de mesure d’audience exemptés de consentement et comment exercer votre droit d’opposition pour ce navigateur et terminal ?</b></u></p>
        <p>Le traceur « Estat » de Médiamétrie mesure les audiences des contenus vidéo. Il permet notamment à France Télévisions de collecter les informations nécessaires à la rémunération des ayants droit par les sociétés de gestion collective, conformément au code de la propriété intellectuelle.</p>
        <p>La rémunération des ayants droit constituant un motif légitime et impérieux au sens de l’article 21 du Règlement général sur la protection des données, France Télévisions ne donne pas automatiquement la possibilité de s’opposer à ce traceur.</p>
        <p>Vous pouvez néanmoins faire valoir les raisons tenant à votre situation particulière pour demander à vous opposer à ce traceur en envoyant un email à l’adresse dpd@francetv.fr</p>
        <p>Le traceur « AT internet » mesure l’audience de nos sites et applications.</p>
        <p>Vous pouvez vous y opposer en cochant la case ci-dessous : « Je m’oppose au traceur de mesure d’audience AT Internet » .</p>
        <p>L’opposition au dépôt de traceurs de mesure d’audience exemptés de consentement peut entrainer le dépôt d’un traceur signalant votre opposition. Ce traceur n’entraîne pas de collecte de donnée et sera conservé pendant une durée de treize mois sur le navigateur et le terminal utilisé.</p>
      `,
      checkbox: 'Je m’oppose au dépôt du traceur de mesure d’audience',
      button: 'retour aux paramétrages',
      popinId: 'ddm-ppn--trckng-cnsnt',
    },
    arguments: {
      link, target, storage, contentTitle, contentText, checkbox, button, popinId, css,
    },
    getConfig: (cfg) => ((module.arguments[cfg] && module.arguments[cfg].trim().length > 0)
      ? module.arguments[cfg]
      : module.default[cfg]),
    popin: {
      element: document.createElement('div'),
      className: 'didomi-popup-backdrop',
    },
  };

  module.config = {
    link: module.getConfig('link'),
    target: module.getConfig('target'),
    storage: module.getConfig('storage'),
    contentTitle: module.getConfig('contentTitle'),
    contentText: module.getConfig('contentText'),
    checkbox: module.getConfig('checkbox'),
    button: module.getConfig('button'),
    popinId: module.getConfig('popinId'),
  };

  module.default.css = `
    #${module.config.popinId} {
      background: initial;
    }
    #${module.config.popinId} .ddm-ppn__cntnr {
      padding-top: var(--didomi-ppn-padding);
    }
    #${module.config.popinId} .ddm-ppn__cntnr__ttl {
      font-family: Poppins-Light, Arial, sans-serif;
      font-size: 20px;
      font-weight: 700;
      margin: 0;
    }
    #${module.config.popinId} .ddm-ppn__cntnr__txt {
      margin-top: .9em;
    }
    #${module.config.popinId} .ddm-ppn__cntnr__scrllr {
      overflow-y: scroll;
      margin: 0 var(--didomi-ppn-padding);
      padding-bottom: var(--didomi-ppn-padding);
    }
    #${module.config.popinId} p:last-child {
      margin-bottom: 0;
    }
    #${module.config.popinId} .ddm-ppn__cntnr__fxd {
      align-items: center;
      border-top: 1px solid #c8cacb;
      display: flex;
      flex-direction: column;
      padding: var(--didomi-ppn-padding);
    }
    #${module.config.popinId} .ddm-ppn__cntnr__chck label {
      align-items: center;
      cursor: pointer;
      display: flex;
      font-family: Poppins-SemiBold, Arial, sans-serif;
      font-weight: 700;
    }
    #${module.config.popinId} .ddm-ppn__cntnr__chck input {
      margin-right: .5em;
    }
    #${module.config.popinId} .ddm-ppn__cntnr__btns {
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    .didomi-screen-xsmall #${module.config.popinId} .ddm-ppn__cntnr__btns {
      display: block;
    }
    #${module.config.popinId} .ddm-ppn__cntnr__btn {
      border: 1px solid;
      border-radius: 50px;
      cursor: pointer;
      display: block;
      line-height: 18px;
      padding: 6px 14px;
    }
    #didomi-host #${module.config.popinId} .ddm-ppn__cntnr__btns .ddm-ppn__cntnr__btn {
      flex: 0 auto;
    }
    #didomi-host input[type="checkbox"] {
      -webkit-appearance: checkbox;
      appearance: checkbox;
    }
  `;

  module.config.css = module.getConfig('css');
  module.template = `
    <style type="text/css" scoped="true">${module.config.css}</style>
    <div class="ddm-ppn didomi-popup-view">
      <div class="ddm-ppn__cntnr didomi-popup-container" role="dialog" aria-modal="true">
        <div class="ddm-ppn__cntnr__scrllr didomi-popup-notice-text-container">
          <h1 class="ddm-ppn__cntnr__ttl">${module.config.contentTitle}</h1>
          <div class="ddm-ppn__cntnr__txt">${module.config.contentText}</div>
        </div>
        <div class="ddm-ppn__cntnr__fxd didomi-buttons">
          <div class="ddm-ppn__cntnr__chck">
            <label><input type="checkbox" />${module.config.checkbox}</label>
          </div>
          <div class="ddm-ppn__cntnr__btns">
            <button class="ddm-ppn__cntnr__btn ddm-ppn__cntnr__btn--cls didomi-button">
              <span>${module.config.button}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  `;

  module.popin.element.className = module.popin.className;
  module.popin.element.id = module.config.popinId;
  module.popin.element.innerHTML = module.template;

  document.addEventListener('click', (event) => {
    if (event.target.closest(module.config.link)) {
      event.preventDefault();

      const container = document.querySelector(module.config.target);
      const btn = module.popin.element.querySelector('button');
      const input = module.popin.element.querySelector('input[type="checkbox"]');

      container.appendChild(module.popin.element);

      if (window.localStorage && window.localStorage.getItem(module.config.storage) !== null) {
        input.checked = window.localStorage.getItem(module.config.storage) !== 'false';
      }

      const closePopin = (e) => {
        e.preventDefault();

        btn.removeEventListener('click', closePopin);
        window.localStorage.setItem(module.config.storage, input.checked);

        if (input.checked) {
          window.localStorage.setItem('consent_at', 'false');
          window.localStorage.setItem('consent_youbora', 'false');
        } else {
          addToLocalStorage('consent_at', AT_INTERNET_ID);
          addToLocalStorage('consent_estat', ESTAT_ID);
          addToLocalStorage('consent_youbora', YOUBORA_ID);
        }

        container.removeChild(module.popin.element);
      };

      btn.addEventListener('click', closePopin);
    }
  });
};

export const listenConsentChange = () => {
  window.didomiEventListeners = window.didomiEventListeners || [];
  window.didomiEventListeners.push({
    event: 'consent.changed',
    listener: () => {
      addToLocalStorage('consent_at', AT_INTERNET_ID);
      addToLocalStorage('consent_estat', ESTAT_ID);
      addToLocalStorage('consent_youbora', YOUBORA_ID);
    },
  });
  window.didomiEventListeners.push({
    event: 'notice.shown',
    listener: () => {
      document.dispatchEvent((new CustomEvent('layer:didomi-open')));
    },
  });
};

export const changeCloseLabelTxt = () => {
  const disagreeBtn = document.querySelector('.didomi-disagree-button');
  window.addEventListener('DOMContentLoaded', () => {
    if (disagreeBtn) {
      disagreeBtn.ariaLabel = 'Continuer sans accepter: refuser notre traitement des données et fermer';
    }
  });
};
