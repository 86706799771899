import { updateState } from '../utils';

const DEFAULT_VALUE = 'default';

/**
 * @param {HTMLInputElement} input
 */
export const checkSchoolLevel = (input) => {
  const value = input.value.trim();
  const isValid = value.length > 0 && value !== DEFAULT_VALUE;

  updateState(input, isValid);
};
