const CONFIG = {
  suggestionButtons: '.search__suggestions button',
};

/**
 * @param {HTMLButtonElement} currentTarget
 * @private
 */
const _onClick = ({ currentTarget }) => {
  document.dispatchEvent(new CustomEvent('Search:suggestion:click', {
    detail: {
      query: currentTarget.dataset.term,
    },
  }));
};

/**
 * @private
 */
const _enableSuggestion = () => {
  document.querySelectorAll(CONFIG.suggestionButtons).forEach((button) => {
    button.addEventListener('click', _onClick, false);
  });
};

/**
 * @constructor
 */
export const Suggestion = () => {
  document.addEventListener('Search:refresh', _enableSuggestion, false);
};
