import { isConnected } from '../../../../../global/assets/scripts/modules/security/session/user';

export const cardInfoLumniz = () => {
  if (isConnected()) {
    const additionalInfo = document.querySelector('.card-lumniz--info-notlogged');
    additionalInfo.classList.add('hidden');
  }

  const lumnizLandingBtn = document.querySelectorAll('.card-lumniz--see-more');
  lumnizLandingBtn.forEach((btn) => btn.addEventListener('click', () => {
    document.dispatchEvent(new CustomEvent('Tracking:click', {
      detail: {
        type: 'lumnizLanding',
      },
    }));
  }));
};
