const CONFIG = {
  'slider__see-more': 'voir_plus_slider',
  'segment__title--link': 'voir_plus_chevron',
  'btn--see-all-content': 'voir_plus_tous_les_contenus',
};

const _dispatch = (data = {}) => {
  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: { type: 'see_more_segment', data },
  }));
};

export const onClickSeeMoreLinks = ({ detail = {} }) => {
  const container = detail.container || document;
  const links = container.querySelectorAll('.slider__see-more, .segment__title--link, .btn--see-all-content');

  if (links.length === 0) {
    return;
  }

  Object.keys(CONFIG).forEach((selector) => {
    const elements = container.querySelectorAll(`.${selector}`);

    elements.forEach((element) => {
      element.addEventListener('click', () => {
        _dispatch({ zone: CONFIG[selector] });
      }, false);
    });
  });
};

export const hitClickSeeMoreLinks = () => {
  onClickSeeMoreLinks({});
  document.addEventListener('refreshAddPlaylist', onClickSeeMoreLinks);
};
