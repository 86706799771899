import { CONFIG } from './utils';

const SCROLL_TRANSITION = 'smooth';
const INTERVAL = 9000;

let slideInterval = null;

/**
 * @param slider
 * @return {HTMLElement}
 * @private
 */
const _getCurrentDot = (slider) => slider.querySelector('.slider__controls-dot--active');

/**
 * @param {HTMLElement|Object} currentTarget
 * @private
 */
const _scrollSlider = ({ currentTarget }) => {
  const slider = currentTarget.closest(CONFIG.slider);
  const wrapper = slider.querySelector(CONFIG.wrapper);

  // Next Slide
  const slideToGo = parseInt(currentTarget.dataset.slide, 10);

  // Current Position of the slider
  const currentDot = _getCurrentDot(slider);
  const currentSlide = parseInt(currentDot.dataset.slide, 10);

  // Number of slides between the selected slide
  const numberOfSlidesBetween = slideToGo - currentSlide;
  const nbCardsToScroll = Math.abs(numberOfSlidesBetween);

  // Number of pixels to scroll
  const slideSize = slider.querySelector(CONFIG.slide).offsetWidth;
  const scrollBy = ((slideSize) * nbCardsToScroll) * (numberOfSlidesBetween < 0 ? -1 : 1);

  // Scrolling
  wrapper.scrollTo({
    left: wrapper.scrollLeft + scrollBy,
    behavior: SCROLL_TRANSITION,
  });
};

/**
 * @param {HTMLElement} slider
 */
const startAutoPlay = (slider) => {
  slideInterval = setInterval(() => {
    const currentDot = _getCurrentDot(slider);
    const currentSlide = parseInt(currentDot.dataset.slide, 10);

    const wrapper = slider.querySelector(CONFIG.wrapper);
    const nextSlide = currentSlide + 1 > wrapper.childElementCount ? 1 : currentSlide + 1;
    const target = slider.querySelector(`${CONFIG.dot}[data-slide="${nextSlide}"]`);

    _scrollSlider({ currentTarget: target });
  }, INTERVAL);
};

/**
 * @param {HTMLElement} slider
 * @private
 */
const _attachAutoPlayEvent = (slider) => {
  ['keydown', 'mouseenter', 'touchstart', 'mouseover'].forEach((event) => {
    slider.addEventListener(event, () => clearInterval(slideInterval), false);
  });

  slider.addEventListener('mouseleave', () => startAutoPlay(slider), false);

  clearInterval(slideInterval);
  startAutoPlay(slider);
};

/**
 * @param {HTMLElement} slider
 */
export const enableSliderControlsDots = (slider) => {
  const dots = slider.querySelectorAll(CONFIG.dot);
  dots.forEach((dot) => dot.addEventListener('click', _scrollSlider));

  _attachAutoPlayEvent(slider);
};
