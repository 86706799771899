import { record } from './CommonNavigationRecorder';
import { getCookies } from '../../utils/cookies';

let item = null;
const COMPLETION_THRESHOLD = 90;
const cookies = getCookies();

const STATES = {
  started: 'started',
  inProgress: 'in_progress',
  finished: 'finished',
};

/**
 * @param {string} eventName
 * @private
 */
const _dispatchEvent = (eventName) => {
  document.dispatchEvent(new CustomEvent(eventName, {
    detail: {
      type: item.type,
      slug: item.slug,
    },
  }));
};

/**
 * @private
 */
const _onRecordArticle = () => {
  _dispatchEvent('Viewed:API:add');

  record({
    type: item.type,
    slug: item.slug,
    state: STATES.finished,
    thematic: window.thematic || cookies.thematic,
  });
};

/**
 * @private
 */
const _onRecordGame = () => {
  _dispatchEvent('Viewed:API:add');

  record({
    type: item.type,
    slug: item.slug,
    state: STATES.started,
  });
};

/**
 * @param {Event} event
 * @private
 */
const _onRecordQuiz = (event) => {
  let state = STATES.started;
  const { progress, score } = event.detail;

  if (progress > 0) {
    state = progress > COMPLETION_THRESHOLD ? STATES.finished : STATES.inProgress;
  }

  if (state === STATES.started) {
    _dispatchEvent('Viewed:API:add');
  }

  record({
    type: item.type,
    slug: item.slug,
    state,
    score,
    progress: Math.floor(progress),
    thematic: window.thematic || cookies.thematic,
  });
};

/**
 * @param {Event} event
 * @private
 */
const _onRecordVideo = (event) => {
  const { time, progress } = event.detail;
  const options = event.detail.options || {};
  const state = (progress > COMPLETION_THRESHOLD) ? STATES.finished : STATES.inProgress;
  const eventName = state === STATES.finished ? 'Viewed:API:add' : 'Viewed:API:remove';

  _dispatchEvent(eventName);

  record({
    type: item.type,
    slug: item.slug,
    state,
    time,
    progress: Math.floor(progress),
    options,
    thematic: window.thematic || cookies.thematic,
  });
};

export const NavigationRecorder = () => {
  item = window.LUMNI_CONTEXT.item;

  document.addEventListener('navigation:article:record', _onRecordArticle, false);
  document.addEventListener('navigation:game:record', _onRecordGame, false);
  document.addEventListener('navigation:quiz:record', _onRecordQuiz, false);
  document.addEventListener('navigation:video:record', _onRecordVideo, false);
};
