const CLASS_SCROLLING_TOP = 'scrolling-top';
const UP = 1;
const DOWN = 2;
const POSITION_SCROLL_LIMIT = 65;

const getScrollToTop = () => window.scrollY || document.documentElement.scrollTop;

const state = {
  header: null,
  curScroll: null,
  direction: 0,
  prevDirection: 0,
  prevScroll: getScrollToTop(),
};

const onScroll = () => {
  const { classList } = state.header;
  state.curScroll = getScrollToTop();
  state.direction = state.curScroll > state.prevScroll ? DOWN : UP;

  if (state.direction === DOWN && state.curScroll > POSITION_SCROLL_LIMIT && !document.querySelector('.thematic-level--open')) classList.remove(CLASS_SCROLLING_TOP);
  else classList.add(CLASS_SCROLLING_TOP);

  state.prevDirection = state.direction;

  state.prevScroll = state.curScroll;
};

/**
 * Module to hide/show the header when scrolling down/up
 */
export const Header = () => {
  state.header = document.querySelector('.container--header-v2');

  if (state.header === null) {
    return;
  }

  window.addEventListener('scroll', onScroll, false);
};
