import { isConnected } from '../../../../global/assets/scripts/modules/security/session/user';

export const showBubbles = () => {
  const bubbleContainerFirst = document.querySelector('.display-chat.unlogged');
  const bubbleContainerNewsletter = document.querySelector('.display-chat.newsletter');

  // If user is connected > hide chat bubble
  if (isConnected()) {
    if (bubbleContainerFirst) {
      bubbleContainerFirst.style.display = 'none';
    }

    bubbleContainerNewsletter?.classList.remove('wrapper--no-border');
  }
};
