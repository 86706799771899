import { ajaxJson } from '@global-js/utils/ajax';
import { createCookie } from '@global-js/utils/cookies';

const MAPPING_CATEGORY = {
  favorite: 'favorite',
  favorites: 'favorite',
  watchLater: 'watchLater',
};

/**
 * @param {string} path
 * @param {string} type
 * @param {string} slug
 * @param {string} listName
 *
 * @returns {string}
 */
const getUrl = (path, type, slug, listName) => `${path}/${MAPPING_CATEGORY[listName]}/${type}/${slug}`;

/**
 * @param {string} eventName
 * @param {object} data
 * @private
 */
const _dispatchEvent = (eventName, data) => {
  document.dispatchEvent(new CustomEvent(eventName, { detail: data }));
};

class Api {
  constructor() {
    this.baseUrl = window.location.origin;
    this.path = '/ajax/mes-contenus';
  }

  /**
   * @param {string} type
   * @param {string} slug
   * @param {string} listName
   *
   * @returns {Promise}
   */
  addItemToList({ type, slug }, listName) {
    const category = MAPPING_CATEGORY[listName];

    createCookie('addfavorite', 'loggedon', 1);
    _dispatchEvent('Favorite:API:add', { type, slug, category });

    return ajaxJson(`${window.location.origin}${this.path}`, false, { slug, type, category });
  }

  /**
   * @param {string} type
   * @param {string} slug
   * @param {string} listName
   *
   * @returns {Promise}
   */
  removeItemFromList({ type, slug }, listName) {
    const category = MAPPING_CATEGORY[listName];
    _dispatchEvent('Favorite:API:remove', { type, slug, category });

    return ajaxJson(`${window.location.origin}${getUrl(`${this.path}/delete`, type, slug, listName)}`, false, null, 'post');
  }

  /**
   * @param {string} type
   * @param {string} slug
   * @param listName
   *
   * @returns {Promise}
   */
  isItemInList({ type, slug }, listName) {
    return ajaxJson(`${window.location.origin}${getUrl(this.path, type, slug, listName)}`);
  }
}

export default Api;
