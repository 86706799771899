import { accessibility } from '../../../../../../global/components/organisms/popup/accessibility';

/**
 * @param {string} action
 * @private
 */
const _dispatchEvents = (action) => {
  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: {
      type: 'popin_account',
      data: { action },
    },
  }));
};

/**
 * @private
 */
const _dispatchImpressionEvent = () => {
  document.dispatchEvent(new CustomEvent('Tracking:impression', {
    detail: { type: 'popin_account' },
  }));
};

/**
 * @param {HTMLElement} currentTarget
 * @private
 */
const _onClickInPopin = ({ currentTarget }) => {
  _dispatchEvents(currentTarget.dataset.action);
};

const _onClick = (popin) => {
  if (document.querySelector('.user__logged') || !popin) return;

  popin.classList.toggle('active');
  accessibility(popin);

  if (popin.classList.contains('active')) {
    _dispatchImpressionEvent();
  }
};

const removeActiveOnScroll = () => {
  const popin = document.querySelector('.popin__login');
  if (!popin) return;
  if (window.innerWidth < 1200 && window.scrollY > 100) {
    if (popin.classList.contains('active')) {
      popin.classList.remove('active');
    }
  }
};

export const LoginPopin = () => {
  const links = document.querySelectorAll('.btn--user__login');
  const popin = document.querySelector('.popin__login');

  links?.forEach((link) => {
    link.addEventListener('click', () => _onClick(popin), false);
  });

  const popinLinks = popin?.querySelectorAll('a');

  popinLinks?.forEach((link) => {
    link.addEventListener('click', _onClickInPopin, false);
  });

  if (popin) {
    document.addEventListener('keydown', (e) => {
      if (popin.classList.contains('active')) {
        if (e.key === 'Escape') {
          popin.classList.remove('active');
        }
      }
    });
    document.addEventListener('click', (e) => {
      if (popin.classList.contains('active')) {
        const targetElement = e.target;
        if (!targetElement.closest('.popin__login') && !targetElement.closest('#user-toggle-menu')) {
          popin.classList.remove('active');
        }
      }
    });
  }

  window.addEventListener('scroll', removeActiveOnScroll, false);
  window.addEventListener('resize', removeActiveOnScroll, false);
};
