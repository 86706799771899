const ALL_SELECTED = 'all';
const CONFIG = {};

/**
 * @param {Object} itemsCount
 * @private
 */
const _setFilterBtnCount = (itemsCount) => {
  const btnTitle = document.querySelector('#btn-popin-title');

  if (!btnTitle) return;

  btnTitle.textContent = itemsCount.size > 0
    ? `Filtr${itemsCount.size > 1 ? 'es' : 'e'} (${itemsCount.size})`
    : 'Filtrer';
};

/**
 * @param {boolean} isActive
 * @param {string} filter
 * @param {string} filterKey
 * @private
 */
const _setFiltersSelected = (isActive, filter, filterKey) => {
  if (!CONFIG[filterKey]) {
    CONFIG[filterKey] = new Set();
  }

  if (isActive) {
    filter === ALL_SELECTED
      ? CONFIG[filterKey].clear()
      : CONFIG[filterKey].add(filter);
  } else if (filter !== ALL_SELECTED) {
    CONFIG[filterKey].delete(filter);
  }

  _setFilterBtnCount(CONFIG[filterKey]);
};

/**
 * @param {NodeListOf} filters
 * @param {string} filterKey
 * @private
 */
const _setActiveState = (filters, filterKey) => {
  const filtersSelected = [...CONFIG[filterKey].values()];

  filters.forEach((btn) => {
    const { filter } = btn.dataset;
    btn.setAttribute('data-active', filtersSelected.includes(filter) ? 'true' : 'false');
  });

  // if there is no filter selected, if so, select the filter "all
  if (CONFIG[filterKey].size === 0) {
    filters[0].setAttribute('data-active', 'true');
  }
};

/**
 * @param {string} filter
 */
export const clearFilter = (filter) => {
  if (CONFIG[filter]) {
    CONFIG[filter] = new Set();
  }
};

/**
 * @param {HTMLElement} target
 * @param {string} filterSelector
 * @return {{}}
 */
export const toggleFilter = (target, filterSelector) => {
  const { active, filter } = target.dataset;
  const newState = !(active === 'true');

  const parent = target.parentNode;
  const filters = parent.querySelectorAll(filterSelector);

  // Step 1 - Store the current filters selected
  _setFiltersSelected(newState, filter, parent.dataset.key);

  // Step 2 - Update states of filters
  _setActiveState(filters, parent.dataset.key);

  // Then return all the filters selected
  return CONFIG;
};
