import smoothscroll from 'smoothscroll-polyfill';

// global
import Lazy from '@global-js/Lazy';
import Playlist from '@global-js/Playlist';
import Recommendation from '@global-js/Recommendation';
import Autocompletion from '@global/molecules/search/autocompletion';
import DescriptionContent from '@global/molecules/description/content/description_content';
import DescriptionContentV2 from '@v2/molecules/description/content/description_content';
import MobileDescription from '@global/molecules/description/content/mobile_description';
import OrderBy from '@global/molecules/orderBy/orderby';
import SearchBar from '@global/molecules/search/search_bar';
import KeepConnect from '@global/molecules/security/notifications/keepConnect';
import Filter from '@global/molecules/filter/filter';
import { popup } from '@global/organisms/popup/popup';
import { closeNotification } from '@global/molecules/security/notifications/notification';
import AdvancedFilter from '@lumni/organisms/filter/search/advanced_filter';
import PopupNewsletter from '@lumni/organisms/popup/newsletter/popup_newsletter';
import { GoBackArrow } from '@global-js/GoBackArrow';
import { incrementPageCount } from '@global-js/utils/countPageLoaded';
import {
  registerPushWorker,
  initNotificationsPrompt,
  initBannerNotifConfirmation,
  getTagsNotifications,
} from '@v2/pages/user/notifications';
import { isMobile } from '@global-js/utils/browser';
import { goUp } from './modules/utils/goUp';
import { quizGameLandscapeFullScreen } from './utils/quizGameLandscapeFullScreen';

export const AppCommon = (cookies, currentCookies) => {
  // Polyfill
  smoothscroll.polyfill();

  incrementPageCount();

  Lazy.init();
  MobileDescription.init();

  GoBackArrow();
  popup();
  closeNotification();
  goUp();

  new DescriptionContent().init();
  new DescriptionContentV2().init();
  new SearchBar().init();

  if (document.querySelector('.game') !== null || document.querySelector('#quiz') !== null) {
    quizGameLandscapeFullScreen();
  }

  // Get playlist based on cookies/item
  if (document.querySelector('.playlist') !== null) {
    new Playlist(cookies).setPlaylist();
  }

  // Get Recommendations based on cookies/item
  if (document.querySelector('#recommendations') !== null) {
    new Recommendation(cookies).setRecommendation();
  }

  // Search on HPP
  if (document.querySelector('#resource_query')) {
    new Autocompletion('#resource_query', '#completion', currentCookies).init();
  }

  // Search on Search Page
  if (document.querySelector('#resource_query_secondary')) {
    new Autocompletion('#resource_query_secondary', '#completion_secondary', currentCookies).init();
  }

  // Search on other pages
  if (document.querySelector('#searchCompletion') !== null) {
    new Autocompletion('#searchQuery', '#searchCompletion', currentCookies).init();
  }

  if (document.querySelector('.keep-connect')) {
    new KeepConnect().init();
  }

  if (document.querySelector('.filters')) {
    new Filter().init();
  }

  // Init Advanced Filter
  if (document.querySelector('#searchResults') !== null) {
    new AdvancedFilter().init();
    new OrderBy().init();
  }

  window.addEventListener('DOMContentLoaded', async () => {
    if (isMobile()) {
      initBannerNotifConfirmation();
      // eslint-disable-next-line no-undef
      if (typeof UA !== 'undefined' && UA !== null) {
        const navNotification = document.querySelectorAll('.notification-nav');
        if (navNotification.length > 0) {
          navNotification.forEach((nav) => {
            nav.setAttribute('data-active', 'true');
          });
        }
        initNotificationsPrompt();
      }

      if (document.querySelector('#notification-tags') !== null) {
        getTagsNotifications();
      }
    }
  });

  window.displayPopupNewsletter = () => {
    new PopupNewsletter().init();
  };
};

registerPushWorker();
