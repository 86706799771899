import {
  CONFIG,
  hideNavigation,
  isSliderAtTheStart,
  isSliderAtTheEnd,
  selectDotNavigation,
} from './utils';

const SLIDER_TYPE_ARROW = 'arrow';
const SLIDER_TYPE_DOT = 'dot';

/**
 * @param {string} type
 * @return {{root: null, rootMargin: string, threshold: (number[]|number)}}
 * @private
 */
function _getObserverOptions(type) {
  return {
    root: null,
    rootMargin: '0px',
    threshold: type === SLIDER_TYPE_ARROW ? [0, 1] : 0,
  };
}

class SliderObserver {
  /**
   * @param {HTMLElement} slider
   */
  constructor(slider) {
    this.slider = slider;
    this.type = this.slider.dataset.type;
    this.wrapper = this.slider.querySelector(CONFIG.wrapper);
  }

  observe() {
    const observer = this.getObserver();
    this.getSlidesToObserve().forEach((slide) => observer.observe(slide));
  }

  /**
   * @return {IntersectionObserver}
   */
  getObserver() {
    return new IntersectionObserver((entries) => {
      entries.forEach((entry) => this.updateNavigation(entry));
    }, _getObserverOptions(this.type));
  }

  /**
   * @param entry
   */
  updateNavigation(entry) {
    if (this.type === SLIDER_TYPE_DOT) {
      this.updateNavigationDot(entry);
    }

    if (this.type === SLIDER_TYPE_ARROW) {
      this.updateNavigationArrow(entry);
    }
  }

  /**
   * @param entry
   */
  updateNavigationDot(entry) {
    if (entry.isIntersecting) {
      const nextSlide = entry.target.dataset.slide;
      const nextDot = this.slider.querySelector(`${CONFIG.dot}[data-slide="${nextSlide}"]`);
      selectDotNavigation(this.slider, nextDot);
    }
  }

  /**
   * @param entry
   */
  updateNavigationArrow(entry) {
    const { slide } = entry.target.dataset;
    const { isIntersecting, intersectionRatio } = entry;

    // Handle intersection for horizontal and vertical scrolling
    const isAtExtremity = isIntersecting && intersectionRatio === 1;

    // CASE when the slider is at the start
    if (parseInt(slide, 10) === 1) {
      const isHidden = this.isSliderAtStart(isAtExtremity);
      hideNavigation(this.slider, CONFIG.prev, isHidden);
    }

    // CASE when the slider is at the end
    if (parseInt(slide, 10) === this.wrapper.childElementCount) {
      const isHidden = this.isSliderAtEnd(isAtExtremity);
      hideNavigation(this.slider, CONFIG.next, isHidden);
    }
  }

  /**
   * @param {boolean} isAtExtremity
   * @return {boolean}
   */
  isSliderAtStart(isAtExtremity) {
    return isAtExtremity || isSliderAtTheStart(this.wrapper);
  }

  /**
   * @param {boolean} isAtExtremity
   * @return {boolean}
   */
  isSliderAtEnd(isAtExtremity) {
    return isAtExtremity || isSliderAtTheEnd(this.wrapper);
  }

  /**
   * @return {Element[]}
   */
  getSlidesToObserve() {
    return this.type === SLIDER_TYPE_ARROW
      ? [
        this.slider.querySelector(`${CONFIG.slide}:last-child`),
        this.slider.querySelector(`${CONFIG.slide}:first-child`),
      ]
      : this.slider.querySelectorAll(`${CONFIG.slide}`);
  }
}

export default SliderObserver;
