/* trun ON or OFF error css (_a-input-widget.scss) */

const inputError = (bool, widget) => {
  if (bool) {
    widget.input.classList.add('c-widget__input--red');
    widget.label.classList.add('c-widget__label--red');
    return;
  }
  widget.input.classList.remove('c-widget__input--red');
  widget.label.classList.remove('c-widget__label--red');
};

export default inputError;
