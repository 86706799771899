import SliderObserver from '../../../organisms/slider/v2/observer';
import { SliderV2 } from '../../../organisms/slider/v2/slider';

export const slideToCurentLevel = () => {
  const levelTab = document.getElementById('cards-tab');
  const levelSlider = document.querySelector('.levels .slider');

  levelTab.addEventListener('click', () => {
    setTimeout(() => {
      const observer = new SliderObserver(levelSlider);
      observer.observe(levelSlider);
      SliderV2();
    }, 500);
  });
};
