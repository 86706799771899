export const quitPageListener = () => {
  const quitButtons = document.querySelectorAll('.js-quit-button');

  if (quitButtons !== undefined) {
    const buttons = Array.from(quitButtons);

    // eslint-disable-next-line array-callback-return
    buttons.map((button) => {
      button.addEventListener('click', () => {
        window.location = button.dataset.lastPage;
      });
    });
  }
};
