// eslint-disable-next-line import/no-cycle
import {
  checkMail,
  checkPassword,
  checkNewsLetter,
  fieldRequired,
  ageConsent,
  checkPasswordConfirmation,
} from './input';

// Execute individuals inputs checks and set the returned boolean in sessionStorage
export const inputListValidator = (widget, firstCheck = false) => {
  // eslint-disable-next-line default-case
  switch (widget.info) {
    case 'mail':
      sessionStorage.setItem('check_mail', checkMail(widget, firstCheck));
      break;
    case 'password':
      sessionStorage.setItem('check_password', checkPassword(widget, firstCheck));
      break;
    case 'passwordConfirmation':
      sessionStorage.setItem('check_password_confirmation', checkPasswordConfirmation(widget, firstCheck));
      break;
    case 'newsletterSubscription':
      sessionStorage.setItem('check_newsletterSubscription', checkNewsLetter(widget, firstCheck));
      break;
    case 'declarationHonnor':
      sessionStorage.setItem('check_declarationHonnor', checkNewsLetter(widget, firstCheck));
      break;
    case 'consent':
      sessionStorage.setItem('check_consent', checkNewsLetter(widget, firstCheck));
      break;
    case 'certifyAge':
      sessionStorage.setItem('check_ageConsent', ageConsent(widget, firstCheck));
      sessionStorage.setItem('check_parentConsent', ageConsent(widget, firstCheck));
      break;
    case 'parentConsent':
      sessionStorage.setItem('check_ageConsent', ageConsent(widget, firstCheck));
      sessionStorage.setItem('check_parentConsent', ageConsent(widget, firstCheck));
      break;
    case 'schoolLevel':
      sessionStorage.setItem('check_schoolLevel', fieldRequired(widget, null, firstCheck));
      break;
  }
};

// used on security-index to check actives inputs.
export const totalInputs = [];

export const securityChecker = () => {
  // making a true/false array from sesssionStorage.
  const listToCheck = [
    sessionStorage.getItem('check_mail'),
    sessionStorage.getItem('check_password'),
    sessionStorage.getItem('check_password_confirmation'),
    sessionStorage.getItem('check_newsletterSubscription'),
    sessionStorage.getItem('check_declarationHonnor'),
    sessionStorage.getItem('check_consent'),
    sessionStorage.getItem('check_pseudo'),
    sessionStorage.getItem('check_ageConsent'),
    sessionStorage.getItem('check_parentConsent'),
    sessionStorage.getItem('check_schoolLevel'),
  ];

  // removing undisplayed inputs.
  const filteredList = listToCheck.filter((item) => item !== null);

  // getting the bottom bar.
  const validate = document.getElementById('js-bottom-panel-fulfilled');
  const notFullfilled = document.getElementById('js-bottom-panel-not-fulfilled');

  if (validate === null || notFullfilled === null) {
    return;
  }

  // display bottomBar if every element of filtredList = true.
  if (
    filteredList.every((currentValue) => currentValue === 'true')
    && totalInputs.length === filteredList.length
    && filteredList.length >= 1
  ) {
    validate.className = 'c-panel';
    notFullfilled.className = 'is-hidden';

    return;
  }

  if (validate !== null) validate.className = 'is-hidden';
  if (notFullfilled !== null) notFullfilled.className = 'c-panel';
};
