import { updateState } from '../form/utils';
import { CONFIG } from '../form/config';

const certifyAgeInputSelector = 'certifyAge';
const certifyAgeInputs = [];

/**
 * @private
 */
const _getCertifyAgeInputs = () => {
  const certifyAgeInputsSelectors = document.querySelectorAll(`input[name="registration[${certifyAgeInputSelector}]"`);

  if (certifyAgeInputsSelectors) {
    certifyAgeInputs.push(...certifyAgeInputsSelectors);
    return certifyAgeInputs;
  }

  return [];
};

/**
 * @param onSubmit
 * @return {string|null}
 */
export const CheckCertifyAge = (onSubmit) => {
  let certifyAgeState = null;
  const certifyAge = _getCertifyAgeInputs(certifyAgeInputSelector);

  if (certifyAge.length === 0) {
    return certifyAgeState;
  }

  let selectedChoice = null;
  const consentYes = document.querySelector('#consentYes');
  const consentNo = document.querySelector('#consentNo');
  const parentConsent = document.querySelector('input[name="registration[parentConsent]"]');

  // eslint-disable-next-line no-restricted-syntax
  for (const item of certifyAge) {
    if (item.checked) {
      selectedChoice = item.value;
      break;
    }
  }

  if (!selectedChoice) {
    updateState(consentYes, false);
    const { state } = consentYes.closest(CONFIG.selectors.inputGroup).dataset;
    certifyAgeState = state;
  }

  if (selectedChoice) {
    switch (selectedChoice) {
      case '0':
        if (onSubmit) {
          if (parentConsent.checked) {
            updateState(consentNo, true);
          } else {
            updateState(consentYes, false);
          }
          const { state: stateWhenLessThan15Checked } = consentYes.closest(
            CONFIG.selectors.inputGroup,
          ).dataset;
          certifyAgeState = stateWhenLessThan15Checked;
        }
        break;

      case '1':
        updateState(consentYes, true);
        // eslint-disable-next-line no-case-declarations
        const { state: stateWhen15AndMoreChecked } = consentYes.closest(
          CONFIG.selectors.inputGroup,
        ).dataset;
        certifyAgeState = stateWhen15AndMoreChecked;
        break;

      default:
        break;
    }
  }

  return certifyAgeState;
};

/**
 * @private
 */
const _onChange = () => CheckCertifyAge(false);

export const CertifyAgeValidation = () => {
  const certifyAge = _getCertifyAgeInputs(certifyAgeInputSelector);

  if (certifyAge.length > 0) {
    const consentBlock = document.querySelector('#consent');
    consentBlock.addEventListener('change', _onChange, false);
  }
};
