import { createCookie } from '@global-js/utils/cookies';
import { check } from './validator';
import { STATE_FOCUS, STATE_ERROR, STATE_ERROR_REQUIRED } from './utils';
import { CONFIG } from './config';
import { resetPasswordHints } from './rules/password';
import { CheckCertifyAge } from '../consent/consent';
import { onClickRegristrationNewsletter } from '../newsletter/newsletter';

const inputs = [];
let hasErrorOnSubmit = false;

/**
 * @param {string} errorMessage
 * @private
 */
const _dispatchTrackingEvents = (errorMessage) => {
  document.dispatchEvent(new CustomEvent('Tracking:login', {
    detail: {
      type: 'security',
      data: { action: 'error', error_message: errorMessage },
    },
  }));
};

const sendTrakingOfErrorField = (inputsHasError) => {
  if (inputsHasError.length === 0) return;

  const { input, state } = inputsHasError[0];
  const message = 'vous avez une erreur dans le formulaire';

  if (state !== STATE_ERROR_REQUIRED && input.type !== 'select-one') {
    _dispatchTrackingEvents(input.closest(CONFIG.selectors.inputGroup).querySelector('.helper--format').textContent);
    return;
  }

  _dispatchTrackingEvents(message);
};

/**
 * @param {HTMLInputElement} input
 * @private
 */
const _setInputLabel = (input) => {
  input.classList.toggle(CONFIG.classNames.inputFilled, input.value.length > 0);
};

/**
 * @private
 */
const _resetHints = () => {
  const passwordInput = document.querySelector(`#${CONFIG.selectors.passwordInput}`);

  if (passwordInput !== null && passwordInput.value.trim().length === 0) {
    resetPasswordHints();
  }
};

/**
 * @private
 */
const _focusOnFirstError = () => {
  const inputInError = document.querySelector(`${CONFIG.selectors.inputGroupError} input`);
  inputInError?.select();
};

/**
 * @param {HTMLElement} currentTarget
 * @private
 */
const _onFocus = ({ currentTarget }) => {
  // Keep error state of the input when focusing on it after form submission
  if (currentTarget.classList.contains('input--error-from-submit')) {
    currentTarget.classList.remove('input--error-from-submit');
    return;
  }

  // Select input container
  const inputGroup = currentTarget.closest(CONFIG.selectors.inputGroup);

  // If field is required but empty we keep the state as "error-required"
  if (inputGroup.getAttribute('data-state') === STATE_ERROR_REQUIRED) return;

  // If field is required but in error when submitting the form we keep the state as "error"
  if (inputGroup.getAttribute('data-state') === STATE_ERROR && hasErrorOnSubmit) {
    hasErrorOnSubmit = false;
    return;
  }

  // Else we can add the state "focus"
  inputGroup.setAttribute('data-state', STATE_FOCUS);
};

/**
 * @param {HTMLElement} currentTarget
 * @param {HTMLElement} relatedTarget
 * @private
 */
const _onFocusOut = ({ currentTarget, relatedTarget }) => {
  if (relatedTarget && relatedTarget.classList.contains(CONFIG.classNames.eyeIcon)) return;

  check(currentTarget, false);
  _setInputLabel(currentTarget);
  hasErrorOnSubmit = false;
};

/**
 * @param {SubmitEvent} event
 * @returns {boolean}
 * @private
 */
const _onSubmit = (event) => {
  let nbErrors = 0;
  const inputsHasError = [];

  inputs.forEach((input) => {
    // Re check fields in case the user press enter
    check(input, true);
    const { state } = input.closest(CONFIG.selectors.inputGroup).dataset;
    if ([STATE_ERROR, STATE_ERROR_REQUIRED].includes(state)) {
      nbErrors += 1;
      inputsHasError.push({ input, state });
    }
  });

  const certifyAgeState = CheckCertifyAge(true);
  if ([STATE_ERROR, STATE_ERROR_REQUIRED].includes(certifyAgeState)) {
    nbErrors += 1;

    inputsHasError.unshift({ input: 'certifyAge', state: certifyAgeState });
  }

  const formLogin = document.querySelector('.formLoginV2');
  if (formLogin && nbErrors === 0) {
    const now = new Date().getTime() / 1000;
    createCookie('lumni_user_time_connect', now, 1);
  }

  if (nbErrors > 0) {
    // Cancel default submit
    event.preventDefault();
    hasErrorOnSubmit = true;
    _focusOnFirstError();
    _resetHints();
    sendTrakingOfErrorField(inputsHasError);

    return false;
  }

  const formEvent = event.currentTarget.dataset.event || null;

  if (formEvent !== null) {
    event.preventDefault();
    document.dispatchEvent(new CustomEvent(formEvent));
    onClickRegristrationNewsletter();

    return false;
  }

  return true;
};

/**
 * @param {Object} inputSelectors
 * @constructor
 */
export const FormValidation = (inputSelectors) => {
  Object.entries(inputSelectors).map((item) => {
    const input = document.querySelector(item[1]);
    if (input) inputs.push(input);
  });

  inputs.forEach((input) => {
    input.addEventListener('focusout', _onFocusOut, false);
    input.addEventListener('focus', _onFocus, false);

    if (CONFIG.selectors.passwordInput === input.id) {
      input.addEventListener('input', _onFocusOut, false);
    }
  });

  const form = document.querySelector('.formV2');
  form.addEventListener('submit', _onSubmit, false);

  // Focus on the first input in error if exists
  _focusOnFirstError();
  _resetHints();
};
