class UniverseSelector {
  constructor() {
    this.menu = document.querySelector('.header__list--establishments');
    this.subMenus = [];
    this.subMenuOpenIndex = null;
    // shortcuts to go to first or las element on submenu
    this.useShortcutKeys = true;
    this.menuButtons = [
      ...this.menu.querySelectorAll(
        'button[aria-expanded][aria-controls]',
      ),
    ];
    this.controlKeys = {
      up: 'ArrowUp',
      down: 'ArrowDown',
      left: 'ArrowLeft',
      right: 'ArrowRight',
      home: 'Home',
      end: 'End',
      escape: 'Escape',
    };
  }

  init() {
    this.menuButtons.forEach((button) => {
      if (button.tagName.toLowerCase() === 'button' && button.hasAttribute('aria-controls')) {
        const menu = button.parentNode.querySelector('ul');

        if (menu) {
          // save ref controlled menu
          this.subMenus.push(menu);
          // collapse menus
          button.setAttribute('aria-expanded', 'false');
          // attach event listeners
          menu.addEventListener('keydown', this.onMenuKeyDown.bind(this));
          button.addEventListener('click', this.onButtonClick.bind(this));
          button.addEventListener('keydown', this.onButtonKeyDown.bind(this));
        }
      } else {
        this.subMenus.push(null);
        button.addEventListener('keydown', this.onLinkKeyDown.bind(this));
      }
    });

    this.menu.addEventListener('focusout', this.onBlur.bind(this));
    this.menu.addEventListener('mouseover', this.onHoverMenu.bind(this));
  }

  onClickControlBtns(keyboardEvent, elementList, currentIndex) {
    switch (keyboardEvent.key) {
      case this.controlKeys.up:
      case this.controlKeys.left:
        keyboardEvent.preventDefault();
        if (currentIndex > -1) {
          const prevIndex = Math.max(0, currentIndex - 1);
          elementList[prevIndex].focus();
        }
        break;
      case this.controlKeys.down:
      case this.controlKeys.right:
        keyboardEvent.preventDefault();
        if (currentIndex > -1) {
          const nextIndex = Math.min(elementList.length - 1, currentIndex + 1);
          elementList[nextIndex].focus();
        }
        break;
      case this.controlKeys.home:
        keyboardEvent.preventDefault();
        elementList[0].focus();
        break;
      case this.controlKeys.end:
        keyboardEvent.preventDefault();
        elementList[elementList.length - 1].focus();
        break;
      default:
        break;
    }
  }

  onBlur(event) {
    const menuContainsFocus = this.menu.contains(event.relatedTarget);
    if (!menuContainsFocus && this.subMenuOpenIndex !== null) {
      this.toggleExpand(this.subMenuOpenIndex, false);
    }
  }

  onHoverMenu(event) {
    this.onBlur(event);
    document.activeElement.blur();
  }

  onButtonClick(event) {
    const button = event.target;
    const buttonIndex = this.menuButtons.indexOf(button);
    const buttonExpanded = button.getAttribute('aria-expanded') === 'true';
    this.toggleExpand(buttonIndex, !buttonExpanded);
  }

  onButtonKeyDown(event) {
    const targetButtonIndex = this.menuButtons.indexOf(document.activeElement);

    // close on escape
    if (event.key === this.controlKeys.escape) {
      this.toggleExpand(this.subMenuOpenIndex, false);
    } else if (
      this.useShortcutKeys
      && this.subMenuOpenIndex === targetButtonIndex
      && event.key === this.controlKeys.down
    ) {
      // move focus into the open menu if the current menu is open
      event.preventDefault();
      this.subMenus[this.subMenuOpenIndex].querySelector('a').focus();
    } else if (this.useShortcutKeys) {
      // handle arrow key navigation between top-level buttons, if set
      this.onClickControlBtns(event, this.menuButtons, targetButtonIndex);
    }
  }

  onLinkKeyDown(event) {
    const targetLinkIndex = this.menuButtons.indexOf(document.activeElement);

    // handle arrow key navigation between top-level buttons, if set
    if (this.useShortcutKeys) {
      this.onClickControlBtns(event, this.menuButtons, targetLinkIndex);
    }
  }

  onMenuKeyDown(event) {
    if (this.subMenuOpenIndex === null) {
      return;
    }

    const menuLinks = Array.prototype.slice.call(
      this.subMenus[this.subMenuOpenIndex].querySelectorAll('a'),
    );
    const currentIndex = menuLinks.indexOf(document.activeElement);

    // close on escape
    if (event.key === this.controlKeys.escape) {
      this.menuButtons[this.subMenuOpenIndex].focus();
      this.toggleExpand(this.subMenuOpenIndex, false);
    } else if (this.useShortcutKeys) {
    // handle arrow key navigation within menu links, if set
      this.onClickControlBtns(event, menuLinks, currentIndex);
    }
  }

  toggleExpand(index, expanded) {
    // close open menu, if applicable
    if (this.subMenuOpenIndex !== index) {
      this.toggleExpand(this.subMenuOpenIndex, false);
    }

    // handle menu at called index
    if (this.menuButtons[index]) {
      this.subMenuOpenIndex = expanded ? index : null;
      this.menuButtons[index].setAttribute('aria-expanded', expanded);
      UniverseSelector.toggleMenu(this.subMenus[index]);
    }
  }

  static toggleMenu(menuItem) {
    if (menuItem) {
      menuItem.parentNode.classList.toggle('active-tab-menu');
    }
  }
}

export default UniverseSelector;
