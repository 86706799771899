import { ajaxHTML, ajaxJson } from '@global-js/utils/ajax';
import { trapFocus } from '@global-js/utils/trapFocus';
import alts from './avatar__alts';

class PopinAvatar {
  constructor() {
    this.popinAvatarWrapper = '.popin-avatar__wrapper';
    this.popinAvatarContainer = '.popin-avatar__container';
    this.closeBtn = '.popin-avatar__close-cross';
    this.radio = '.avatar__radio:checked';
    this.submitBtn = '.popin-avatar__actions--submit';
    this.cancelBtn = '.popin-avatar__actions--cancel';
    this.overlay = '.popin-avatar__overlay';
    this.editBtn = '.avatar__edit';
    this.editBtnImg = '.avatar__display';
    this.alts = alts;
  }

  init() {
    this.onClickAvatarEdit();
  }

  onClickAvatarEdit() {
    if (!document.querySelector(this.popinAvatarWrapper)) return;
    const editBtn = document.querySelector(this.editBtn);
    editBtn.addEventListener('click', () => this.displayPopin());
  }

  displayPopin() {
    const popin = document.querySelector(this.popinAvatarWrapper);
    const avatarId = document.querySelector(this.editBtn).getAttribute('data-avatar');

    ajaxHTML(`${ window.location.origin }/ajax/update/avatar?avatarId=${ avatarId }`, 'get', null, false, 'no-cache')
      .then((response) => response.text())
      .then((template) => {
        popin.innerHTML = template;

        this.initPopin();
      })
      .catch((e) => console.log(e.message));
  }

  initPopin() {
    const popinContainer = document.querySelector(this.popinAvatarContainer);
    const close = document.querySelector(this.closeBtn);
    const submit = document.querySelector(this.submitBtn);
    const cancel = document.querySelector(this.cancelBtn);
    const overlay = document.querySelector(this.overlay);

    close.focus();
    document.querySelector('body').classList.add('body-blocked');
    trapFocus(popinContainer);
    close.addEventListener('click', () => this.closePopin());
    cancel.addEventListener('click', () => this.closePopin());
    overlay.addEventListener('click', () => this.closePopin());
    submit.addEventListener('click', () => this.updateAvatar());
    document.querySelector('body').addEventListener('keyup', this.onEscape, false);
  }

  onEscape = (event) => {
    if (event.key === 'Escape') {
      this.closePopin();
    }
  };

  closePopin() {
    const popin = document.querySelector(this.popinAvatarWrapper);

    popin.innerHTML = '';
    document.querySelector('body').classList.remove('body-blocked');
    document.querySelector(this.editBtn).focus();
  }

  applyNewAvatarToFront(avatarId) {
    const editBtn = document.querySelector(this.editBtn);
    const editBtnImg = document.querySelector(this.editBtnImg);

    editBtn.setAttribute('data-avatar', avatarId);

    if (editBtnImg) {
      editBtnImg.src = `/images/avatars/avatar-${ avatarId }.png`;
      editBtnImg.srcset = `/images/avatars/avatar-${ avatarId }.png 1x, /images/avatars/2x/avatar-${ avatarId }@2x.png 2x`;
      editBtnImg.alt = this.alts[`avatar-${ avatarId }.alt`];
    } else {
      const img = document.createElement('img');
      img.src = `/images/avatars/avatar-${ avatarId }.png`;
      img.srcset = `/images/avatars/avatar-${ avatarId }.png 1x, /images/avatars/2x/avatar-${ avatarId }@2x.png 2x`;
      img.alt = this.alts[`avatar-${ avatarId }.alt`];
      img.classList.add('avatar__display');
      editBtn.appendChild(img);
      editBtn.querySelector('.sr-only').remove();
      editBtn.classList.remove('default');
      document.querySelector('.avatar__default').remove();
    }
  }

  updateAvatar() {
    const avatarId = document.querySelector(this.radio).value;
    const data = {
      avatar: avatarId,
    };

    ajaxJson(`${ window.location.origin }/ajax/update/avatar`, false, data)
      .then((result) => {
        if (result) {
          this.applyNewAvatarToFront(avatarId);
          this.closePopin();
        }
      })
      .catch((e) => console.error(e.message));
  }
}

export default PopinAvatar;
