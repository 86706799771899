const { notifsData } = require('../../../security/datas/notifications-data');

export const notifsHandler = (hide) => {
  const notification = document.getElementById('js-bottom-notification');
  const closeButton = document.getElementById('js-bottom-notification-button');
  const hideIt = (hide === 'hide');

  const closeNotification = () => {
    notification.classList.add('is-hidden');
  };

  if (notification === null) {
    return;
  }

  if (hideIt === true) {
    notification.className = 'is-hidden';
  }

  setTimeout(closeNotification, 5000);

  // eslint-disable-next-line array-callback-return
  notifsData.map((notif) => {
    if (notif.info === null || notif.hook === null) {
      return;
    }

    if (notif.info === 'confirm-login') {
      setTimeout(() => { notification.className = 'is-hidden'; }, 5000);

      closeButton.addEventListener('click', () => {
        closeNotification();
      });
    }

    if ((notif.info === 'auth-error' || notif.info === 'bad-credentials')) {
      closeButton.addEventListener('click', () => {
        closeNotification();
      });
    }

    if ((notif.info === 'existing-account' || notif.info === 'expired-password')) {
      closeButton.addEventListener('click', () => {
        closeNotification();
      });
    }

    if (notif.info === 'lost-credentials') {
      closeButton.addEventListener('click', () => {
        closeNotification();
      });
    }
  });
};
