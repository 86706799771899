export const getQueryParams = (url) => {
  const result = {};
  const params = url.search;
  const queryParamArray = params.substr(1).split('&');

  if (params !== '') {
    queryParamArray.forEach((queryParam) => {
      const item = queryParam.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
  }

  return result;
};

export const getUrl = (params, url) => {
  const urlParameters = Object.keys(params)
    .map((key) => [key, params[key]].map(encodeURIComponent).join('='))
    .join('&');

  return `${url}?${urlParameters}`;
};

export const updateBrowserUrl = (relativeUrl, parameters) => {
  const newUrl = getUrl(parameters, `${window.location.origin}${relativeUrl}`);
  window.history.pushState(parameters, null, newUrl);
};
