/**
 * @param {string} title
 * @private
 */
const _dispatchEvent = (title) => {
  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: {
      type: 'pdf',
      data: { title },
    },
  }));
};

/**
 * @param {HTMLElement} currentTarget
 * @private
 */
const _oncClick = ({ currentTarget }) => {
  const title = currentTarget.querySelector('a[href]').text;
  _dispatchEvent(title);
};

export const pdfTracking = () => {
  const cardsButton = document.querySelectorAll('.card--card-button .cardButton--pdf');

  cardsButton.forEach((el) => {
    const element = el.closest('.card--card-button');
    element.addEventListener('click', _oncClick, false);
  });
};
