import { ajaxJson } from '@global-js/utils/ajax';
import { getParameters } from '../../utils/cookiesParameters';

const BASE_URL = window.location.origin;
const PATH = '/ajax/search/same-subject';
const sameSubjectElement = document.querySelector('.ckeditor-sameSubject');

/**
 * @param {string} slug
 * @param {string} type
 * @param {string} title
 * @private
 */
const _buildContentHtml = (slug, type, title) => {
  sameSubjectElement.innerHTML = `<p class='ckeditor-sameSubject__title'>
    <a href="${BASE_URL}/${type}/${slug}">SUR LE MEME SUJET - <span>${title}</span></a></p>`;
};

export const sameSubject = (cookies) => {
  const { itemSlug, itemType, schoolLevel } = getParameters(cookies);
  const { thematic } = cookies || '';
  const url = `${BASE_URL}${PATH}/${itemSlug}/${itemType}/${schoolLevel}/${thematic}`;

  ajaxJson(url)
    .then((data) => {
      if ([data.slug, data.type, data.title].includes(undefined)) {
        sameSubjectElement.remove();
        return;
      }
      _buildContentHtml(data.slug, data.type, data.title);
    })
    .catch((e) => console.log(e.message));
};
