const THRESHOLD = 500;

export const goUp = () => {
  const element = document.querySelector('.up-page');

  if (element === null) return;

  element.addEventListener('click', () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, false);

  document.addEventListener('scroll', () => {
    element.classList.toggle('hidden', window.scrollY < THRESHOLD);
  }, false);
};
