import inputListener from './security/listeners/input-listener';
import { inputData } from './security/datas/security-data';
import { inputListValidator, totalInputs } from './security/validators/form';
import loadSecurityApp from './security/helpers/load-security-app';
import tooglePasswordVisibility from './security/listeners/toogle-password-visibility';
import handleGinErrors from './security/helpers/handle-gin-errors';

class SecurityApp {
  static init() {
    if (document.querySelector('.c-form') === null) {
      return;
    }

    loadSecurityApp();

    /* map on widgets from security-data -- data-type : [{}]  */
    // eslint-disable-next-line array-callback-return
    inputData().map((widget) => {
      if (widget.input !== null && (widget.inputYes !== null || widget.inputNo !== null)) {
        totalInputs.push(widget);
      }
    });

    // then iteration on actives inputs.
    // eslint-disable-next-line array-callback-return
    totalInputs.map((widget) => {
      if (widget.input !== null && (widget.inputYes !== null || widget.inputNo !== null)) {
        inputListValidator(widget, true);
        inputListener(widget);
        tooglePasswordVisibility(widget);
        handleGinErrors(widget);
      }
    });
  }
}

export default SecurityApp;
