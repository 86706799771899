import { onClickFilter } from '@global-js/utils/filters';

class Filter {
  constructor() {
    this.filtersContainers = document.querySelectorAll('.filters');
    this.filtersMobile = document.querySelectorAll('.filters__mobile');
    this.filters = document.querySelectorAll('.filters__filter');
  }

  init() {
    if (this.filtersContainers.length === 0) {
      return;
    }

    this.filtersMobile.forEach((selector) => {
      selector.addEventListener('click', () => {
        selector.parentElement.classList.toggle('filters--open');
      });
    });

    [].forEach.call(this.filters, (filter) => {
      filter.addEventListener('click', ({ target }) => {
        onClickFilter(filter.closest('.filters'), target.closest('button'));
      });
    });
  }
}

export default Filter;
