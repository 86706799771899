import PopinGamification from '@v2/organisms/popup/gamification/popin_gamification';
import { TRANSLATE_LEVELSLABEL } from '../security/ui/security-ui';

export const TRANSLATE_BADGE_TITLE = {
  'math.success.title': 'Boss des maths',
  'game.success.title': 'Gameur',
  'quiz.success.title': 'Quizeur',
  'video.success.title': 'Vidéovore',
  'article.success.title': 'Décodeur',
  'MEA.success.title': 'Actuvore',
  'history.success.title': 'Historien du futur',
  'french.success.title': 'Maître des mots',
  'science.success.title': 'Savant des sciences',
  'art.success.title': 'Magicien des arts',
  'geographie.success.title': 'Géographe de l\'espace',
  basique: 'basique',
  epique: 'épique',
  legendaire: 'légendaire',
};

export const GamificationRecorder = () => {
  document.addEventListener('Gamification', ({ detail }) => {
    if (detail.type === 'MEA') return;
    if (detail.type === 'video' && detail.bonuses.videoFinishedAfterResume === true) {
      new PopinGamification().init(detail.points, 'play');
    } else {
      new PopinGamification().init(detail.points, detail.type);
    }

    const userLevel = sessionStorage.getItem('lumni_student_user_level');
    if (detail.level && userLevel < detail.level) {
      setTimeout(() => {
        new PopinGamification()._openPopinLevel('popin_gamification_level', detail);
        document.querySelector('.popin-gamification.level .popin-gamification__container--content .lumniz__level').innerHTML = detail.level;
        document.querySelector('.popin-gamification.level .popin-gamification__container--title span').innerHTML = TRANSLATE_LEVELSLABEL[`label.level.${detail.level}`];
      }, 1000);
    }

    if (detail.lastBadges?.length > 0) {
      let timing = detail.level && userLevel < detail.level ? 2000 : 1000;

      detail.lastBadges.forEach((badge, i) => {
        const position = i === 0 ? 'first' : 'second';
        const popin = `.popin-gamification.badge.${position}`;
        const nodeSelector = document.querySelector(popin);

        if (!popin) return;

        const type = badge.type === 'thematic' ? badge.thematic : badge.type;

        setTimeout(() => {
          nodeSelector.querySelector('.card__success__front').classList.add(`card__success__front--${badge.title}`);
          nodeSelector.querySelector('.success-img').setAttribute('src', `/images/success/${badge.title === 'legendaire' ? 'epique' : badge.title}/${type}.png`);
          nodeSelector.querySelector('.thematic-card').innerHTML = TRANSLATE_BADGE_TITLE[`${type}.success.title`];
          nodeSelector.querySelector('.text-thematic-card').innerHTML = TRANSLATE_BADGE_TITLE[`${type}.success.title`];
          nodeSelector.querySelector('.title-card').innerHTML = TRANSLATE_BADGE_TITLE[badge.title];
          nodeSelector.querySelector('.gem img').setAttribute('src', `/images/success/gems/${badge.title}.png`);
          nodeSelector.querySelector(`.popin-gamification__container--message.${badge.title}`).classList.add('display');
        }, timing);

        setTimeout(() => {
          new PopinGamification()._openPopinBadge('popin_gamification_badge', position, badge, detail, TRANSLATE_BADGE_TITLE[`${type}.success.title`]);
        }, parseInt(timing + 200, 10));

        timing += 1000;
      });
    }

    const lumniz = document.querySelectorAll('.btn_lumniz__score');
    const coins = document.querySelectorAll('.btn_lumniz__coin');

    lumniz.forEach((point) => {
      point.innerHTML = detail.memberPoints;
    });

    const emojiIcons = document.querySelectorAll('.emoji-user');
    emojiIcons.forEach((icon) => {
      const existingTextElement = icon.nextElementSibling;
      if (existingTextElement && existingTextElement.classList.contains('lumniz__level')) {
        existingTextElement.remove();
      }
      const textElement = document.createElement('p');
      textElement.classList.add('lumniz__level');
      textElement.textContent = detail.level;
      icon.parentNode.insertBefore(textElement, icon.nextSibling);
    });

    coins.forEach((coin) => {
      coin.classList.add('lumniz_bounce');
      setTimeout(() => {
        coin.classList.remove('lumniz_bounce');
      }, 2000);
    });

    sessionStorage.setItem('lumni_student_user_points', detail.memberPoints);
    sessionStorage.setItem('lumni_student_user_level', detail.level);
  }, false);
};
