import { updateState } from '../utils';

/**
 * @param {HTMLInputElement} input
 */
export const checkText = (input) => {
  const firstNameRegex = new RegExp(/^[-'\sa-zA-ZàâäèéêëîïôöùûüÿçæœñÀÂÄÈÉÊËÎÏÔÖÙÛÜŸÇÆŒÑ]{2,40}$/g);
  const isValid = firstNameRegex.exec(input.value.trim()) !== null;

  updateState(input, isValid);
};
