import { ajax, buildUrl } from '@global-js/modules/theme/utils';

let state = {};
const baseUrl = '/ajax/menuV2/';

/**
 * @param {Element} menuV2
 * @param {string} thematic
 */
const setMenuV2OnSameSchoolLevel = (menuV2, thematic) => {
  const level = menuV2.querySelector('.thematic-level');
  const currentThematic = level.querySelector('li.select-thematic');

  if (currentThematic) {
    currentThematic.classList.remove('select-thematic');
    currentThematic.querySelector(`li[data-slug="${thematic}"]`).classList.add('select-thematic');
  }

  menuV2.classList.remove('menu--hidden');
};

/**
 * @param {Object} stateTheme
 */
export const setMenuV2 = (stateTheme) => {
  state = stateTheme;

  const menuV2 = document.querySelector('.menuV2');

  if (!menuV2) {
    return;
  }

  if (!menuV2.classList.contains('menu--hidden')) {
    return;
  }

  const { schoolLevel, thematic } = window.LUMNI_CONTEXT.qualification;

  // If the school level of the item is the same as the cookie we just display the menu
  // and we select the thematic from the cookie
  if (state.cookies.schoolLevel === schoolLevel) {
    setMenuV2OnSameSchoolLevel(menuV2, thematic);
    return;
  }

  // Else we make call ajax to get the menu for the correct school level
  ajax(`${baseUrl}${buildUrl(state)}`, (template) => {
    const nextElement = menuV2.nextSibling;
    menuV2.remove();

    const placeholder = document.createElement('div');
    placeholder.innerHTML = template;

    const node = placeholder.firstElementChild;
    nextElement.insertAdjacentElement('beforebegin', node);

    document.querySelector('.menuV2').classList.remove('menu--hidden');
    document.dispatchEvent((new CustomEvent('reloadMenu')));
  });
};
