import Lazy from './Lazy';
import { ajaxHTML } from './utils/ajax';
import { getParameters } from './utils/cookiesParameters';

class Recommendation {
  /**
   * @param {Object} cookies
   */
  constructor(cookies) {
    this.cookies = cookies;
    this.recommendationContainer = '#recommendations';
  }

  setRecommendation() {
    const url = this.getUrl();

    if (url === null) {
      return;
    }

    ajaxHTML(url)
      .then((response) => response.text())
      .then((template) => {
        const container = document.querySelector(this.recommendationContainer);
        container.querySelector('.container').innerHTML += template;

        Recommendation.initPlugins();

        if (container.querySelector('.bloc-decouvrir') !== null) {
          container.classList.remove('hidden');
        }
        Recommendation.dispatchEvents(container);
      })
      .catch((e) => console.log(e.message));
  }

  /**
   * @return {string|null}
   */
  getUrl() {
    const { itemSlug, itemType, schoolLevel } = getParameters(this.cookies);
    const context = schoolLevel;

    if (typeof context === 'undefined' || typeof itemType === 'undefined' || typeof itemSlug === 'undefined') {
      return null;
    }

    return `${window.location.origin}/ajax/recommendation/${context}/${itemType}/${itemSlug}`;
  }

  static dispatchEvents(container) {
    document.dispatchEvent((new CustomEvent('refreshAddPlaylist', { detail: { container } })));
    document.dispatchEvent((new CustomEvent('refreshCardRedirection', { detail: { container } })));
  }

  static initPlugins() {
    Lazy.init('#recommendations .lazy', '#recommendations .lazy-picture');
  }
}

export default Recommendation;
