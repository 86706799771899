let state = {};

/**
 * @param {Element} establishment
 */
const setActiveSchoolLevel = (establishment) => {
  const cookieSchoolLevel = state.cookies.schoolLevel;
  const topBar = document.querySelector('.top-bar');

  const schoolLevel = establishment.querySelector(`li[data-slug="${cookieSchoolLevel}"]`);
  const schoolLevelMetanav = topBar.querySelector(`li[data-slug="${cookieSchoolLevel}"]`);

  if (schoolLevel) {
    schoolLevel.classList.add('header__sublist--active');
  }

  if (schoolLevelMetanav) {
    const link = schoolLevelMetanav.querySelector('a');
    schoolLevelMetanav.classList.add('top-bar--active');

    const bottomBarBtn = document.querySelector('.bottom-bar--button span');
    if (bottomBarBtn) {
      bottomBarBtn.innerHTML = link.textContent.trim();
    }
  }
};

const setActiveEstablishment = () => {
  const establishment = document.querySelector(`.header-v2 li[data-slug="${state.theme}"]`);

  if (establishment) {
    establishment.classList.add('header__list--active');
    setActiveSchoolLevel(establishment);
  }
};

/**
 * @param {Object} stateTheme
 */
export const setHeader = (stateTheme) => {
  state = stateTheme;
  setActiveEstablishment();
};
