import { switchTabOnArrowPress } from '@global-js/modules/user/user_a11y';

export const formA11y = () => {
  const form = document.querySelector('.c-form__main');
  const switchButtons = document.querySelectorAll('.c-switch-button__radio-label');
  const hiddenPassword = document.querySelectorAll('.c-input__password-eye-icon');

  if (!form) {
    return;
  }

  document.addEventListener('keydown', (event) => {
    switchTabOnArrowPress(event, switchButtons);

    Array.from(switchButtons)
      .forEach((button) => {
        const labelForText = button.getAttribute('for');
        const input = document.querySelector(`#${labelForText}`);
        const index = Array.from(switchButtons).indexOf(document.activeElement);
        if (index > -1) {
          const nextElement = switchButtons[index + 1] || switchButtons[0];
          if (event.keyCode === 13 && button === document.activeElement) {
            input.click();
            nextElement.setAttribute('tabindex', '-1');
            event.target.setAttribute('tabindex', '0');
          }
        }
      });

    Array.from(hiddenPassword)
      .forEach((button) => {
        if (event.keyCode === 13 && button === document.activeElement) {
          button.click();
        }
      });
  });
};
