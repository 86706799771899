import { getCookies, createCookie } from './utils/cookies';
import PopinGamification from '../../../v2/components/organisms/popup/gamification/popin_gamification';

class SuccessRegistration {
  static init() {
    const cookieFirstConnection = 'lumni_student_first_connection';
    const cookies = getCookies([cookieFirstConnection]);
    const registrationNotificationContainer = document.getElementById('js-success_registration');

    if (typeof cookies[cookieFirstConnection] !== 'undefined') {
      registrationNotificationContainer.classList.remove('is-hidden');
      document.cookie = `${cookieFirstConnection}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;

      // create cookie with timestamp for Usabilla
      const now = new Date().getTime() / 1000;
      createCookie('lumni_user_first_time', now, 1);

      new PopinGamification().init('50', 'account');

      document.dispatchEvent(new CustomEvent('Tracking:impression', {
        detail: {
          type: 'account_gamification',
        },
      }));
    }
  }
}

export default SuccessRegistration;
