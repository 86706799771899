export const List = () => {
  const btn = document.querySelector('.list__btn');

  if (btn === null) {
    return;
  }

  btn.addEventListener('click', () => {
    document.querySelector('.more').classList.toggle('hidden');
    document.querySelector('.less').classList.toggle('hidden');
    document.querySelector('.list__container').classList.toggle('list--display-max-8');
  }, false);
};
