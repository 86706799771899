import Lazy from '@global-js/Lazy';
import { ajaxHTML, ajaxJson } from '@global-js/utils/ajax';
import { trapFocus } from '../../../../../global/assets/scripts/utils/trapFocus';

class ButtonPlaylist {
  constructor(isUserConnected) {
    this.isUserConnected = isUserConnected;
    this.cardHeaderSelector = '.card__header';
    this.popinPlaylistContainer = '.popin__playlist--container';
    this.popinPlaylist = '.popin__playlist';
    this.closePopinBtn = '.popin__playlist__close';
    this.showFormBtn = 'button.create--list';
    this.listContainer = '.popin__playlist--list-container';
    this.list = '.popin__playlist--list';
    this.form = '.popin__playlist--form';
    this.input = 'input#playlistName';
    this.playlist = '.playlist__item';
    this.submitContainer = '.popin__playlist--submit-container';
    this.submitBtn = '.popin__playlist--submit-btn';
    this.submitCreateListBtn = '.formPlaylist_submit';
    this.cancelCreateListBtn = '.formPlaylist_cancel';
    this.toolboxPlaylistContainer = '.btn__action_playlist';
    this.toolboxPlaylistBtn = '.toolbox__btn_playlist';
    this.popinToastContainer = '.popin__toast_container';
    this.btnRemoveFromPlaylist = '.card__btn_trash';
    this.btnManagePlaylist = '.btn_manage_playlist';
    this.popinManagePlaylist = '.popin_manage_playlist';
    this.btnRemovePlaylist = '.btn_remove_playlist';
    this.popinRemovePlaylist = '.popin-remove--playlist';
    this.closePopinRemovePlaylistBtn = '.popin-remove--playlist__close-cross';
    this.overlayPopinRemovePlaylist = '.popin-remove--playlist__overlay';
    this.cancelPopinRemovePlaylist = '.popin-remove--playlist__actions--cancel';
    this.deletePlaylistBtn = '.popin-remove--playlist__actions--delete';
    this.timeoutId = null;
    this.setVariables();
  }

  setVariables() {
    this.cardSelector = '.card';
    this.cardHeaderSelector = '.card__btn_playlist';
    this.cardLinks = document.querySelectorAll(this.cardSelector);
  }

  init() {
    this.attachEventListeners();
    this.onClickToolbox();
    this.onClickBtnRemove();
    this.onClickBtnManagePlaylist();
    document.addEventListener('refreshAddPlaylist', this.onRefreshAddPlaylist.bind(this));
  }

  _dispatchTrackingClicks = (action, type, title) => {
    document.dispatchEvent(new CustomEvent('Tracking:click', {
      detail: {
        type: 'playlist_popin',
        data: { action, type, title },
      },
    }));
  };

  onClickToolbox() {
    if (!document.querySelector(this.toolboxPlaylistContainer)) return;
    const toolboxBtn = document.querySelector(this.toolboxPlaylistBtn);
    const toolboxContainer = document.querySelector(this.toolboxPlaylistContainer);
    toolboxBtn.addEventListener('click', () => this.displayPopin(toolboxContainer));
  }

  onClickBtnRemove() {
    const btnsRemove = document.querySelectorAll(this.btnRemoveFromPlaylist);
    if (!btnsRemove) return;
    btnsRemove.forEach((btn) => {
      btn.addEventListener('click', () => {
        const card = btn.closest('.card');
        const type = card.getAttribute('data-type');
        const slug = card.getAttribute('data-slug');
        const thumbnailId = card.getAttribute('data-thumbnail');
        const playlist = btn.closest('.user_playlist');
        const id = playlist ? playlist.getAttribute('data-playlistId') : null;
        this.removeFromPlaylist(id, type, slug, thumbnailId, card);
      });
    });
  }

  onClickBtnManagePlaylist() {
    const btn = document.querySelector(this.btnManagePlaylist);
    if (!btn) return;
    const popin = btn.nextElementSibling;
    btn.addEventListener('click', () => {
      popin.classList.toggle('active');
      const btnRemovePlaylist = popin.querySelector(this.btnRemovePlaylist);
      btnRemovePlaylist.addEventListener('click', () => {
        this.displayPopinRemovePlaylist();
      });
    });
  }

  displayPopinRemovePlaylist() {
    const popin = document.querySelector(this.popinRemovePlaylist);
    if (!popin) return;
    const btnDeletePlaylist = popin.querySelector(this.deletePlaylistBtn);
    const idPlaylist = popin.getAttribute('data-id');
    const elementsClose = [
      this.closePopinRemovePlaylistBtn,
      this.overlayPopinRemovePlaylist,
      this.cancelPopinRemovePlaylist,
    ];
    const handleClosePopin = () => this.closePopinRemovePlaylist(popin);
    popin.classList.add('active');
    trapFocus(popin);
    popin.querySelector(this.closePopinRemovePlaylistBtn).focus();
    elementsClose.forEach((selector) => {
      const element = popin.querySelector(selector);
      if (element) {
        element.addEventListener('click', handleClosePopin);
      }
    });
    btnDeletePlaylist.addEventListener('click', () => this.deletePlaylist(idPlaylist));
  }

  deletePlaylist(id) {
    const toastContainer = document.querySelector(this.popinToastContainer);
    const popin = document.querySelector(this.popinRemovePlaylist);
    ajaxHTML(`${window.location.origin}/ajax/member_playlists_delete/${id}`, 'post', '', false)
      .then((response) => response.text())
      .then((template) => {
        this.closePopinRemovePlaylist(popin);
        toastContainer.innerHTML = template;
        this.displayToast(toastContainer, true);
        setTimeout(() => {
          window.location.assign(`${window.location.origin}/mes-listes`);
        }, 2000);
      })
      .catch((e) => {
        console.log(e.message);
        this.closePopinRemovePlaylist(popin);
        this.displayToast(toastContainer, false);
      });
  }

  closePopinRemovePlaylist(popin) {
    popin.classList.remove('active');
    document.querySelector(this.btnRemovePlaylist).focus();
  }

  onRefreshAddPlaylist(event = null) {
    this.setVariables(event?.detail.container);
    this.detachEventListeners();
    this.attachEventListeners();
  }

  detachEventListeners() {
    this.cardLinks.forEach((cardLink) => {
      const actionBtn = cardLink.querySelector(this.cardHeaderSelector);
      actionBtn?.removeEventListener('click', () => this.displayPopin(cardLink));
    });
  }

  attachEventListeners() {
    this.cardLinks.forEach((cardLink) => {
      const actionBtn = cardLink.querySelector(this.cardHeaderSelector);
      actionBtn?.addEventListener('click', () => this.displayPopin(cardLink));
    });
  }

  clearPopins() {
    const popins = document.querySelectorAll(this.cardSelector);
    popins.forEach((link) => {
      const container = link.querySelector(this.popinPlaylistContainer);
      if (container) {
        container.innerHTML = '';
      }
    });
  }

  displayPopin(container) {
    const popinContainer = container.querySelector(this.popinPlaylistContainer);
    const slug = popinContainer.getAttribute('data-slug');
    const type = popinContainer.getAttribute('data-type');

    this.clearPopins();
    ajaxHTML(`${window.location.origin}/ajax/member_playlists?slug=${slug}&type=${type}`, 'get', null, false, 'no-cache')
      .then((response) => response.text())
      .then((template) => {
        popinContainer.innerHTML = template;

        if (!container.querySelector(this.popinPlaylist)) {
          this.initPopinNotLogged(container, popinContainer);
          return;
        }
        this.initPopin(container, popinContainer);
        this.initListState(popinContainer);
      })
      .catch((e) => console.log(e.message));
  }

  initPopin(container, popinContainer) {
    const elements = {
      btnClosePopin: popinContainer.querySelector(this.closePopinBtn),
      btnShowForm: popinContainer.querySelector(this.showFormBtn),
      form: popinContainer.querySelector(this.form),
      list: popinContainer.querySelector(this.list),
      slug: popinContainer.getAttribute('data-slug'),
      type: popinContainer.getAttribute('data-type'),
      thumbnailId: popinContainer.getAttribute('data-thumbnail'),
    };
    elements.btnClosePopin.focus();
    this.setupPopinEvents(elements, popinContainer, container);
  }

  initPopinNotLogged(container, popinContainer) {
    const elements = {
      btnClosePopin: popinContainer.querySelector(this.closePopinBtn),
      btnShowForm: popinContainer.querySelector(this.showFormBtn),
    };
    elements.btnClosePopin.focus();
    this.setupPopinNotLogged(elements, popinContainer, container);
  }

  setupPopinEvents(elements, popinContainer, container) {
    const {
      btnClosePopin, btnShowForm, form, list, slug, type, thumbnailId,
    } = elements;
    const btnSubmit = form.querySelector(this.submitCreateListBtn);
    const btnCancel = form.querySelector(this.cancelCreateListBtn);
    const lastFocusableElement = list.children.length > 0
      ? list.lastElementChild.querySelector('input')
      : btnShowForm;
    trapFocus(popinContainer, lastFocusableElement);
    this.onChangeCheckbox(container, popinContainer, list, slug, type, thumbnailId);
    btnSubmit.addEventListener('click', () => this.addNewPlaylist(container, form, slug, type, thumbnailId));
    btnShowForm.addEventListener('click', () => this.showForm(popinContainer, btnCancel));
    btnClosePopin.addEventListener('click', () => this.closePopin(container));
    btnCancel.addEventListener('click', () => this.closePopin(container));
    document.addEventListener('click', (event) => this.handleClickOutside(event, popinContainer));
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.closePopin(container);
      }
    });
    const submitContainer = popinContainer.querySelector(this.submitContainer);
    const btn = submitContainer.querySelector(this.submitBtn);
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          if (!submitContainer.classList.contains('hidden')) {
            trapFocus(popinContainer, btn);
          } else {
            trapFocus(popinContainer, lastFocusableElement);
          }
        }
      });
    });
    observer.observe(submitContainer, { attributes: true });
  }

  setupPopinNotLogged(element, popinContainer, container) {
    const {
      btnClosePopin,
      btnShowForm,
    } = element;
    trapFocus(popinContainer, btnShowForm);
    btnClosePopin.addEventListener('click', () => this.closePopin(container));
    document.addEventListener('click', (event) => this.handleClickOutside(event, popinContainer));
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.closePopin(container);
      }
    });
  }

  closePopin(container) {
    const popin = container.querySelector(this.popinPlaylistContainer);
    popin.innerHTML = '';
    if (container.classList.contains('toolbox__playlist')) {
      container.querySelector(this.toolboxPlaylistBtn).focus();
    } else {
      container.querySelector(this.cardHeaderSelector).focus();
    }
  }

  handleClickOutside(event, popin) {
    if (popin && !popin.contains(event.target)) {
      popin.innerHTML = '';
    }
  }

  initListState(container) {
    const checkboxes = container.querySelectorAll('input[type="checkbox"]');
    this.initialStates = new Map();
    checkboxes.forEach((checkbox) => {
      this.initialStates.set(checkbox, checkbox.checked);
    });
  }

  showForm(container, lastFocusableEl) {
    const form = container.querySelector(this.form);
    const list = container.querySelector(this.listContainer);
    const btnShowForm = container.querySelector(this.showFormBtn);
    list.classList.add('hidden');
    form.classList.add('active');
    form.querySelector('input').focus();
    btnShowForm.classList.add('hidden');
    trapFocus(container, lastFocusableEl);
  }

  hideForm(form, popin) {
    const btnShowForm = form.closest('.popin__playlist--content').querySelector(this.showFormBtn);
    const list = popin.querySelector(this.listContainer);
    list.classList.remove('hidden');
    form.classList.remove('active');
    btnShowForm.classList.remove('hidden');
    form.querySelector('input').value = '';
    trapFocus(popin, btnShowForm);
  }

  addNewPlaylist(container, form, slug, type, thumbnailId) {
    const popin = form.closest('.popin__playlist');
    const input = form.querySelector(this.input);
    const listName = input.value;
    const playlistName = { name: listName };

    if (listName.length <= 0) {
      this.displayErrorMessage(input, 'Saisis au moins 1 caractère');
      return;
    }
    if (listName.length >= 75) {
      this.displayErrorMessage(input, 'Saisis moins de 75 caractères');
      return;
    }

    ajaxJson(`${window.location.origin}/ajax/member_playlists`, false, playlistName)
      .then((response) => {
        const errorMessage = input.parentNode.querySelector('.error-message');
        if (errorMessage) {
          errorMessage.remove();
          form.classList.remove('error');
        }
        this.addToPlaylist(response.id, slug, type, thumbnailId);
        this.hideForm(form, popin);
      })
      .catch((e) => console.log(e.message));
    this._dispatchTrackingClicks('create', type, slug);
    this.closePopin(container);
  }

  onChangeCheckbox(container, popin, list, slug, type, thumbnailId) {
    const btnContainer = popin.querySelector(this.submitContainer);
    const btn = btnContainer.querySelector(this.submitBtn);
    let checkedItems = [];
    let uncheckedItems = [];

    list.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      checkbox.addEventListener('change', () => {
        const isDifferent = Array.from(list.querySelectorAll('input[type="checkbox"]')).some((input) => this.initialStates.get(input) !== input.checked);

        if (isDifferent) {
          checkedItems = [];
          uncheckedItems = [];
          const differentInputs = Array.from(list.querySelectorAll('input[type="checkbox"]')).filter((input) => this.initialStates.get(input) !== input.checked);
          differentInputs.forEach((input) => {
            const playlistId = input.getAttribute('data-id');
            if (input.checked) {
              checkedItems.push(playlistId);
            } else {
              uncheckedItems.push(playlistId);
            }
          });
          btnContainer.classList.add('active');
        } else {
          checkedItems = [];
          uncheckedItems = [];
          btnContainer.classList.remove('active');
        }
      });
    });

    btn.addEventListener('click', () => {
      if (checkedItems.length > 0 || uncheckedItems.length > 0) {
        this.updatePlaylist(slug, type, thumbnailId, checkedItems, uncheckedItems, container);
        checkedItems = [];
        uncheckedItems = [];
        btnContainer.classList.remove('active');
      }
    });
  }

  addToPlaylist(id, slug, type, thumbnailId) {
    const data = {
      id,
      slug,
      type,
      thumbnailId,
    };
    const toastContainer = document.querySelector(this.popinToastContainer);
    ajaxHTML(`${window.location.origin}/ajax/member_playlist/add_content`, 'post', data, false)
      .then((response) => response.text())
      .then((template) => {
        toastContainer.innerHTML = template;
        this.displayToast(toastContainer, true);
      })
      .catch((e) => {
        console.log(e.message);
        this.displayToast(toastContainer, false);
      });
  }

  updatePlaylist(slug, type, thumbnailId, playlistAdd, playlistDelete, container) {
    const data = {
      slug,
      type,
      thumbnailId,
      playlists_add: playlistAdd,
      playlists_delete: playlistDelete,
    };
    const toastContainer = document.querySelector(this.popinToastContainer);
    ajaxHTML(`${window.location.origin}/ajax/member_playlists_update_contents`, 'post', data, false)
      .then((response) => response.text())
      .then((template) => {
        toastContainer.innerHTML = template;
        this.displayToast(toastContainer, true);
      })
      .catch((e) => {
        console.log(e.message);
        this.displayToast(toastContainer, false);
      });
    this._dispatchTrackingClicks('save', data.type, data.slug);
    this.closePopin(container);
  }

  removeFromPlaylist(id, type, slug, thumbnailId, card) {
    const data = {
      type,
      slug,
      thumbnailId,
    };
    const toastContainer = document.querySelector(this.popinToastContainer);
    ajaxHTML(`${window.location.origin}/ajax/member_playlists_content_delete/${id}`, 'post', data, false)
      .then((response) => response.text())
      .then((template) => {
        toastContainer.innerHTML = template;
        this.displayToast(toastContainer, true);
        card.closest('li').remove();
      })
      .catch((e) => {
        console.log(e.message);
        this.displayToast(toastContainer, false);
      });
  }

  displayToast(toast, isSuccess) {
    if (!isSuccess) {
      toast.innerHTML = `
        <div class="popin__toast_playlist error" aria-live="polite">
            <p class="message"></p>
            <a href="${window.location.origin}/mes-listes" title="Voir les listes">
                Voir les listes
            </a>
        </div>`;
    }
    Lazy.init();
    const message = isSuccess ? 'Enregistrement pris en compte' : 'Un problème est survenu lors de l’enregistrement';
    toast.querySelector('.message').textContent = message;
    toast.classList.add('active');
    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      toast.classList.remove('active');
      setTimeout(() => {
        toast.innerHTML = '';
      }, 200);
    }, 5000);
  }

  displayErrorMessage(input, message) {
    const form = input.closest(this.form);
    if (form.classList.contains('error')) {
      input.focus();
      return;
    }
    form.classList.add('error');

    const errorMessage = document.createElement('span');
    errorMessage.classList.add('error-message');
    errorMessage.textContent = message;
    input.classList.add('error');
    input.focus();
    input.parentNode.insertBefore(errorMessage, input.nextSibling);
  }
}

export default ButtonPlaylist;
