import { notifsHandler } from '../../modules/notification/listeners/notifications';
import cleanStorage from './clean-storage';
import { securityChecker } from '../validators/form';
import { quitPageListener } from '../listeners/quit-page-listener';
import {
  forgotPassword, resetPassword, resetPwValidation, forgotPwValidation,
} from '../datas/bottom-panels-data';
import hideSubmitButton from '../listeners/hide-submit-button';

const loadSecurityApp = () => {
  cleanStorage();
  securityChecker();
  notifsHandler();
  quitPageListener();

  if (forgotPassword !== null && forgotPwValidation) {
    hideSubmitButton(forgotPwValidation.id);
  }

  if (resetPassword !== null && resetPwValidation) {
    hideSubmitButton(resetPwValidation.id);
  }
};

export default loadSecurityApp;
