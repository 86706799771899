import { trapFocus } from '../../../../../global/assets/scripts/utils/trapFocus';

/**
 * @param {string} type
 * @private
 */
const _dispatchTrackingImpressionEvents = (type) => {
  document.dispatchEvent(new CustomEvent('Tracking:impression', {
    detail: {
      type: 'popin_incentive',
      data: { type },
    },
  }));
};

class PopinAccompany {
  constructor() {
    this.breakView = window.innerWidth;
    this.popinAccompagny = document.querySelector('.popin-accompany');
    this.cross = this.popinAccompagny.querySelector('.close-cross');
    this.overlay = document.querySelector('.popin-accompany__overlay');
    this.header = document.querySelector('header');
    this.popinAction = document.querySelector('.popup--action');
  }

  init() {
    if (!this.cross || !this.overlay) return;

    this._setEvents();
    trapFocus(this.popinAccompagny);
  }

  _setEvents() {
    document.addEventListener('Popin:Accompany:Freemium', () => {
      this._addPopin();
      _dispatchTrackingImpressionEvents('favorite');
    });

    this.cross.addEventListener('click', () => {
      this._removePopin();
    }, false);

    this.overlay.addEventListener('click', () => {
      this._removePopin(false);
    }, false);
  }

  /**
   * @private
   */
  _addPopin() {
    this.popinAccompagny.classList.add('popin-accompany__open');
    this.header.classList.remove('scrollUp');

    if (this.breakView <= 576) {
      this.overlay.classList.add('popin-accompany__overlay-visible');
      document.querySelector('body').classList.add('body-blocked');
    }

    document.querySelector('.popin-accompany__close').focus();

    setTimeout(() => {
      this._removePopin();
    }, 45000);
  }

  /**
   * @private
   */
  _removePopin() {
    this.popinAccompagny.classList.remove('popin-accompany__open');

    if (this.breakView <= 576) {
      this.overlay.classList.remove('popin-accompany__overlay-visible');
      document.querySelector('body').classList.remove('body-blocked');
    }

    if (this.popinAction) {
      this.popinAction.querySelector('.close-cross--popup').focus();
    }
  }
}

export default PopinAccompany;
