// Hooks for bottom panels.
export const bottomPanel = document.getElementById('js-bottom-panel-fulfilled');
export const validationPanel = document.getElementById('js-bottom-panel-not-fulfilled');
export const validationButton = document.getElementById('my_account_validate');

// Reset pwd page - bottom panel
export const resetPwValidation = document.getElementById('reset_password_validate');
export const resetPassword = document.getElementById('js-hook-reset-password');

// forgot pwd page - bottom panel
export const forgotPwValidation = document.getElementById('forgotten_password_validate');
export const forgotPassword = document.getElementById('js-hook-forgot-password');
