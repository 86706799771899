import { SliderV2 } from '../../../v2/components/organisms/slider/v2/slider';
import Lazy from './Lazy';
import { ajaxHTML } from './utils/ajax';
import { getParameters } from './utils/cookiesParameters';

const SUPPORTED_SCHOOL_LEVELS = ['troisieme', 'terminale'];

class QuizExam {
  /**
   * @param {Object} cookies
   */
  constructor(cookies) {
    this.cookies = cookies;
    this.quizExamContainer = '#quizExam';
  }

  setQuizExam() {
    const { schoolLevel } = getParameters(this.cookies) || '';

    if (!SUPPORTED_SCHOOL_LEVELS.includes(schoolLevel)) {
      return;
    }

    ajaxHTML(`${window.location.origin}/ajax/aggregation/quiz/${schoolLevel}`)
      .then((response) => response.text())
      .then((template) => {
        document.querySelector(this.quizExamContainer).innerHTML += template;
        this.initPlugins();
        QuizExam.dispatchEvents(document.querySelector('#recommendation--v2'));
      })
      .catch((e) => console.log(e.message));
  }

  initPlugins() {
    Lazy.init(`${this.quizExamContainer} .lazy`, `${this.quizExamContainer} .lazy-picture`);
    SliderV2(document.querySelector(this.quizExamContainer));
  }

  static dispatchEvents(container) {
    document.dispatchEvent((new CustomEvent('refreshAddPlaylist', { detail: { container } })));
    document.dispatchEvent((new CustomEvent('refreshCardRedirection', { detail: { container } })));
  }
}

export default QuizExam;
