const queryString = window.location.search;
const searchParams = new URLSearchParams(queryString);
const queryStringWord = 'matiere';
const paginationParam = 'page';

const createNewParams = (dataFilter, params) => {
  const existingParams = params.split('+');
  const thematics = new Set(existingParams);

  if (params === '') {
    return dataFilter;
  }

  if (thematics.has(dataFilter)) {
    thematics.delete(dataFilter);
  } else {
    thematics.add(dataFilter);
  }

  return [...thematics.values()];
};

const openFilterBlock = () => {
  const activeElments = document.querySelector('.wrapper-archive').querySelectorAll('[data-active="true"]') || null;

  if (!activeElments) return;

  for (let index = 0; index < activeElments.length; index++) {
    const parent = activeElments[index].parentNode;
    const style = getComputedStyle(parent).display;

    if (style === 'none') {
      parent.closest('.filters-v2').setAttribute('data-open', 'true');
    }
  }
};

const _onClick = (event) => {
  event.preventDefault();

  const myUrl = new URL(window.location);
  let newUrl = '';
  const item = event.target.parentNode;
  const dataFilter = item.getAttribute('data-filter');

  if (searchParams.has(paginationParam)) {
    searchParams.delete(paginationParam);
  }

  if (!searchParams.has(queryStringWord)) {
    searchParams.set(queryStringWord, dataFilter);
    myUrl.search = searchParams.toString();
    newUrl = `${myUrl.search}`;
  } else {
    const params = searchParams.get(queryStringWord);
    const filterSelected = document.querySelectorAll('.thematic-filter-item button[data-active="false"]').length;
    const newParams = createNewParams(dataFilter, params);

    if (newParams.length !== 0 && filterSelected > 1) {
      searchParams.set(queryStringWord, newParams);
      myUrl.search = searchParams.toString().replaceAll('%2C', '%2B');
      newUrl = `${myUrl.search}`;
    } else {
      searchParams.delete(queryStringWord);
      newUrl = `${myUrl.pathname}`;
    }
  }

  window.location.href = `${newUrl}`;
};

export const archiveFilterBySubject = () => {
  if (!document.querySelector('.archive-content--v2')) return;

  openFilterBlock();

  const filterItems = document.querySelectorAll('.thematic-filter-item');

  filterItems.forEach((element) => {
    element.addEventListener('click', _onClick);
  });
};
