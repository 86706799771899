import { getCookies, createCookie, removeCookie } from '@global-js/utils/cookies';
import { trapFocus } from '@global-js/utils/trapFocus';

class PopinGamification {
  constructor() {
    this.popinGamification = document.querySelector('.popin-gamification.point');
    this.popinGamificationLevel = document.querySelector('.popin-gamification.level');
    this.popinLevelCross = this.popinGamificationLevel.querySelector('.popin-gamification.level .popin-gamification__container--close-cross');
    this.cross = this.popinGamification.querySelector('.popin-gamification__container--close-cross');
    this.checkbox = this.popinGamification.querySelector('.popin-gamification__input');
    this.points = this.popinGamification.querySelector('.popin-gamification__container--content--points');
    this.contents = this.popinGamification.querySelectorAll('.popin-gamification__container--content--action');
    this.cookies = getCookies();
    this.cookieName = 'lumni_gamification_popin';
  }

  init(points, type) {
    this._setEvents(points, type);
  }

  _setEvents(points, type) {
    if (!['false', 'undefined'].includes(this.cookies.lumni_gamification_popin)) {
      this._openPopin(points, type);

      this.cross.addEventListener('click', () => {
        this._closePopin(this.popinGamification, 'lastFocus');
      });

      window.addEventListener('keydown', ({ code }) => {
        if (code === 'Escape') {
          this._closePopin(this.popinGamification, 'lastFocus');
        }
      });

      this.checkbox.addEventListener('change', ({ target }) => {
        if (target.checked) {
          createCookie(this.cookieName, 'false');
        } else {
          removeCookie(this.cookieName);
        }
      });
    }

    if (['watchLater', 'favorite'].includes(type)) {
      this._saveItemAnimation(points, type);
    }
  }

  _dispatchEvent = (eventType, data) => {
    document.dispatchEvent(new CustomEvent('Tracking:impression', {
      detail: {
        type: eventType,
        data,
      },
    }));
  };

  _openPopin(points, type) {
    const activeFocus = document.activeElement;

    if (!this.popinGamification) return;

    activeFocus?.classList.add('lastFocus');
    this.popinGamification.classList.remove('animationOut');
    this._updatePoints(points);
    this._updateContent(type);
    this.popinGamification.setAttribute('data-active', 'true');
    this.cross.focus();

    trapFocus(this.popinGamification);

    this._dispatchEvent('popin_gamification', { type });
  }

  _openPopinLevel(eventType, detail) {
    const activeFocus = document.activeElement;

    if (!this.popinGamificationLevel) return;

    this.popinGamificationLevel.dataset.level = detail.level;
    activeFocus?.classList.add('lastFocusLevel');
    this.popinGamificationLevel.classList.remove('animationOut');
    this.popinGamificationLevel.setAttribute('data-active', 'true');
    this.popinLevelCross.focus();

    trapFocus(this.popinGamificationLevel);

    this.popinLevelCross.addEventListener('click', () => {
      this._closePopin(this.popinGamificationLevel, 'lastFocusLevel');
    });

    this._dispatchEvent(eventType, { level: detail.level, type: detail.type });
  }

  _openPopinBadge(eventType, position, badge, detail, badgeTitle) {
    const activeFocus = document.activeElement;
    const popinGamificationBadge = document.querySelector(`.popin-gamification.badge.${position}`);
    const popinBadgeCross = popinGamificationBadge.querySelector('.popin-gamification__container--close-cross');
    const formatedBadgeTitle = badgeTitle.replace(/ /g, '_');

    if (!popinGamificationBadge) return;

    activeFocus?.classList.add(`lastFocus${position}`);

    popinGamificationBadge.classList.remove('animationOut');
    popinGamificationBadge.setAttribute('data-active', 'true');
    popinBadgeCross.focus();

    trapFocus(popinGamificationBadge);

    popinBadgeCross.addEventListener('click', () => {
      this._closePopin(popinGamificationBadge, `lastFocus${position}`);
    });

    this._dispatchEvent(eventType, {
      title: formatedBadgeTitle,
      type: badge.type,
      level: badge.title,
    });
  }

  _closePopin(popinType, className) {
    const popin = popinType;
    const lastFocus = document.querySelector(`.${className}`);
    popin.classList.add('animationOut');
    setTimeout(() => {
      popin.setAttribute('data-active', 'false');
    }, 1500);
    lastFocus?.focus();
    lastFocus?.classList.remove(className);
  }

  _updatePoints(points) {
    this.points.innerHTML = `${ points } Lumniz `;
  }

  _updateContent(type) {
    this.contents.forEach((content) => {
      content.classList.toggle('active', content.classList.contains(type));
    });
  }

  _saveItemAnimation(points, type) {
    const saveItemType = type === 'favorite' ? '.btn-save-item__gamification.favorite' : '.btn-save-item__gamification.watchLater';
    const saveItemButtonActive = document.querySelector('.gamificationActive');
    const saveItemAnimation = saveItemButtonActive.querySelector(saveItemType);
    const saveItemAnimationPoints = saveItemAnimation.querySelector('.btn-save-item__gamification--points');
    saveItemAnimationPoints.innerHTML = `+${ points }`;
    saveItemAnimation.setAttribute('data-active', 'true');
    setTimeout(() => {
      saveItemAnimation.setAttribute('data-active', 'false');
      saveItemButtonActive.classList.remove('gamificationActive');
    }, 3500);
  }
}

export default PopinGamification;
