/**
 * @param {string} action
 * @param {string} type
 * @private
 */
const _dispatchEvent = (action, type) => {
  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: {
      type: 'popin_incentive',
      data: { action, type },
    },
  }));
};

/**
 * @param {HTMLElement} currentTarget
 * @private
 */
const _onClick = ({ currentTarget }) => {
  const popinOpen = document.querySelector('.popin-accompany__open');

  const { type } = popinOpen.dataset;
  const { action } = currentTarget.dataset;

  _dispatchEvent(action, type);
};

export const Popup = () => {
  const popupLinks = document.querySelectorAll('.popin__link-account');

  popupLinks.forEach((popupLink) => {
    popupLink.addEventListener('click', _onClick, false);
  });
};
