const getTabs = () => document.querySelectorAll('[role="tab"]');

const KEYS = {
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  enter: 13,
};

const deactivateTabs = () => {
  Array.from(getTabs()).forEach((tab) => {
    tab.setAttribute('tabindex', '-1');
    tab.setAttribute('aria-selected', 'false');
  });
};

const activateTab = (tab) => {
  tab.removeAttribute('tabindex');
  tab.setAttribute('aria-selected', 'true');
};

export const switchTabOnArrowPress = (event, tabs = getTabs()) => {
  const pressed = event.keyCode;
  const index = Array.from(tabs).indexOf(document.activeElement);

  if (index > -1) {
    const nextElement = tabs[index + 1] || tabs[0];
    const prevElement = tabs[index - 1] || tabs[tabs.length - 1];

    if (pressed === KEYS.right) {
      nextElement.focus();
    }

    if (pressed === KEYS.left) {
      prevElement.focus();
    }
  }
};

const keyDownEventListener = (event) => {
  const key = event.keyCode;
  switchTabOnArrowPress(event, getTabs());
  if (KEYS.enter === key) {
    deactivateTabs(getTabs());
    activateTab(event.target);
  }
};

const clickEventListener = (event) => {
  const tab = event.target;
  deactivateTabs();
  activateTab(tab);
};

export const userA11y = () => {
  Array.from(getTabs()).forEach((tab) => {
    tab.addEventListener('keydown', keyDownEventListener);
    tab.addEventListener('click', clickEventListener);
  });
};
