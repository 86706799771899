import { openPopup } from '@global/organisms/popup/popup';
import { isValidEmail } from '@global-js/utils/validator';
import NewsletterApi from '@global/organisms/newsletter/newsletterApi';

const EVENT_SUCCESS = 'newsletter-subscription-success';

class PopupNewsletter {
  constructor() {
    this.inputEmail = document.querySelector('#email-newsletter');
    this.errorEmail = document.querySelector('.text-error-input');
    this.text = document.querySelector('.newsletter-text');
    this.btnSubmit = document.querySelector('#newsletter-submit');
    this.btnClose = document.querySelector('.close-popup');
    this.popup = null;
  }

  init() {
    if (!this.inputEmail) {
      return;
    }

    this.popup = this.inputEmail.closest('.popup-container');
    openPopup(this.popup);

    ['change', 'keypress'].forEach((eventName) => {
      window.addEventListener(eventName, () => this.validateEmail(), false);
    });

    this.btnSubmit.addEventListener('click', () => this.sendSubscription(), false);
    this.popup.addEventListener(EVENT_SUCCESS, () => this.onSuccessResponse(), false);
  }

  validateEmail() {
    if (isValidEmail(this.inputEmail.value)) {
      this.errorEmail.style.display = 'none';
      this.btnSubmit.removeAttribute('disabled');
      this.btnSubmit.classList.add('newsletter-submit-btn--active');
    } else {
      this.errorEmail.style.display = 'inline-block';
      this.btnSubmit.setAttribute('disabled', 'disabled');
      this.btnSubmit.classList.remove('newsletter-submit-btn--active');
    }
  }

  sendSubscription() {
    const newsletterApi = new NewsletterApi(this.popup);
    newsletterApi.send(this.inputEmail.value, true, false, 'popin-lumni');
  }

  onSuccessResponse() {
    this.text.innerHTML = 'Merci de votre inscription';
    this.inputEmail.remove();
    this.btnSubmit.remove();
    this.btnClose.classList.remove('is-hidden');
  }
}

export default PopupNewsletter;
