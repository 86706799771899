import { enableTabs } from '@global-js/modules/tabs/tabs';
import { ajaxHTML } from '@global-js/utils/ajax';
import Lazy from '@global-js/Lazy';
import { SliderV2 } from '../slider/v2/slider';
import { sliderSegmentObserver } from '../slider/v2/trackingSliderImpression';

/**
 * @param {string} establishment
 * @return {boolean}
 * @private
 */
const _hasNothing = (establishment) => document.querySelector(`#hppSegments-${establishment}`).innerHTML.trim().length === 0;

/**
 * @param {string} establishment
 * @private
 */
const _initPlugins = (establishment) => {
  Lazy.init(`#hppSegments-${establishment} .lazy`, `#hppSegments-${establishment} .lazy-picture`);
  SliderV2(document.querySelector(`#hppSegments-${establishment}`));
};

/**
 * @param {HTMLElement} container
 * @private
 */
const _dispatchEvents = (container) => {
  document.dispatchEvent((new CustomEvent('refreshAddPlaylist', { detail: { container } })));
  document.dispatchEvent((new CustomEvent('refreshCardRedirection', { detail: { container } })));
  document.dispatchEvent((new CustomEvent('blockedVideosInList', { detail: { container } })));
  document.dispatchEvent((new CustomEvent('noResponsiveGameCardsInList', { detail: { container } })));
};

/**
 * @param {string} establishment
 * @private
 */
const _getHppSegments = (establishment) => {
  ajaxHTML(`${window.location.origin}/ajax/hpp/segment/${establishment}`)
    .then((response) => response.text())
    .then((template) => {
      const element = document.querySelector(`#hppSegments-${establishment}`);
      element.innerHTML = template;
      _initPlugins(establishment);
      _dispatchEvents(element);
      sliderSegmentObserver(document.querySelector(`#hppSegments-${establishment}`));
    })
    .catch((e) => console.log(e.message));
};

/**
 * @param {HTMLElement} target
 * @private
 */
const _onClick = ({ currentTarget }) => {
  const { establishment } = currentTarget.dataset;

  if (_hasNothing(establishment)) {
    _getHppSegments(establishment);
  }
};

export const establishmentTabs = () => {
  if (!document.querySelector('.hpp-establishment')) {
    return;
  }

  enableTabs('hpp-establishment', false);

  _getHppSegments('primaire');

  const tabs = document.querySelectorAll('.hpp-establishment .tab-links');
  tabs.forEach((item) => item.addEventListener('click', _onClick, false));
};
