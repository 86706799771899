const DATABASE_LOGGED = 'lumni';
const DATABASE_LOGOUT = 'lumni_logout';

class IndexDB {
  /**
   * @param {boolean} isUserConnected
   * @param {string} collection
   */
  constructor(isUserConnected, collection = 'favorites') {
    this.databaseName = isUserConnected ? DATABASE_LOGGED : DATABASE_LOGOUT;
    this.collectionName = collection;
    this.version = 3;
    this.db = null;

    this.init();
  }

  init() {
    // Create/upgrade a database
    const openRequest = indexedDB.open(this.databaseName, this.version);

    openRequest.onupgradeneeded = (e) => {
      this.db = e.target.result;

      const collectionNames = this.databaseName === DATABASE_LOGOUT ? ['favorites'] : ['favorites', 'viewed'];

      collectionNames.forEach((collectionName) => {
        if (!this.db.objectStoreNames.contains(collectionName)) {
          // Create PrimaryKey
          const collection = this.db.createObjectStore(collectionName, { keyPath: 'name' });
          // Create Index with constraint => the name must be unique
          collection.createIndex('name', 'name', { unique: true });
        }
      });
    };

    // When the database is ready we fire an event to populate it
    openRequest.onsuccess = (e) => {
      this.db = e.target.result;

      document.dispatchEvent(
        new CustomEvent(`IndexDB:ready:${this.collectionName}`, {
          detail: { collection: this.collectionName },
        }),
      );
    };
  }

  clear() {
    this._getStore().clear();
  }

  /**
   * @param {object} item
   */
  addItem(item) {
    this._getStore().add(item);
  }

  /**
   * @param {string} key
   */
  deleteItem(key) {
    this._getStore().delete(key);
  }

  getAll() {
    return this._getStore('readonly').getAllKeys();
  }

  /**
   * @param perm
   * @return {*}
   */
  _getStore(perm = 'readwrite') {
    const tx = this.db.transaction(this.collectionName, perm);

    return tx.objectStore(this.collectionName);
  }
}

export default IndexDB;
