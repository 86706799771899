import { isUserAbroad } from '../../../../../global/assets/scripts/modules/security/session/user';
import { accessibility } from '../../../../../global/components/organisms/popup/accessibility';

/**
 * @param {HTMLElement} popupContainer
 * @private
 */
const _displayPopup = (popupContainer) => {
  popupContainer.querySelector('.popup-container').setAttribute('data-active', 'true');
  accessibility(popupContainer);
};

export const PopupLocalization = () => {
  const popupContainer = document.querySelector('.containerPopupLocalization');

  if (isUserAbroad()) {
    _displayPopup(popupContainer);
    return;
  }

  // Handle case when the call to get the info of user location takes too much time
  document.addEventListener('userAbroad', ({ detail }) => {
    if (detail) _displayPopup(popupContainer);
  });
};
