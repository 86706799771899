/**
 * @return {boolean}
 */
export const isBackNavigation = () => {
  try {
    /** @type {PerformanceEntryList} */
    const entries = performance.getEntriesByType('navigation');
    /** @type {PerformanceNavigationTiming} */
    const lastEntry = entries[0];

    return lastEntry.type === 'back_forward';
  } catch (e) {
    return false;
  }
};
