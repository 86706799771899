class NavBottom {
  constructor() {
    this.navBottomButton = document.querySelector('.bottom-bar--button');
    this.navBottomButtonImg = document.querySelector('.bottom-bar--arrow');
    this.topBar = document.querySelector('.top-bar');
    this.grid = document.querySelector('.grid');
    this.navBottom = document.querySelector('.navbar-mobile');
  }

  init() {
    if (this.navBottomButton) {
      this.toggleNavBottomLevelMenu();
      this.listenScroll();
    }
  }

  toggleNavBottomLevelMenu() {
    this.navBottomButton.addEventListener('click', () => {
      this.toggleTopBar();
      this.toggleBodyBlocked();
      this.handleThematicLevel();
      this.toggleHeaderScrollingTop();
    }, false);
  }

  toggleTopBar() {
    this.topBar.classList.toggle('top-bar--open');
    this.navBottomButtonImg.classList.toggle('top-bar--arrow-rotate');
  }

  toggleBodyBlocked() {
    const isOpen = this.topBar.classList.contains('top-bar--open');
    const isPanelOpen = document.querySelector('.thematic-level--open');
    document.querySelector('body').classList.toggle('body-blocked', isOpen || isPanelOpen);
  }

  handleThematicLevel() {
    const panelThematic = document.querySelector('.thematic-level');

    if (panelThematic?.classList.contains('thematic-level--open')) {
      const btnPanelThematic = document.querySelector('.nav-bottom-thematic').closest('li');
      panelThematic.classList.remove('thematic-level--open');
      btnPanelThematic.classList.remove('active');
      this.toggleBodyBlocked();
      this.handlePreviousActiv();
    }
  }

  handlePreviousActiv() {
    const navItems = document.querySelectorAll('.nav-bottom ul li');
    const route = window.LUMNI_CONTEXT?.route;
    const contentType = window.LUMNI_CONTEXT?.routeParameters?.contentType;
    let index = 1;

    if (['game', 'quiz'].includes(route) || ['tous-les-jeux', 'tous-les-quiz'].includes(contentType)) {
      index = 2;
    } else if (['homepage', 'school', 'middle-school', 'high-school'].includes(route)) {
      index = 0;
    }

    navItems[index].classList.add('active');
  }

  toggleHeaderScrollingTop() {
    const isOpen = this.topBar.classList.contains('top-bar--open');
    if (isOpen) {
      document.querySelector('.container--header-v2').classList.add('scrolling-top');
    }
  }

  listenScroll() {
    let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;

    window.addEventListener('scroll', () => {
      const scrollTopPosition = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTopPosition > lastScrollTop) {
        this.reduceNavBottomHeight();
      } else if (scrollTopPosition < lastScrollTop) {
        this.restoreNavBottomHeight();
      }
      lastScrollTop = scrollTopPosition <= 0 ? 0 : scrollTopPosition;
    });
  }

  reduceNavBottomHeight() {
    this.navBottom.dataset.reduced = 'true';
  }

  restoreNavBottomHeight() {
    this.navBottom.dataset.reduced = 'false';
  }
}

export default NavBottom;
