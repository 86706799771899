import { isConnected } from '../../../../../global/assets/scripts/modules/security/session/user';
import { ajaxJson } from '../../../../../global/assets/scripts/utils/ajax';
import { createCookie, getCookies } from '../../../../../global/assets/scripts/utils/cookies';
import IndexDB from './connector/IndexDB';

/**
 * @param {object} item
 * @return {`${string}_${string}_${string}`}
 * @private
 */
const _buildName = (item) => `${item.type}_${item.slug}`;

class ViewedItems {
  constructor() {
    this.db = null;
    this.baseUrl = window.location.origin;
    this.path = '/ajax/tous-mes-contenus-vus';
    this.cookieName = 'lumni_viewed_items_sync';
    this.unitContents = ['video', 'article', 'game', 'quiz'];
  }

  /**
   * @param {boolean} isUserAuthenticated
   */
  init() {
    // If user is not connected we stop here
    if (!isConnected()) return;

    // Listens events to initDB DB
    document.addEventListener('IndexDB:ready:viewed', this._getViewedItems.bind(this), false);

    // Listens events to Add viewed item to the DB
    document.addEventListener('Viewed:API:add', this._addItem.bind(this), false);

    // Listens events to Remove viewed item to the DB
    document.addEventListener('Viewed:API:remove', this._removeItem.bind(this), false);

    // Listen to new cards added on the page dynamically to update their status
    document.addEventListener('refreshFavoriteWatchLater', this._updateNewCardsOnPage.bind(this));

    // Create uniq instance of IndexDB
    this.db = new IndexDB(true, 'viewed');
  }

  /**
   * @param {object} detail
   * @private
   */
  _removeItem({ detail }) {
    this.db.deleteItem(_buildName(detail));
  }

  /**
   * @param {object} detail
   * @private
   */
  _addItem({ detail }) {
    this.db.addItem({ name: _buildName(detail) });
  }

  _getViewedItems() {
    // If the user does not have the cookie 'lumni_viewed_items_sync'
    // we call the api to get the saved items, and we store them in IndexedDB
    // This will prevent from making http request in each pages
    if (Object.keys(getCookies([this.cookieName])).length === 0) {
      this.db.clear();

      ajaxJson(`${this.baseUrl}${this.path}`).then((data) => {
        this._saveItemsInDB(data);
        // Once everything is store we update the cards
        this._updateCards();
      });

      return;
    }

    // If IndexedDB is already sync we can update directly the cards.
    this._updateCards();
  }

  /**
   * @param {array} items
   * @private
   */
  _saveItemsInDB(items) {
    // Store items in IndexedDB
    items.forEach((item) => {
      this.db.addItem({
        name: _buildName(item),
      });
    });

    // Create a cookie for 1 day
    createCookie(this.cookieName, 'true', 1);
  }

  /**
   * @param {HTMLElement} container
   * @private
   */
  _updateCards(container = document) {
    const cards = container.querySelectorAll('.card');
    const cardsGamification = container.querySelectorAll('.card-lumniz');
    const storage = this.db.getAll();

    // Storage will return an "onsuccess" event with the data in "result"
    storage.onsuccess = (e) => {
      const viewedItemsKeys = e.target.result;

      this._typeOfCards(cards, viewedItemsKeys);
      this._typeOfCards(cardsGamification, viewedItemsKeys);
    };
  }

  /**
   * @param {HTMLElement} cardBlocs
   * @private
   */
  _typeOfCards(cardBlocs, viewedItemsEl) {
    cardBlocs.forEach((cardBloc) => {
      const { type, slug } = cardBloc.dataset;
      const prefix = `${type}_${slug}`;

      if (viewedItemsEl.includes(`${prefix}`) && this.unitContents.includes(type)) {
        cardBloc.classList.add('viewed');
      }
    });
  }

  /**
   * @param {object} detail
   * @private
   */
  _updateNewCardsOnPage({ detail }) {
    this._updateCards(detail.container);
  }
}

export default ViewedItems;
