const SUGGESTION = 'suggestion';
const ZONE_CONFIG = {
  'tu-aimeras-aussi': SUGGESTION,
};
const SLIDER = 'slider';
const HIGHLIGHT = 'mea';

/**
 * @private
 */
const _dispatchImpressionEvent = (zone, feature) => {
  document.dispatchEvent(new CustomEvent('Tracking:impression', {
    detail: {
      type: 'slider',
      data: { zone, feature },
    },
  }));
};

/**
 * @private
 */
const _highlightHandleIntersection = (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      _dispatchImpressionEvent(HIGHLIGHT, 'bloc_mea');
      observer.unobserve(entry.target);
    }
  });
};

/**
 * @private
 */
const _listHandleIntersection = (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const blocName = entry.target.querySelector('h2').innerHTML.trim();
      _dispatchImpressionEvent(SUGGESTION, `bloc_${blocName}`);
      observer.unobserve(entry.target);
    }
  });
};

/**
 * @private
 */
const _sliderSegmentHandleIntersection = (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const blocName = entry.target.dataset.csOverrideId;
      const zone = ZONE_CONFIG[blocName] ? ZONE_CONFIG[blocName] : SLIDER;
      _dispatchImpressionEvent(zone, `bloc_${blocName}`);
      observer.unobserve(entry.target);
    }
  });
};

const highlightObserver = () => {
  const highlightSliderObserver = new IntersectionObserver(
    _highlightHandleIntersection,
    { threshold: [1] },
  );
  highlightSliderObserver.observe(document.querySelector('.slider--mea, .slider--hp'));
};

const listObserver = () => {
  const listSegmentObserver = new IntersectionObserver(_listHandleIntersection, { threshold: [1] });
  listSegmentObserver.observe(document.querySelector('.segment .list'));
};

export const sliderSegmentObserver = (container = document) => {
  const sliderObserver = new IntersectionObserver(
    _sliderSegmentHandleIntersection,
    { threshold: [1] },
  );
  const segments = container.querySelectorAll('.segment .slider');
  segments.forEach((segment) => sliderObserver.observe(segment));
};

export const trackingSliderImpression = () => {
  if (document.querySelector('.slider--mea, .slider--hp')) {
    highlightObserver();
  }

  if (document.querySelector('.segment .slider')) {
    sliderSegmentObserver();
  }

  if (document.querySelector('.segment .list')) {
    listObserver();
  }
};
