import { isiOS } from '@global-js/utils/browser';

const createParentIos = (container, quiz, toolbox) => {
  const newDiv = document.createElement('div');
  newDiv.classList.add('container-ios');
  newDiv.appendChild(quiz);
  container.appendChild(newDiv);
  container.insertBefore(newDiv, toolbox);
};

export const quizIos = () => {
  if (window.innerWidth <= 1199 && isiOS()) {
    const container = document.querySelector('.grid-area--player');
    const quiz = document.querySelector('#quiz');
    const toolbox = document.querySelector('.toolbox--v2');
    createParentIos(container, quiz, toolbox);
  }
};
