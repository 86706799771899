import dataThematics from '../../../../../v2/data/dataThematics.json';

let state = {};

/**
 * @param {Object} stateTheme
 */
export const setSearchContext = (stateTheme) => {
  state = stateTheme;

  const inputEstablishment = document.querySelector('#establishment');
  const inputSchoolLevel = document.querySelector('#schoolLevel');
  const inputThematic = document.querySelector('#thematics');
  const searchUserButtons = document.querySelectorAll('.my-content-search');
  const searchVideoAccessibleBtn = document.querySelector('.popup--localization__btn');

  const establishment = state?.cookies?.establishment
    || state?.cookies?.lumni_user_establishment
    || null;

  const schoolLevel = state?.cookies?.schoolLevel || state?.cookies?.lumni_user_schoolLevel || null;
  const thematic = state?.cookies?.thematic || null;

  if (inputEstablishment && establishment) inputEstablishment.value = state.cookies.establishment;
  if (inputSchoolLevel && schoolLevel) inputSchoolLevel.value = state.cookies.schoolLevel;
  if (inputThematic && thematic) inputThematic.value = state.cookies.thematic;

  if (searchVideoAccessibleBtn || searchUserButtons) {
    const searchParams = new URLSearchParams();

    if (establishment) searchParams.append('establishment', establishment);
    if (schoolLevel) searchParams.append('schoolLevel', schoolLevel);
    if (thematic) searchParams.append('thematics', dataThematics[thematic] || '');

    if (searchUserButtons) {
      searchUserButtons.forEach((element) => {
        element.href = `${window.location.origin}/recherche?${searchParams.toString()}`;
      });
    }

    if (searchVideoAccessibleBtn) {
      searchParams.append('collections', 'video');
      searchParams.append('videotype', 'not_geoblocked');
      searchVideoAccessibleBtn.href = `${window.location.origin}/recherche?${searchParams.toString()}`;
    }
  }
};
