/**
 * @param {HTMLElement} currentTarget
 * @private
 */
const _onClick = ({ currentTarget }) => {
  const { social, zone } = currentTarget.dataset;

  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: {
      type: 'share',
      data: { social, zone },
    },
  }));
};

export const Social = () => {
  const socialLinks = document.querySelectorAll('.social__link');

  socialLinks.forEach((link) => {
    link.addEventListener('click', _onClick, false);
  });
};
