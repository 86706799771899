import { ajaxHTML } from '@global-js/utils/ajax';
import { SliderV2 } from '../../../../../v2/components/organisms/slider/v2/slider';
import * as user from '../security/session/user';

const ROUTE_SCHOOL_LEVELS = ['school', 'middle-school', 'high-school'];

const USER_SEGMENTS = [
  {
    path: '/ajax/mon-historique/niveau',
    position: 0,
    segmentSelector: '.segment-user-historic',
  },
];

/**
 * @param {string} selector
 */
const initPlugins = (selector) => {
  const container = document.querySelector(selector);

  if (!container) return;

  SliderV2(container);

  document.dispatchEvent((new CustomEvent('refreshAddPlaylist', {
    detail: {
      container,
      lazySelector: selector,
    },
  })));
};

/**
 * @param {string} template
 * @param {number} position
 */
const displayTemplate = (template, position) => {
  const wrapper = document.createElement('div');
  wrapper.classList.add(window.ENABLE_NEW_HPN_MENU ? 'wrapper--large' : 'wrapper--with-menu');
  wrapper.innerHTML = template;
  wrapper.classList.add('interlayer--historic');

  if (wrapper.childElementCount === 0) {
    return;
  }

  const selectWrapper = window.ENABLE_NEW_HPN_MENU ? '.wrapper--large' : '.wrapper--with-menu';
  const previousSegment = document.querySelector(`${selectWrapper}[data-position="${position}"]`);

  if (previousSegment) {
    previousSegment.insertAdjacentElement('beforebegin', wrapper);
  } else {
    document.querySelector('.container--segments').insertAdjacentElement('beforeend', wrapper);
  }
};

/**
 * @param {Object} detail
 */
const getItems = ({ detail }) => {
  if (!detail.id || !detail.schoolLevel) {
    return;
  }

  if (detail.schoolLevel === 'etudiant' || detail.schoolLevel === null) {
    return;
  }

  USER_SEGMENTS.forEach((segment) => {
    ajaxHTML(`${window.location.origin}${segment.path}/${detail.schoolLevel}`)
      .then((response) => response.text())
      .then((template) => {
        displayTemplate(template, segment.position);
        initPlugins(segment.segmentSelector);
      })
      .catch((e) => console.log(e.message));
  });
};

/**
 * @param {Object} context
 * @return {boolean}
 */
const canGetUserItems = (context) => {
  const { route } = context;

  return ROUTE_SCHOOL_LEVELS.includes(route);
};

/**
 * @param {Object} cookies
 */
const userHasSameSchoolLevel = (cookies) => user.isConnected()
  && user.hasId()
  && user.hasSchoolLevel()
  && user.getSchoolLevel() === cookies.schoolLevel;

/**
 * @param {Object} cookies
 */
export const getHistoricContents = (cookies) => {
  const context = window.LUMNI_CONTEXT;

  if (!context) {
    return;
  }

  if (canGetUserItems(context)) {
    // If User is already Connected we get directly items
    if (userHasSameSchoolLevel(cookies)) {
      getItems({
        detail: {
          id: user.getId(),
          schoolLevel: user.getSchoolLevel(),
        },
      });

      return;
    }

    // Else we wait until the event 'user:logged' is fired by the Security Component
    document.addEventListener('user:logged', getItems, false);
  }
};
