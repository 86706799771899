class AutoCompletionAccessibility {
  constructor(choices) {
    this.container = null;
    this.choices = choices;
    this.alert = document.querySelector('#alert');
    this.completionInput = document.querySelector('#completion') || document.querySelector('#searchCompletion');
  }

  init() {
    if (document.querySelector('#searchForm')) {
      this.container = document.getElementById('searchForm');
    } else {
      this.container = document.getElementById('search-form');
    }

    if (this.container === null) {
      return;
    }

    this.container.addEventListener('keydown', (e) => {
      const firstChoice = this.choices.firstChild;
      const lastChoice = this.choices.lastChild;
      const input = this.container.querySelector('input');
      const selectedElement = this.choices.querySelector('.selected-accessibility');

      if (e.keyCode === 40) {
        this.arrowChoice(e, selectedElement, firstChoice, lastChoice, true);
      }

      if (e.keyCode === 38) {
        this.arrowChoice(e, selectedElement, lastChoice, firstChoice, false);
      }

      if (e.keyCode === 13 && selectedElement !== null) {
        input.value = selectedElement.innerHTML;
        this.container.submit();
        input.focus();
      }

      if (e.keyCode === 27) {
        this.completionInput.classList.add('hidden');
        this.completionInput.classList.add('hide');
        this.completionInput.classList.remove('open');
      }
    });
  }

  arrowChoice(e, selectedElement, choice1, choice2, upDown) {
    e.preventDefault();
    e.stopPropagation();
    if (!selectedElement) {
      choice1.classList.add('selected-accessibility');
      this.alert.textContent = choice1.innerHTML;
    } else if (choice2.classList.contains('selected-accessibility')) {
      choice1.classList.add('selected-accessibility');
      choice2.classList.remove('selected-accessibility');
      this.alert.textContent = choice1.innerHTML;
    } else {
      const choice = upDown
        ? selectedElement.nextElementSibling
        : selectedElement.previousElementSibling;
      selectedElement.classList.remove('selected-accessibility');
      choice.classList.add('selected-accessibility');
      this.alert.textContent = choice.innerHTML;
    }
  }
}

export default AutoCompletionAccessibility;
