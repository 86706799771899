import { ajax, buildUrl } from '@global-js/modules/theme/utils';

let state = {};
const baseUrl = '/ajax/breadcrumb-v2/';

/**
 * @param {Object} stateTheme
 */
export const setBreadcrumb = (stateTheme) => {
  state = stateTheme;

  const breadcrumb = document.querySelector('.container--breadcrumb-v2');

  if (!breadcrumb) {
    return;
  }

  const { schoolLevel, thematic } = window.LUMNI_CONTEXT.qualification;

  // If the school level and thematics of the item are the same as the cookie we stop here.
  if (state.cookies.schoolLevel === schoolLevel
    && state.cookies.thematic === thematic) {
    return;
  }

  // Else we make call ajax to get the breadcrumb for the correct school level / thematic
  ajax(`${baseUrl}${buildUrl(state, window.LUMNI_CONTEXT.item.type || null)}`, (template) => {
    breadcrumb.innerHTML = template;
    document.querySelector('#breadcrumbTitle').textContent = window.LUMNI_CONTEXT.item.title;
  });
};
