import PlayerJs from '@global/molecules/player/player';

/**
 * Components that need the user to be authenticated can be initialized here
 * The Security.js initialize the AppProtected Component with two parameters:
 *  - isComponentProtected:
 *    • default value: false
 *    • description: The component has to be used by an authenticated user
 *  - isUserAuthenticated:
 *    • default value: false
 *    • description: The user is authenticated via the Security.js
 *
 * @param {boolean} isComponentProtected
 * @param {boolean} isUserAuthenticated
 */
export const AppProtected = (isComponentProtected = false, isUserAuthenticated = false) => {
  new PlayerJs(isComponentProtected, isUserAuthenticated).init();
};
