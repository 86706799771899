import {
  CONFIG,
  getGap,
  hideNavigation,
  isSliderAtTheStart,
  isSliderAtTheEnd,
} from './utils';

const LEFT_DIRECTION = 'left';
const SCROLL_TRANSITION = 'smooth';
const TIMEOUT_CHECK = 700;

/**
 * Handle specific case when the slider is not entirely visible vertically.
 * @param slider
 * @param wrapper
 * @private
 */
const _handleNavigationVisibility = (slider, wrapper) => {
  setTimeout(() => {
    hideNavigation(slider, CONFIG.prev, isSliderAtTheStart(wrapper));
    hideNavigation(slider, CONFIG.next, isSliderAtTheEnd(wrapper));
  }, TIMEOUT_CHECK);
};

/**
 * @param {HTMLElement} slider
 * @param {string} direction
 * @private
 */
const _scrollSlider = (slider, direction) => {
  const wrapper = slider.querySelector(CONFIG.wrapper);
  const slideSize = slider.querySelector(CONFIG.slide).offsetWidth;

  // === number of possible slides visible
  const nbCardsToScroll = Math.max(1, Math.floor(slider.offsetWidth / slideSize) - 1);

  // Number of pixels to scroll
  const scrollBy = ((slideSize + getGap(wrapper)) * nbCardsToScroll)
    * (direction === LEFT_DIRECTION ? -1 : 1);

  wrapper.scrollTo({
    left: wrapper.scrollLeft + scrollBy,
    behavior: SCROLL_TRANSITION,
  });

  _handleNavigationVisibility(slider, wrapper);
};

/**
 * @param {HTMLElement} slider
 */
export const enableSliderControlsArrows = (slider) => {
  const prev = slider.querySelector(CONFIG.prev);
  const next = slider.querySelector(CONFIG.next);

  prev.addEventListener('click', () => _scrollSlider(slider, 'left'), false);
  next.addEventListener('click', () => _scrollSlider(slider, 'right'), false);
};
