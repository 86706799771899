import { ajaxJson } from '@global-js/utils/ajax';

const BASE_URL = window.location.origin;
const PATH = '/ajax/mon-historique';

export const record = (data) => {
  ajaxJson(`${BASE_URL}${PATH}`, false, data)
    .then((result) => {
      if (!result.success) {
        console.error(`Error when user visited: Type => ${data.type}, Slug => ${data.slug}`);
      }

      if (result.action && Object.keys(result.action).length > 0) {
        document.dispatchEvent(new CustomEvent('Gamification', { detail: result.action }));
      }
    })
    .catch((e) => console.log(e.message));
};
