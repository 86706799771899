import { getCookies } from '@global-js/utils/cookies';

const MAPPING_TABS = {
  watchLater: 'a_voir_plus_tard',
  favorite: 'favoris',
  resume: 'reprendre',
  replay: 'revoir',
  'personal-data': 'mon_profil',
  password: 'mon_profil',
};

/**
 * @param {string} tab
 * @private
 */
const _dispatch = (tab) => {
  document.dispatchEvent(new CustomEvent('Tracking:view', {
    detail: { data: { tab } },
  }));
};

/**
 * @param {String} type
 * @param {String} id
 * @param {String} tabName
 */
const trackingUser = (type, id, tabName) => {
  _dispatch(type === 'profile' ? tabName : type);
};

/**
 * @param {Element} target
 * @param {string} activeClass
 * @private
 */
const activeTabLinkAndContent = (target, activeClass) => {
  const activeElement = document.querySelectorAll(`.${activeClass}`);

  if (!activeElement) {
    return;
  }

  activeElement.forEach((element) => {
    element.classList.remove(activeClass);
  });

  target.classList.add(activeClass);

  document.dispatchEvent((new CustomEvent('tabs:active', { detail: { container: target } })));
};

/**
 * @param {HTMLElement} tab
 * @param {string} isActive
 * @private
 */
const _toggleContent = (tab, isActive) => {
  document.querySelector(`#${tab.dataset.tab}`).setAttribute('data-active', isActive);
};

/**
 * @param {HTMLElement} target
 * @private
 */
const _toggleTabContent = ({ currentTarget: target }) => {
  const isActive = target.dataset.active === 'true';
  const nextState = (!isActive).toString();

  if (isActive) return;

  target.parentNode.querySelectorAll('[role="tab"]').forEach((tab) => {
    tab.setAttribute('data-active', 'false');
    _toggleContent(tab, 'false');
  });

  target.setAttribute('data-active', nextState);
  _toggleContent(target, nextState);
  _dispatch(target.dataset.tab);
};

/**
 * @param {string} selector
 */
export const Tabs = (selector) => {
  const tabs = document.querySelectorAll(`${selector} [role="tab"]`);
  tabs.forEach((tab) => tab.addEventListener('click', _toggleTabContent));
};

/**
 * @param {string} container
 * @param {boolean} isUserPage
 */
export const enableTabs = (container, isUserPage) => {
  const tabs = document.querySelectorAll(`.${container} .tab-links`);

  tabs.forEach((item) => {
    item.addEventListener('click', (event) => {
      const target = event.currentTarget;
      const tabContentId = document.getElementById(target.dataset.tab);

      activeTabLinkAndContent(target, 'active-tab');
      activeTabLinkAndContent(tabContentId, 'active-content');

      if (isUserPage) {
        const tabName = MAPPING_TABS[target.dataset.tab] || '';
        const idLogin = getCookies().lumni_student_user_id || '';

        if (['watchLater', 'favorite', 'resume', 'replay'].includes(target.dataset.tab)) {
          trackingUser(target.dataset.tab, idLogin, tabName);
        } else {
          trackingUser('profile', idLogin, target.dataset.tab);
        }
      }
    });
  });
};
