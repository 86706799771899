class Lazy {
  static init(selectorThumbnail = '.lazy', selectorPicture = '.lazy-picture', selectorBackground = '.lazy-bg') {
    const lazyImages = [].slice.call(document.querySelectorAll(selectorThumbnail));
    const lazyPictures = [].slice.call(document.querySelectorAll(`${selectorPicture} > source`));
    const lazyBackground = [].slice.call(document.querySelectorAll(selectorBackground));

    if ('IntersectionObserver' in window) {
      Lazy.observeImage(lazyImages);
      Lazy.observeImage(lazyPictures, true);
      Lazy.observeImage(lazyBackground, false, true);
    } else {
      Lazy.applyFallback(lazyImages);
      Lazy.applyFallback(lazyPictures, true);
      Lazy.applyFallback(lazyBackground, false, true);
    }
  }

  static observeImage(lazyImages, isPicture = false, isBackground = false) {
    const lazyImageObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const lazyImage = entry.target;
          Lazy.replacePlaceholder(lazyImage, isPicture, isBackground);
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });

    lazyImages.forEach((lazyImage) => {
      lazyImageObserver.observe(lazyImage);
    });
  }

  static applyFallback(lazyImages, isPicture, isBackground) {
    if (isPicture) {
      lazyImages.forEach((image) => {
        image.nextElementSibling.src = image.nextElementSibling.dataset.srcset;
      });
    } else if (isBackground) {
      lazyImages.forEach((entry) => {
        Lazy.replacePlaceholder(entry, false, true);
      });
    } else {
      lazyImages.forEach((entry) => {
        Lazy.replacePlaceholder(entry);
      });
    }
  }

  static replacePlaceholder(lazyImage, isPicture, isBackground) {
    if (isPicture) {
      lazyImage.srcset = lazyImage.dataset.srcset;
      lazyImage.nextElementSibling.srcset = lazyImage.dataset.srcset;
      lazyImage.parentElement.classList.remove('lazy-picture');
    } else if (isBackground) {
      lazyImage.style.background = `url(${Lazy.getBackgroundImage(lazyImage)}) 50% 100% / cover no-repeat`;
      lazyImage.classList.remove('lazy-bg');
    } else {
      lazyImage.src = lazyImage.dataset.src;
      lazyImage.classList.remove('lazy');
    }
  }

  static getBackgroundImage(lazyImage) {
    const breakView = window.innerWidth;

    if (breakView >= 1024) {
      return lazyImage.dataset.srcdesktop;
    }

    if (breakView > 576) {
      return lazyImage.dataset.srctablet;
    }

    return lazyImage.dataset.srcmobile;
  }
}

export default Lazy;
