import { getSearchResults } from '@global-js/modules/search/SearchResults';
import { getQueryParams } from '@global-js/utils/queryParameters';

class OrderBy {
  constructor() {
    this.sortingTitle = document.querySelector('.sorting-title');
    this.sortingChoices = document.querySelector('.sorting-choices');
    this.sortingItems = document.querySelectorAll('.sorting-item div');
    this.filterItems = document.querySelectorAll('.sorting-list .filter');
    this.classActive = 'active-sorting';
    this.sortingGrid = document.querySelector('.sorting-grid--icon');
    this.sortingList = document.querySelector('.sorting-list--icon');
  }

  init() {
    this.setText();

    this.sortingTitle.addEventListener('click', () => this.toggleSortingChoices());
    this.sortingGrid.addEventListener('click', () => this.switchFormat('grid'));
    this.sortingList.addEventListener('click', () => this.switchFormat('list'));

    document.addEventListener('switchFormatEvent', () => this.switchFormat(), false);

    [].forEach.call([...this.sortingItems, ...this.filterItems], (element) => {
      element.addEventListener('click', (e) => this.search(e));
      element.addEventListener('keyup', (e) => {
        if (e.keyCode === 13) {
          this.search(e);
        }
      });
    });
  }

  setText() {
    const params = new URLSearchParams(window.location.search);
    const orderBy = params.get('orderBy');

    if (orderBy) {
      const element = document.querySelector(`.sorting-item [data-type=${orderBy}]`);
      this.updateSortingTitle(element);
    }
  }

  search(event) {
    const element = event.currentTarget;
    const orderBy = element.getAttribute('data-type');
    const filters = {
      ...(getQueryParams(new URL(window.location.href))),
      orderBy,
    };

    OrderBy.updateUrl(filters, element.getAttribute('data-type'), 'orderBy');
    getSearchResults(filters);

    this.updateSortingTitle(element);
    this.toggleSortingChoices();
  }

  updateSortingTitle(element) {
    this.sortingTitle.querySelector('span').innerHTML = element.textContent.trim();
    this.setActiveOrder(element);
  }

  toggleSortingChoices() {
    this.sortingChoices.classList.toggle('sorting-choices--active');
    if (document.querySelector('.sorting-choices--active') !== null) {
      this.sortingTitle.setAttribute('aria-expanded', 'true');
    } else {
      this.sortingTitle.setAttribute('aria-expanded', 'false');
    }
  }

  setActiveOrder(element) {
    this.sortingItems.forEach((node) => {
      node.classList.remove(this.classActive);
    });
    element.classList.add(this.classActive);
  }

  switchFormat(format) {
    const params = getQueryParams(window.location);
    format = format || (params.format === undefined ? 'grid' : params.format);
    const filters = {
      ...(getQueryParams(new URL(window.location.href))),
      format,
    };

    OrderBy.updateUrl(filters, format, 'format');
    OrderBy.updatePaginationUrl(format);

    const listContainer = document.querySelector('.list__container--search');

    if (!listContainer) {
      return;
    }

    const cards = document.querySelectorAll('.card');

    if (format === 'grid') {
      listContainer?.classList.add('list__container--grid');
      this.sortingGrid.classList.add(this.classActive);
      this.sortingList.classList.remove(this.classActive);
    } else {
      listContainer?.classList.remove('list__container--grid');
      this.sortingList.classList.add(this.classActive);
      this.sortingGrid.classList.remove(this.classActive);
    }

    [].forEach.call(cards, (element) => {
      element.setAttribute('data-format', format === 'grid' ? 'horizontal-s-fluid desktop-square' : 'horizontal');
    });
  }

  static updateUrl(filters, param, filter) {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    searchParams.set(filter, param);
    url.search = searchParams.toString();
    window.history.pushState(filters, null, url);
  }

  static updatePaginationUrl(format) {
    const searchResults = document.querySelector('#searchResults');
    const pagination = searchResults?.querySelector('.pagerfanta-v2');

    if (!pagination) {
      return;
    }

    [].forEach.call(pagination.querySelectorAll('a'), (element) => {
      const url = new URL(element.href);
      const searchParams = new URLSearchParams(url.search);
      searchParams.set('format', format);
      url.search = searchParams.toString();
      element.href = url.href;
    });
  }
}

export default OrderBy;
