import { loadIframe } from '../iframe';
import { isiOS } from '../../../../../global/assets/scripts/utils/browser';

/**
 * @param {string} gameName
 * @private
 */
const _trackingClick = (gameName) => {
  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: {
      type: 'game',
      data: { gameName, action: 'play' },
    },
  }));
};

/**
 * @private
 */
const _dispatch = () => {
  document.dispatchEvent(new CustomEvent('navigation:game:record'));
};

class Iframe {
  constructor() {
    this.iframe = document.querySelector('.game iframe');
    this.hideImg = document.querySelector('.game__img');
    this.fullscreenBtn = document.querySelector('.fullscreen-button');
    this.fullscreenIcon = document.querySelector('.game__fullscreen-icon');
    this.game = document.querySelector('.game');
    this.body = document.querySelector('body');
  }

  init() {
    this.gameStart();
    this.fullscreen();
  }

  gameStart() {
    this.hideImg.addEventListener('click', () => this.launchGame());
  }

  launchGame() {
    loadIframe(this.iframe, 'game');
    this.hideImg.style.display = 'none';
    this.fullscreenBtn.style.display = 'flex';

    _dispatch();

    const gameName = this.iframe.dataset.slug.replace(/-/gi, '_');
    _trackingClick(gameName);
  }

  fullscreen() {
    this.fullscreenBtn.onclick = () => {
      if (window.innerWidth <= 1199 && isiOS()) {
        this.iframe.classList.toggle('fullscreen-ios');
        this.body.classList.toggle('body--fixed');
        this.body.classList.toggle('fullscreen');
      }
      if (this.iframe.requestFullscreen) {
        this.iframe.requestFullscreen();
      } else if (this.iframe.mozRequestFullScreen) {
        this.iframe.mozRequestFullScreen();
      } else if (this.iframe.webkitRequestFullscreen) {
        this.iframe.webkitRequestFullscreen();
      } else if (this.iframe.msRequestFullscreen) {
        this.iframe.msRequestFullscreen();
      }
    };

    this.iframe.addEventListener('fullscreenchange', () => {
      this.body.classList.toggle('fullscreen', document.fullscreenElement);
    });
  }
}

export default Iframe;
