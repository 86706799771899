import { createCookie, removeCookie } from '../../../utils/cookies';

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }

  return '';
};

const isTimestampOlderThan30Seconds = (timestamp) => {
  const currentTime = Math.floor(Date.now() / 1000);
  return (currentTime - Math.floor(timestamp)) > 30;
};

const checkTimestamp = (timestamp, cookie) => {
  if (isTimestampOlderThan30Seconds(timestamp)) {
    if (cookie === 'registration') {
      const now = new Date();
      const expire = now.setTime(now.getTime() + 3600 * 1000);
      createCookie('lumni_student_user_justcreated', 'true', expire);
      removeCookie('lumni_user_first_time');
    }
    if (cookie === 'login') {
      createCookie('lumni_student_user_justconnected', 'true', 365);
      removeCookie('lumni_user_time_connect');
    }
    // eslint-disable-next-line no-use-before-define
    clearInterval(checkInterval);
  }
};

export const checkInterval = (cookieName, cookieType) => {
  setInterval(() => {
    checkTimestamp(getCookie(cookieName), cookieType);
  }, 1000);
};
