import Api from './connector/Api';

/**
 *  Class to manage "favorites contents" and "contents to watch later" for an user
 *  When the user is connected, contents are fetched from the API (ApiConnector)
 */
class Favorite {
  constructor() {
    this.connector = new Api();
  }

  /**
   * @param {string} listName
   * @param {string} slug
   * @param {string} type
   *
   * @returns {Promise}
   */
  addItemToList(listName, slug, type) {
    return this.connector.addItemToList({ slug, type }, listName);
  }

  /**
   * @param {string} listName
   * @param {string} slug
   * @param {string} type
   *
   * @returns {Promise}
   */
  removeItemFromList(listName, slug, type) {
    return this.connector.removeItemFromList({ slug, type }, listName);
  }

  /**
   * @param {string} slug
   * @param {string} type
   * @param {string} listName
   *
   * @returns {Promise}
   */
  isItemInList(slug, type, listName) {
    return this.connector.isItemInList({ slug, type }, listName);
  }
}

export default Favorite;
