import { createCookie } from '../../../utils/cookies';

const COOKIE_LIFETIME = 365;

/**
 * @param {Object} data
 * @private
 */
export const createCookies = (data) => {
  if (data.establishment) {
    createCookie('lumni_user_establishment', data.establishment, COOKIE_LIFETIME);
  }

  if (data.schoolLevel) {
    createCookie('lumni_user_schoolLevel', data.schoolLevel, COOKIE_LIFETIME);
  }
};
