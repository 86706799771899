/**
 * @param {object} data
 * @private
 */
const _dispatchTrackingEvents = (data) => {
  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: {
      type: 'incitation_compte',
      data: {
        action: data.name,
      },
    },
  }));
};

export const setupOnClickIncitationProfile = () => {
  const incitationProfileContainer = document.querySelectorAll('.incitation-profile__container');
  const btnElements = document.querySelectorAll('.register-btns > a');

  if (incitationProfileContainer) {
    btnElements.forEach((btnElement) => {
      btnElement.addEventListener('click', () => {
        const { action } = btnElement.dataset;
        _dispatchTrackingEvents({ action: 'incitation_compte', name: action });
      });
    });
  }
};
