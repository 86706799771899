import { createCookie, getCookies } from '../../../../../global/assets/scripts/utils/cookies';

export const onClickRegristrationNewsletter = () => {
  const newsletterAgreementYes = document.querySelector('#newsLetterSubscriptionYes:checked');
  const cookie = 'lumni_nl_registered';
  const nlRegisteredCookie = getCookies([cookie]);
  const cookieValidation = 90;

  if (!newsletterAgreementYes) return;

  const isRegistered = nlRegisteredCookie.lumni_nl_registered
    ? JSON.parse(nlRegisteredCookie.lumni_nl_registered)
    : null;

  if (isRegistered === null) {
    createCookie(cookie, 'true', cookieValidation);
  }
};
