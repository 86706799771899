export const notifsData = [
  {
    hook: document.getElementById('js-hook-confirm-login'),
    info: 'confirm-login',
  },
  {
    hook: document.getElementById('js-hook-authentication-error'),
    info: 'auth-error',
  },
  {
    hook: document.getElementById('js-hook-bad-credentials-error'),
    info: 'bad-credentials',
  },
  {
    hook: document.getElementById('js-hook-existing-account'),
    info: 'existing-account',
  },
  {
    hook: document.getElementById('js-hook-expired-password'),
    info: 'expired-password',
  },
  {
    hook: document.getElementById('js-hook-lost-credentials'),
    info: 'lost-credentials',
  },
];
