class ProgressBar {
  constructor() {
    this.cursor = document.querySelector('.progress-bar__cursor--dynamic');
    this.currentQuestion = document.querySelector('#currentQuestion');
    this.totalQuestions = document.querySelector('#totalQuestions');
  }

  init() {
    if (this.cursor) {
      this.advanceTo(1);
    }
  }

  advanceTo(questionPosition) {
    const position = 100 * (questionPosition / this.totalQuestions.dataset.value);
    this.currentQuestion.textContent = questionPosition;
    this.cursor.style.width = `${position}%`;
  }
}

export default ProgressBar;
