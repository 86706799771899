import { ajaxJson } from '@global-js/utils/ajax';
import { AppProtected } from '../../AppProtected';
import { dispatchEvents, UserEvent } from './events/events';
import {
  displayUsername,
  displayPoints,
  gameLevel,
  gameInformation,
  renderLumnizButton,
  displayLevel,
} from './ui/security-ui';
import { resetUserSession, setUserSessionStorage } from './session/session';
import { createCookies } from './session/cookie';
import * as user from './session/user';

const CONFIG = {
  securityPageSelector: '#securityPage',
  pathUserInfo: '/ajax/get-user-info',
  pathUserSettings: '/mes-informations',
  pathUserProfil: '/mon-profil',
};

/**
 * @param {Object} data
 * @return {boolean}
 * @private
 */
function _isValidData(data) {
  return data !== null
  && data.userId !== undefined
  && data.email !== undefined
  && data.firstName !== undefined;
}

/**
 * @param {boolean} isUserAuthenticated
 * @private
 */
const _initAppProtected = (isUserAuthenticated = false) => {
  AppProtected(false, isUserAuthenticated);
};

/**
 * @param {Object|null} data
 * @private
 */
const _onRefreshData = (data) => {
  // Dispatch event for Piano Tracking
  document.dispatchEvent(new CustomEvent('Tracking:login', {
    detail: {
      type: 'security',
      data: { action: 'success' },
    },
  }));
  const isUserAuthenticated = _isValidData(data);
  window.isUserAuthenticated = isUserAuthenticated;

  setUserSessionStorage(data);
  displayUsername(data || { email: '' }, isUserAuthenticated);
  displayPoints(data || { points: '0' }, isUserAuthenticated);
  displayLevel(data || { level: '0' }, true);
  gameLevel(data || { nextLevel: '0', percentageCurrentSubLevel: '0' }, isUserAuthenticated);
  gameInformation(data || { nextLevel: '0', points: '0', goalPoints: '0' }, isUserAuthenticated);
  renderLumnizButton(isUserAuthenticated);
  createCookies(data);
  dispatchEvents(data);

  _initAppProtected(isUserAuthenticated);
};

/**
 * @private
 */
const _getUserInformation = () => {
  const { pathname } = window.location;
  const schoolLevel = sessionStorage.lumni_student_user_school_level;
  const hasToRefreshData = pathname === CONFIG.pathUserSettings
    || !user.hasInformationInSession()
    || pathname === CONFIG.pathUserProfil
    || pathname.includes(schoolLevel);
  if (hasToRefreshData) {
    ajaxJson(`${window.location.origin}${CONFIG.pathUserInfo}`, false)
      .then((data) => _onRefreshData(data))
      .catch((error) => console.log('error :', error));

    return;
  }

  // Else use data from the session
  window.isUserAuthenticated = true;
  displayUsername({ email: user.getEmail(), firstName: user.getFirstName() }, true);
  displayPoints({ points: user.getPoints() }, true);
  displayLevel({ level: user.getLevel() }, true);
  renderLumnizButton(true);
  UserEvent(user.getId(), user.getSchoolLevel());

  _initAppProtected(true);
};

/**
 * @private
 */
const _toggleSubMenu = () => {
  const btn = document.querySelector('button.user-menu__tab');
  const arrowIcon = document.querySelector('.user-menu__arrow');
  btn?.addEventListener('click', () => {
    const sublist = document.querySelector('.header__sublist');
    if (sublist.style.display === 'block') {
      sublist.style.display = 'none';
      arrowIcon.style.transform = 'rotate(0deg)';
    } else {
      sublist.style.display = 'block';
      arrowIcon.style.transform = 'rotate(180deg)';
    }
  });
};

const _toggleA11ySubmenu = () => {
  const topLevelLinks = document.querySelectorAll('.btn--user__login');

  topLevelLinks.forEach((link) => {
    if (link) {
      link.addEventListener('focus', () => {
        link.setAttribute('aria-expanded', 'true');
        link.nextElementSibling.setAttribute('tabindex', '1');
      });

      const subMenu = link.nextElementSibling;
      const subMenuLinks = subMenu.querySelectorAll('li');
      const lastLinkIndex = subMenuLinks.length - 1;
      const lastLink = subMenuLinks[lastLinkIndex];
      const lastLinkInside = lastLink.querySelectorAll('a');

      lastLinkInside[0].addEventListener('blur', () => {
        link.setAttribute('aria-expanded', 'false');
      });
    }
  });
};

/**
 * This component check if the user is authenticated or refresh his information
 * And initialize the AppProtected component which will initialize protected components.
 */
export const Security = () => {
  if (document.querySelector(CONFIG.securityPageSelector)) {
    return;
  }
  _toggleSubMenu();
  _toggleA11ySubmenu();

  if (user.isConnected()) {
    _getUserInformation();
    return;
  }

  displayUsername({});
  displayLevel({ level: '0' }, false);
  resetUserSession();
  _initAppProtected(false);
};
