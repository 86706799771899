/**
 * @private
 */
const _dispatchEvent = () => {
  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: { type: 'see_more_content' },
  }));
};

class DescriptionContent {
  constructor() {
    this.breakView = window.innerWidth;
    this.descriptionText = document.querySelectorAll('.description__text');
    this.descriptionArrow = document.querySelector('.description__arrow');
    this.descriptionTextReadMore = document.querySelector('.description__text--read-more');
    this.descriptionArrowMobile = document.querySelector('.description__arrow-mobile');
    this.DescriptionReadMore = document.querySelector('.description__read-more');
    this.partner = document.querySelector('.partner');
    this.mobileDescription = document.getElementById('mobileDescription');
  }

  init() {
    if (this.descriptionArrowMobile === null || this.descriptionTextReadMore === null) {
      return;
    }

    [].forEach.call(this.descriptionText, (element) => {
      element.addEventListener('click', () => this.showMore());
    });

    if (this.breakView <= 768) {
      this.toggleDescriptionMobile();
    } else {
      this.hideBtnReadMore();
      this.toggleDescriptionDesktop();
    }
  }

  toggleDescriptionMobile() {
    this.descriptionArrowMobile.addEventListener('click', () => {
      this.descriptionArrow.classList.toggle('description__arrow--open');
      const expanded = this.descriptionArrow.classList.contains('description__arrow--open') ? 'true' : 'false';
      this.descriptionArrowMobile.setAttribute('aria-expanded', expanded);
      if (this.mobileDescription !== null) {
        this.mobileDescription.classList.toggle('mobile-description');
      }
    });
  }

  toggleDescriptionDesktop() {
    this.descriptionTextReadMore.addEventListener('click', () => {
      if (this.descriptionTextReadMore.textContent === 'voir plus') {
        this.descriptionTextReadMore.textContent = 'voir moins';
        this.descriptionTextReadMore.setAttribute('aria-expanded', 'true');
      } else {
        if (document.getElementById('playlistVideo')) {
          document.getElementById('playlistVideo')
            .scrollIntoView(true);
        }
        if (document.getElementsByClassName('block-game-three')[0]) {
          document.getElementsByClassName('block-game-three')[0].scrollIntoView(true);
        }
        this.descriptionTextReadMore.textContent = 'voir plus';
        this.descriptionTextReadMore.setAttribute('aria-expanded', 'false');
      }
    });
  }

  showMore() {
    this.DescriptionReadMore.classList.toggle('description__read-more--open');
    this.DescriptionReadMore.classList.toggle('description__read-more--close');
    this.descriptionArrow.classList.toggle('description__arrow-mobile--open');

    if ((this.breakView <= 768 && this.descriptionArrowMobile.getAttribute('aria-expanded') === 'false')
    || (this.breakView >= 769 && this.descriptionTextReadMore.textContent === 'voir plus')) {
      _dispatchEvent();
    }
  }

  hideBtnReadMore() {
    const isEllipsisActive = (e) => (e.offsetHeight < e.scrollHeight);

    if (!isEllipsisActive(this.DescriptionReadMore)) {
      this.descriptionTextReadMore.style.display = 'none';
    }
  }
}

export default DescriptionContent;
