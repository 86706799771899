export const enablePlayerOnfullwidth = () => {
  const wrapper = document.querySelector('.wrapper--grid-playlist');
  const route = window.LUMNI_CONTEXT.route || null;
  const targetPages = ['quiz', 'video', 'game'];

  if (!wrapper || !route || !targetPages.includes(route)) return;

  wrapper.classList.add('wrapper--grid--player-full-width');
};

export const hasInterlayer = () => {
  const { route } = window.LUMNI_CONTEXT;
  if (!window.ENABLE_NEW_DA || (route !== 'quiz' && route !== 'game')) {
    return;
  }

  const interlayerClass = `interlayer--${route}`;
  const breadcrumb = document.querySelector('.container--breadcrumb-v2');
  const element = document.querySelector('.wrapper--grid-playlist');
  const parent = element.closest('.wrapper--with-menu');

  breadcrumb.classList.add(interlayerClass);
  parent.classList.add(interlayerClass);
};
