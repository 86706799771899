import { FormValidation } from '../../../molecules/security/form/validation';
import { PasswordVisibility } from '../../../molecules/security/form/password-visibility';
import { SelectDropdown } from '../../../molecules/security/form/select-dropdown';
import { RegistrationConditions } from '../../../molecules/security/registration_conditions/registration_conditions';
import { CertifyAgeValidation } from '../../../molecules/security/consent/consent';

const inputs = {
  email: '#form_email',
  password: '#form_password',
  firstName: '#form_firstName',
  schoolLevel: '#form_schoolLevel',
};

export const FormRegistration = () => {
  // Temporary
  if (!document.querySelector('.formV2')) return;

  // Dispatch event for Piano Tracking
  if (window.LUMNI_CONTEXT.headers['ftv-existing-account-error']) {
    document.dispatchEvent(new CustomEvent('Tracking:login', {
      detail: {
        type: 'security',
        data: {
          action: 'error',
          error_message: 'vous_avez_deja_un_compte',
        },
      },
    }));
  }

  FormValidation(inputs);
  CertifyAgeValidation();
  PasswordVisibility();
  SelectDropdown();
  RegistrationConditions();
};
