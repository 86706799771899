import { openPopup } from '@global/organisms/popup/popup';
import { isMobile } from '@global-js/utils/browser';

/**
 * @private
 */
const _dispatchEvents = () => {
  document.dispatchEvent(new CustomEvent('Tracking:impression', {
    detail: { type: 'share' },
  }));
};

const onShare = () => {
  if (navigator.share && isMobile() !== null) {
    navigator.share({ url: document.location.href });
    return;
  }

  const parent = document.querySelector('.icon-share');
  const popup = parent.closest('.popup-container');

  openPopup(popup);

  _dispatchEvents();
};

export const Share = () => {
  const toolboxShare = document.querySelector('.toolbox__share');

  if (!toolboxShare) {
    return;
  }

  toolboxShare.addEventListener('click', onShare, false);
};
