import { CONFIG } from './config';

export const STATE_FOCUS = 'focus';
export const STATE_DEFAULT = 'default';
export const STATE_ERROR = 'error';
export const STATE_ERROR_REQUIRED = 'error-required';
export const STATE_VALID = 'valid';

/**
 * @param {boolean} isValid
 * @param {boolean} isEmpty
 * @returns {string}
 * @private
 */
export const _getState = (isValid, isEmpty) => {
  if (isEmpty) return STATE_ERROR_REQUIRED;

  return isValid ? STATE_VALID : STATE_ERROR;
};

/**
 * @param {array} errors
 * @private
 */
export const _updatePasswordState = (errors) => {
  const rulesElements = document.querySelectorAll('span[data-hint]');
  const invalidRules = errors.map((value) => value[0]);

  rulesElements?.forEach((ruleElement) => {
    ruleElement.classList.toggle(
      CONFIG.classNames.invalidRule,
      invalidRules.includes(ruleElement.dataset.hint),
    );

    ruleElement.classList.toggle(
      CONFIG.classNames.validRule,
      !invalidRules.includes(ruleElement.dataset.hint),
    );
  });

  const prefixTextPasswordHelper = document.querySelector(
    CONFIG.selectors.prefixTextPasswordHelper,
  );

  if (prefixTextPasswordHelper) {
    prefixTextPasswordHelper.classList.toggle(
      CONFIG.classNames.helperOnError,
      invalidRules.length !== 0,
    );
  }
};

/**
 * @param {HTMLInputElement} input
 * @param {boolean} isValid
 * @param {array} errors
 */
export const updateState = (input, isValid, errors = []) => {
  input.setAttribute('aria-invalid', (!isValid).toString());

  const inputGroup = input.closest('.input-group');
  const isEmpty = ['radio', 'checkbox'].includes(input.type) ? !input.checked : input.value.trim().length === 0;

  inputGroup.setAttribute('data-state', _getState(isValid, isEmpty));

  if (CONFIG.selectors.passwordInput === input.id) {
    _updatePasswordState(errors);
  }
};
