import { ajaxHTML } from '@global-js/utils/ajax';
import Lazy from '@global-js/Lazy';
import { toggleLoader } from '@global-js/modules/search/SearchResults';
import { enablePagination } from '../pagination';

const REPLAY = 'revoir';
const RESUME = 'reprendre';

let filters = [];

/**
 * @param {string} selector
 * @param category{string}
 * @param {function} callback
 * @private
 */
const _initPlugins = (selector, category, callback) => {
  enablePagination(selector, callback);

  Lazy.init(`${selector} .lazy`, `${selector} .lazy-picture`);

  document.dispatchEvent((new CustomEvent('refreshAddPlaylist', { detail: { container: document.querySelector(selector) } })));
  document.dispatchEvent((new CustomEvent('history:data-view', { detail: { category } })));
};

/**
 * @param {string} category
 * @param {string} selector
 * @param {int} page
 * @private
 */
const _getItems = (category, selector, page = 1) => {
  toggleLoader('show');

  const params = new URLSearchParams({ page: page.toString() });

  if (category === REPLAY && filters.length > 0) {
    params.append('filters', filters);
  }

  ajaxHTML(`${window.location.origin}/ajax/mon-historique/${category}?${params.toString()}`)
    .then((response) => response.text())
    .then((template) => {
      const element = document.querySelector(selector);
      element.innerHTML = template;

      toggleLoader('hide');
      _initPlugins(selector, category, _getItems);
    })
    .catch((e) => console.log(e.message));
};

/**
 * @param {boolean} displayFilters
 * @private
 */
const _toggleFiltersContainer = (displayFilters = true) => {
  const filtersContainer = document.querySelector('.container--user-filters');
  filtersContainer.setAttribute('data-active', displayFilters.toString());
};

export const myHistory = () => {
  const resume = document.querySelector('.resume');
  const replay = document.querySelector('.replay');

  if (resume === null) {
    return;
  }

  resume.addEventListener('click', () => _toggleFiltersContainer(false));
  replay.addEventListener('click', () => _toggleFiltersContainer(true));

  enablePagination('#resume', _getItems);
  enablePagination('#replay', _getItems);

  document.dispatchEvent((new CustomEvent('history:data-view', { detail: { category: RESUME } })));

  document.addEventListener('filters:type', ({ detail }) => {
    filters = [...detail.filters.values()];
    _getItems(REPLAY, '#replay');
  });
};
