class DescriptionContentV2 {
  constructor() {
    this.descriptionText = document.querySelectorAll('.description-v2__text');
    this.descriptionTextReadMore = document.querySelector('.description-v2__text--read-more');
    this.descriptionReadMore = document.querySelector('.description-v2__read-more');
    this.descriptionTextHeight = document.querySelector('.description-v2__read-more__content')?.offsetHeight;
    this.descriptionBoxHeight = document.querySelector('.description-v2__read-more')?.offsetHeight;
  }

  init() {
    if (this.descriptionTextReadMore === null) {
      return;
    }

    [].forEach.call(this.descriptionText, (element) => {
      element.addEventListener('click', () => this.showMore());
    });

    this.toggleDescription();
  }

  toggleDescription() {
    if (this.descriptionTextHeight > this.descriptionBoxHeight) {
      this.descriptionTextReadMore.addEventListener('click', () => {
        if (this.descriptionTextReadMore.textContent === 'voir plus') {
          this.descriptionTextReadMore.textContent = 'voir moins';
          this.descriptionTextReadMore.setAttribute('aria-expanded', 'true');
        } else {
          if (document.getElementById('playlistVideo')) {
            document.getElementById('playlistVideo')
              .scrollIntoView(true);
          }
          if (document.getElementsByClassName('block-game-three')[0]) {
            document.getElementsByClassName('block-game-three')[0].scrollIntoView(true);
          }
          this.descriptionTextReadMore.textContent = 'voir plus';
          this.descriptionTextReadMore.setAttribute('aria-expanded', 'false');
        }
      });
    } else {
      this.descriptionTextReadMore.setAttribute('aria-expanded', 'true');
      this.descriptionTextReadMore.style.display = 'none';
    }
  }

  showMore() {
    this.descriptionReadMore.classList.toggle('description-v2__read-more--open');
    this.descriptionReadMore.classList.toggle('description-v2__read-more--close');
  }
}

export default DescriptionContentV2;
