const cleanStorage = () => {
  // eslint-disable-next-line array-callback-return
  Object.keys(sessionStorage).map((item) => {
    const splittedItem = item.split('_');

    if (splittedItem[0] === 'check') {
      sessionStorage.removeItem(item);
    }
  });
};

export default cleanStorage;
