/**
 * @param {string} position
 * @private
 */
const _dispatch = (position) => {
  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: { type: 'search', data: { position } },
  }));
};

/**
 * @param {HTMLElement} currentTarget
 * @private
 */
const _onClick = ({ currentTarget }) => {
  _dispatch(currentTarget.dataset.position);
};

/**
 * @param {object} detail
 * @private
 */
const _enableEvents = ({ detail = {} }) => {
  const container = detail.container || document;
  const cards = container.querySelectorAll('.card');

  cards.forEach((card) => card.addEventListener('click', _onClick, false));
};

export const Search = () => {
  if (window.LUMNI_CONTEXT.route !== 'search') return;

  _enableEvents({});
  document.addEventListener('refreshCardRedirection', _enableEvents, false);
};
