let focusTrapListener = null;
export const trapFocus = (element, lastElement) => {
  if (focusTrapListener) {
    element.removeEventListener('keydown', focusTrapListener);
  }
  const focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
  const firstFocusableEl = focusableEls[0];
  let lastFocusableEl;
  const KEYCODE_TAB = 9;

  if (lastElement) {
    lastFocusableEl = lastElement;
  } else {
    lastFocusableEl = focusableEls[focusableEls.length - 1];
  }

  focusTrapListener = (e) => {
    const isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

    if (!isTabPressed) return;

    if (e.shiftKey) {
      if (document.activeElement === firstFocusableEl) {
        if (lastFocusableEl) lastFocusableEl.focus();
        e.preventDefault();
      }
    } else if (document.activeElement === lastFocusableEl) {
      if (firstFocusableEl) firstFocusableEl.focus();
      e.preventDefault();
    }
  };

  element.addEventListener('keydown', focusTrapListener);
};
