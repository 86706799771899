import { getQueryParams, getUrl, updateBrowserUrl } from '@global-js/utils/queryParameters';
import { ajaxHTML } from '@global-js/utils/ajax';
import { getSearchResults } from '@global-js/modules/search/SearchResults';
import Cookies from '@global-js/Cookies';
import * as _ from './filter_constants';

class AdvancedFilter {
  constructor() {
    this.apiUrl = `${window.location.origin}/ajax/filters`;
    this.filtersContainer = document.querySelector('#filterBody');
    this.filtersHeaderContainer = document.querySelector('.header-search__active-items');
    this.filterMenu = document.querySelector('.advanced-filter--mobile');
    this.filterList = document.querySelector('.filter-list');
    this.filterResult = document.querySelector('.filter-result--mobile');
    this.filterReset = document.querySelector('.filter-reset');
    this.overlay = document.querySelector('.filter-overlay');
    this.close = document.querySelector('.advanced-filter .close');
    this.sort = document.querySelector('.sorting-list');
    this.resultDesktop = document.querySelector('.filter-result--desktop');
    this.sectionsOpen = _.DEFAULT_SECTIONS_OPEN;
    this.lastTarget = null;
    this.filters = null;
  }

  init() {
    this.enableEvents();
    this.initFilters();
    this.getFiltersAndSearch(true);
  }

  enableEvents() {
    [this.filtersContainer, this.filtersHeaderContainer].forEach((item) => {
      item.addEventListener('click', ({ target }) => {
        if (target.classList.contains('filter')) this.onClickFilter(target);
      });
    });

    this.filtersContainer.addEventListener('click', ({ target }) => {
      if (target.classList.contains('filter-seemore')) this.onClickSeeMore(target);
      if (target.classList.contains('block-filter-title')) this.onClickSection(target);
    });

    [this.filterMenu, this.filterResult, this.close].forEach((selector) => {
      selector.addEventListener('click', ({ target }) => this.onClickMenuFilter(target));
    });

    this.sort.addEventListener('click', ({ target }) => this.onClickSortResults(target));
    this.filterReset.addEventListener('click', ({ target }) => this.onClickReset(target));

    this.resultDesktop.addEventListener('click', () => window.scrollTo(0, 0));
  }

  initFilters() {
    const urlParameters = getQueryParams(window.location);
    const { establishment, schoolLevel } = urlParameters;
    const [, , level] = window.location.pathname.split('/');

    const institution = _.ESTABLISHMENTS[establishment] || _.ESTABLISHMENTS[level] || null;
    const defaultSchoolLevel = _.SCHOOLLEVELS[schoolLevel] || null;

    this.filters = _.DEFAULT_FILTERS;

    if (institution !== null) {
      this.filters.institutions = [institution];
    }

    if (defaultSchoolLevel !== null) {
      this.filters.schoolLevels = [defaultSchoolLevel];
    }

    Object.keys(urlParameters).forEach((key) => {
      const value = urlParameters[key];

      if (this.filters[key] !== undefined && value !== '') {
        if (typeof value === 'string') {
          value.split(',').forEach((val) => {
            if (this.filters[key].indexOf(val) === -1) this.filters[key].push(val);
          });
        }
      }
    });

    this.initCookies();
  }

  initCookies() {
    AdvancedFilter.updateCookie(_.SCHOOLLEVELS, this.filters.schoolLevels, 'schoolLevel');
    AdvancedFilter.updateCookie(_.ESTABLISHMENTS, this.filters.institutions, 'establishment');
  }

  getFiltersAndSearch(initialState = false) {
    const { isCustomQuery, parameters } = this.getSearchFilters(initialState);
    const initialValue = parameters.institutions.length > 0 && parameters.schoolLevels.length > 0;

    if ((!isCustomQuery && !initialState) || (initialState && initialValue)) {
      updateBrowserUrl('/recherche', parameters);
    }

    ajaxHTML(getUrl({
      ...this.filters,
      query: parameters.query || '',
    }, this.apiUrl))
      .then((response) => response.text())
      .then((template) => {
        this.filtersContainer.innerHTML = template;
        this.filtersHeaderContainer.innerHTML = '';
        this.selectFilters();
        this.openSections();
        this.displayNumberOfActiveFilters();
        this.focusLastTarget();
      })
      .catch((e) => console.log(e.message));

    getSearchResults(parameters);
  }

  onClickFilter(target) {
    AdvancedFilter.toggleClassName(target, _.CLASS_ACTIVE_FILTER);
    this.updateFilters(target);
    this.getFiltersAndSearch();

    this.lastTarget = target;

    if (['institutions', 'schoolLevels'].includes(target.dataset.filter)) {
      this.initCookies();
    }
  }

  onClickSeeMore(target) {
    AdvancedFilter.toggleSeeMore(target);

    const isOpen = target.textContent === _.SEE_LESS;
    if (!isOpen) target.parentNode.scrollIntoView();

    this.updateSectionsOpen(target, isOpen);
  }

  onClickMenuFilter(target) {
    if (!target.classList.contains('filter-result--desktop')) {
      AdvancedFilter.toggleClassName(this.filterList, 'filter-list--open');
      AdvancedFilter.toggleClassName(this.overlay, 'overlay-open');
      AdvancedFilter.toggleClassName(this.close, 'close--active');
      AdvancedFilter.toggleClassName(document.querySelector('.search-wrapper > div'), 'container');
    }
  }

  onClickSortResults(target) {
    [].forEach.call(this.sort.querySelectorAll('.filter'), (element) => {
      element.classList.remove(_.CLASS_ACTIVE_FILTER);
    });

    AdvancedFilter.toggleClassName(target, _.CLASS_ACTIVE_FILTER);
  }

  onClickSection(target) {
    AdvancedFilter.toggleSection(target);
    this.updateSectionsOpen(target, target.classList.contains(_.CLASS_SECTION_OPEN));
  }

  onClickReset() {
    Object.keys(this.sectionsOpen).forEach((key) => { this.sectionsOpen[key] = []; });
    Object.keys(this.filters).forEach((key) => { this.filters[key] = []; });

    const { state } = window.history;

    if (state !== null) {
      const { query, orderBy, format } = state;
      updateBrowserUrl('/recherche', { query: query || '', orderBy: orderBy || '', format: format || 'list' });
    }

    this.getFiltersAndSearch();
  }

  copyActiveFilters(target) {
    if (!target) {
      return;
    }

    const activeListFilterBody = this.filtersContainer.querySelector('.active-filter-list');
    const activeFilterContainers = [this.filtersHeaderContainer, activeListFilterBody];
    const selector = target.dataset ? target.dataset.value : target;

    activeListFilterBody.classList.add('active-filter-list__open');

    activeFilterContainers.forEach((item) => {
      const activeFilter = item.querySelector(`button[data-value="${selector}"]`);

      if (activeFilter) {
        item.removeChild(activeFilter);
      } else {
        const clone = target.cloneNode(true);
        clone.insertAdjacentHTML('beforeend', '<div class="close-cross"></div');
        item.appendChild(clone);
      }
    });
  }

  selectFilters() {
    Object.keys(this.filters).forEach((filter) => {
      this.filters[filter].forEach((value) => {
        const filterElement = AdvancedFilter.getFilterElement(value, 'filter', filter);
        AdvancedFilter.toggleClassName(filterElement, _.CLASS_ACTIVE_FILTER);
        AdvancedFilter.setAriaAttr(filterElement);
        this.copyActiveFilters(filterElement);
      });
    });
  }

  openSections() {
    Object.keys(this.sectionsOpen).forEach((section) => {
      this.sectionsOpen[section].forEach((value) => {
        const target = AdvancedFilter.getFilterElement(value, 'section', section);
        if (section === 'seemore') AdvancedFilter.toggleSeeMore(target);
        if (section === 'filters') AdvancedFilter.toggleSection(target);
      });
    });
  }

  displayNumberOfActiveFilters() {
    let count = 0;
    const resultCount = document.querySelector('.results-count');

    if (!resultCount.textContent.startsWith('Aucun')) {
      Object.keys(this.filters).forEach((filter) => {
        count += this.filters[filter].length;
      });

      [].forEach.call(document.querySelectorAll('.count-active--filter'), (element) => {
        element.textContent = `${count > 0 ? `(${count})` : ''}`;
      });
    }
  }

  updateFilters(target) {
    const { filter, value } = target.dataset;
    let subFilters = [];

    if (target.classList.contains(_.CLASS_ACTIVE_FILTER)) {
      this.filters[filter].push(value);
    } else {
      this.filters[filter] = this.filters[filter].filter((name) => name !== value);

      // Remove sub-filters
      if (target.classList.contains(_.CLASS_GROUP_FILTER)) {
        const filterOf = this.filtersContainer.querySelector(`div[data-filter-of='${value}']`);
        const filtersToReset = filterOf.querySelectorAll('.filter');

        [].forEach.call(filtersToReset, (element) => {
          this.filters[element.dataset.filter] = this.filters[element.dataset.filter]
            .filter((name) => name !== element.dataset.value);

          subFilters = this.filters[element.dataset.filter];
        });
        this.updateActiveFilterBlocks(subFilters);
      }
    }
  }

  updateActiveFilterBlocks(filters) {
    filters.forEach((filter) => {
      this.copyActiveFilters(filter);
    });
  }

  updateSectionsOpen(target, isOpen) {
    const { section, value } = target.dataset;

    if (isOpen) {
      this.sectionsOpen[section].push(value);
    } else {
      this.sectionsOpen[section] = this.sectionsOpen[section].filter((name) => name !== value);
    }
  }

  getSearchFilters(initialState) {
    const urlParameters = getQueryParams(window.location);
    const customQuery = window.location.pathname.split('/')[3] || '';

    urlParameters.establishment = '';
    urlParameters.schoolLevel = '';

    if (!initialState) {
      delete urlParameters.page;
    }

    return {
      parameters: { ...{ query: customQuery }, ...urlParameters, ...this.filters },
      isCustomQuery: customQuery.length > 0,
    };
  }

  focusLastTarget() {
    if (this.lastTarget !== null) {
      const target = AdvancedFilter.getFilterElement(this.lastTarget.dataset.value, 'filter', this.lastTarget.dataset.filter);
      target.focus();
    }
  }

  static toggleSeeMore(target) {
    target.textContent = target.textContent.trim() === _.SEE_MORE ? _.SEE_LESS : _.SEE_MORE;

    [].forEach.call(target.parentNode.querySelectorAll('.filter:nth-of-type(6)~.filter'), (element) => {
      AdvancedFilter.toggleClassName(element, 'filter--visible');
    });
  }

  static toggleSection(target) {
    AdvancedFilter.toggleClassName(target.parentNode.querySelector('.filters'), 'block--active');
    AdvancedFilter.toggleClassName(target, _.CLASS_SECTION_OPEN);
  }

  static toggleClassName(target, className) {
    if (target === null) {
      return;
    }

    target.classList.toggle(className);

    // Remove activeClass on sub-filters
    if (target.classList.contains(_.CLASS_GROUP_FILTER)) {
      [].forEach.call(target.parentNode.querySelectorAll('.filter-level .filter'), (element) => {
        element.classList.remove(_.CLASS_ACTIVE_FILTER);
      });
    }
  }

  static setAriaAttr(filter) {
    if (filter?.classList.contains('group-filter')) {
      filter.setAttribute('aria-expanded', filter.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
    }
  }

  static getFilterElement(value, keyAttr, keyVal) {
    return document.querySelector(`[data-${keyAttr}="${keyVal}"][data-value="${value}"]`);
  }

  static updateCookie(mappingSlugs, filters, cookieName) {
    const nbFilters = filters.length;
    const cookiesSelector = document.querySelector(`#${cookieName}`);
    const schoolLevelSelector = document.querySelector('#schoolLevels');

    if (nbFilters > 0 && mappingSlugs.length === 0) {
      const value = filters[nbFilters - 1];
      AdvancedFilter.createCustomCookie(cookieName, value, cookiesSelector);

      return;
    }

    if (nbFilters > 0) {
      // Get Last value selected by the user as an object
      // Example: { sixieme: 'label.school-levels.middle-school.sixth' }
      const mapping = Object.entries(mappingSlugs)
        .filter((item) => item[1] === filters[nbFilters - 1]);

      if (mapping.length > 0) {
        // Get translated value. Example: sixieme
        const value = mapping[0][0];

        AdvancedFilter.createCustomCookie(cookieName, value, cookiesSelector);

        if (cookieName === 'schoolLevel') {
          if (schoolLevelSelector) document.querySelector('#schoolLevels').value = filters;
        }
      }
    } else {
      Cookies.resetCustomCookies([cookieName]);

      if (cookiesSelector) document.querySelector(`#${cookieName}`).value = '';
      if (schoolLevelSelector) document.querySelector('#schoolLevels').value = '';
    }
  }

  static createCustomCookie(cookieName, value, cookiesSelector) {
    if (value) {
      // Set new value for the cookie
      Cookies.createCustomCookies([cookieName], value);
      if (cookiesSelector) document.querySelector(`#${cookieName}`).value = value;
    }
  }
}

export default AdvancedFilter;
