import { ajaxHTML } from '@global-js/utils/ajax';
import Lazy from '@global-js/Lazy';
import { enableTabs } from '@global-js/modules/tabs/tabs';
import { setSearchContext } from '@global-js/modules/theme/search';
import { toggleLoader } from '@global-js/modules/search/SearchResults';
import { enablePagination } from '../pagination';

let cookiesObject = {};

/**
 * @param {string} selector
 * @param category{string}
 * @param {function} callback
 * @private
 */
const _initPlugins = (selector, category, callback) => {
  enablePagination(selector, callback);

  setSearchContext({ cookies: cookiesObject });
  Lazy.init(`${selector} .lazy`, `${selector} .lazy-picture`);

  const container = document.querySelector(selector);
  document.dispatchEvent((new CustomEvent('refreshAddPlaylist', { detail: { container } })));
};

/**
 * @param {string} category
 * @param {string} selector
 * @param {int} page
 * @private
 */
const _getItems = (category, selector, page = 1) => {
  toggleLoader('show');

  ajaxHTML(`${window.location.origin}/ajax/mes-contenus/${category}?page=${page}`)
    .then((response) => response.text())
    .then((template) => {
      const element = document.querySelector(selector);

      if (template !== '') {
        element.innerHTML = template;
        _initPlugins(selector, category, _getItems);
      }

      toggleLoader('hide');
    })
    .catch((e) => console.log(e.message));
};

/**
 * @private
 */
const _init = () => {
  enableTabs('user-contents__content', true);
  enablePagination('#favorite', _getItems);
  enablePagination('#watchLater', _getItems);
  setSearchContext({ cookies: cookiesObject });
};

export const myContents = (cookies) => {
  if (!document.querySelector('.user-contents__content')) {
    return;
  }

  cookiesObject = cookies;

  _init();
  document.addEventListener('user:refresh:contents', _init, false);
};
