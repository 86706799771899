const hideSubmitButton = (id) => {
  const button = document.getElementById(`${id}`);

  if (button === null) {
    return;
  }
  button.addEventListener('click', () => {
    button.className = 'c-panel--hide';
  });
};

export default hideSubmitButton;
