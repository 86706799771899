/**
 * @param {string} id
 * @param {string} schoolLevel
 */
export const UserEvent = (id, schoolLevel) => {
  document.dispatchEvent(new CustomEvent('user:logged', {
    detail: { id, schoolLevel },
  }));
};

/**
 * @param {Object} data
 * @private
 */
export const dispatchEvents = (data) => {
  UserEvent(data?.userId, data?.schoolLevel);
};
