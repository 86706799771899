import { openPopup, closePopup } from '@global/organisms/popup/popup';
import { getCookies } from '../../../../../global/assets/scripts/utils/cookies';
import { isUserAbroad } from '../../../../../global/assets/scripts/modules/security/session/user';
import {
  isBackNavigation,
} from '../../../../../global/assets/scripts/modules/utils/isBackNavigation';

const CONFIG = {
  filterPopinBtn: '.filter_btn',
  filters: '.search__filters',
  filter: '.search__filter',
  nbActiveFilters: '.search__filter span',
  schoolLevels: '#search_schoolLevel',
  schoolLevelsInputs: '#search_schoolLevel input',
  thematics: '#search_thematic',
  thematicsInputs: '#search_thematic input',
  collections: '#search_content-types',
  collectionsInputs: '#search_content-types input',
  geoblocked: '.search__geoblocked',
  geoblockedInput: '.search__geoblocked input',
  activeFilters: '.search__active-filters ul',
  activeFiltersThematics: '.search__active-filters--thematics ul',
  activeFiltersSchoolLevel: '.search__active-filters--schoollevel ul',
  activeFiltersCollections: '#search_content-types ul',
  order: '#searchOrderBtn',
  orderInputs: '#search_order input',
  seeAllResult: '#seeAllResults',
  seeMoreBtn: '.slider__see-more',
  numberInputs: '.search__filters-number',
  resetBtn: '.search__filters__reset',
};

const ORDER_MESSAGE = {
  type: 'Tri : Type de contenus',
  relevance: 'Tri : Pertinence',
};

const SEARCH_QUERY_SESSION = 'lumni_search_query';
const SEARCH_ORDER_SESSION = 'lumni_search_order';

// Lock variable to prevent fetching data at page load
let lockInitPage = true;
let currentOrder = null;

/**
 * @param {HTMLElement} currentTarget
 * @private
 */
const _onClickFilter = ({ currentTarget }) => {
  // Store the next state of the filter
  const nextState = !(currentTarget.getAttribute('data-active') === 'true');

  // Reset all filters
  document.querySelectorAll(CONFIG.filter).forEach((filter) => {
    filter.setAttribute('data-active', 'false');
  });

  // Apply new state to the filter clicked
  currentTarget.setAttribute('data-active', nextState.toString());
};

/**
 * @private
 */
const _closeFiltersList = (event) => {
  const isExcludedElement = (
    event.target instanceof HTMLButtonElement
    || event.target instanceof HTMLAnchorElement
    || event.target instanceof HTMLInputElement
    || event.target instanceof HTMLLabelElement
  );

  if (!isExcludedElement) {
    document.querySelectorAll(CONFIG.filter).forEach((filter) => {
      filter.setAttribute('data-active', 'false');
    });
  }
};

/**
 * @param {HTMLElement} currentTarget
 * @private
 */
const _onClickOrder = ({ currentTarget }) => {
  const nextState = !(currentTarget.getAttribute('data-active') === 'true');
  currentTarget.setAttribute('data-active', nextState.toString());
};

/**
 * @param {MouseEvent} event
 * @private
 */
const _onClickSeeAllResult = (event) => {
  event.preventDefault();
  document.querySelector('#search_order_by_relevance').click();
};

/**
 * @param {HTMLElement} schoolLevels
 * @param {string} establishment
 * @private
 */
const _onChangeLevel = (schoolLevels, establishment) => {
  // Get group parent (primaire|college|lycee)
  const parent = schoolLevels.querySelector(`input[value="${ establishment }"]`);
  // Get the children
  const inputs = schoolLevels.querySelectorAll(`input[data-establishment="${ establishment }"]`);

  // Get nb inputs and nb inputs checked
  const nbInputs = inputs.length;
  const nbInputsChecked = [...inputs].filter((input) => input.checked).length;

  // Update state of the input parent
  parent.checked = nbInputs === nbInputsChecked && nbInputsChecked > 0;
  parent.indeterminate = nbInputs > nbInputsChecked && nbInputsChecked > 0;
};

/**
 * @param {HTMLElement} schoolLevels
 * @param {HTMLInputElement} currentTarget
 * @private
 */
const _onChangeEstablishment = (schoolLevels, currentTarget) => {
  const isChecked = currentTarget.checked;

  // Get children
  const inputs = schoolLevels.querySelectorAll(`input[data-establishment="${ currentTarget.value }"]`);

  // Update all children of a group with the state of the parent
  inputs.forEach((input) => {
    input.checked = isChecked;
  });
};

/**
 * @param {boolean} isFilteredByLevels
 * @private
 */
const _toggleThematics = (isFilteredByLevels) => {
  document
    .querySelectorAll(CONFIG.thematicsInputs)
    .forEach((input) => {
      input.parentNode.classList.toggle('hidden', isFilteredByLevels);
    });
};

/**
 * @param {[]} selectors
 * @private
 */
const _toggleFilteredThematics = (selectors) => {
  const thematics = document.querySelector(CONFIG.thematics);

  // Display all thematics for the levels checked (in selectors)
  thematics
    .querySelectorAll(selectors.join(', '))
    .forEach((input) => {
      input.parentNode.classList.remove('hidden');
    });

  // Uncheck hidden inputs
  thematics
    .querySelectorAll('.form-group.hidden')
    .forEach((formGroup) => {
      const input = formGroup.querySelector('input');
      input.checked = false;
    });
};

/**
 * @param {HTMLElement} schoolLevels
 * @private
 */
const _updateAllowedThematics = (schoolLevels) => {
  const selectors = [];
  const levels = [];

  // Store in an array the values of levels checked
  const inputsChecked = schoolLevels.querySelectorAll('input:checked');
  inputsChecked.forEach((input) => levels.push(input.value));

  // Build the selector to filter thematics by levels checked
  levels.forEach((level) => selectors.push(`input[data-levels*="${ level }"]`));

  const isFilteredByLevels = selectors.length > 0;

  // If at least 1 level is checked we hide all thematics
  // Else we display all thematics
  _toggleThematics(isFilteredByLevels);

  if (isFilteredByLevels) {
    // We display only the thematics available for the levels checked
    _toggleFilteredThematics(selectors);
  }
};

/**
 * @param {string} inputSelector
 * @param {string} filterSelector
 * @private
 */
const _toggleNbActiveFilter = (inputSelector, filterSelector) => {
  const nbActiveItems = document.querySelectorAll(`${ inputSelector }:checked`).length;
  const nbElement = document.querySelector(filterSelector)
    .parentNode.querySelector(CONFIG.nbActiveFilters);

  nbElement.setAttribute('data-count', `${ nbActiveItems }`);
  nbElement.innerHTML = `(${ nbActiveItems })`;
};

/**
 * @private
 */
const _updateNbActiveFilters = () => {
  _toggleNbActiveFilter(CONFIG.schoolLevelsInputs, CONFIG.schoolLevels);
  _toggleNbActiveFilter(CONFIG.thematicsInputs, CONFIG.thematics);
  const nbActiveSchoolLevels = document.querySelectorAll(`${ CONFIG.schoolLevelsInputs }:checked`).length;
  const nbActiveThematics = document.querySelectorAll(`${ CONFIG.thematicsInputs }:checked`).length;
  const nbActiveCollections = document.querySelectorAll(`${ CONFIG.collectionsInputs }:checked`).length;
  const nbActiveGeoblocked = isUserAbroad() && document.querySelector(`${ CONFIG.geoblockedInput }:checked`) ? 1 : 0;
  const nbActiveElements = document.querySelectorAll(`${ CONFIG.numberInputs }`);
  const totalActiveFilters = nbActiveSchoolLevels + nbActiveThematics
    + nbActiveCollections + nbActiveGeoblocked;

  if (totalActiveFilters > 0) {
    nbActiveElements.forEach((input) => {
      input.classList.add('active');
      input.innerHTML = totalActiveFilters;
    });
  } else {
    nbActiveElements.forEach((input) => {
      input.classList.remove('active');
      input.innerHTML = '';
    });
  }
};

/**
 * @private
 */
const _dispatchFiltersChange = () => {
  if (lockInitPage) return;

  document.dispatchEvent(new CustomEvent('Search:filters:change'));
};

/**
 * @private
 */
const _cancelFilter = () => {
  const cancelBtns = document.querySelectorAll('.cancel-btn');
  const activeFilters = document.querySelectorAll(`${ CONFIG.activeFilters }, ${ CONFIG.activeFiltersThematics }, ${ CONFIG.activeFiltersSchoolLevel }`);
  const thematics = document.querySelector(CONFIG.thematics);

  cancelBtns.forEach((cancelBtn) => {
    cancelBtn.addEventListener('click', () => {
      cancelBtn.parentNode.querySelector('label').click();

      if (activeFilters.innerHTML.trim() === '') {
        thematics.parentNode.querySelector('button.search__filter').focus();
      } else {
        activeFilters.querySelector('li:first-child').focus();
      }
    }, false);
  });
};

/**
 * @private
 */
const _resetFilters = () => {
  const activeFilters = document.querySelectorAll(`${ CONFIG.activeFilters }, ${ CONFIG.activeFiltersThematics }, ${ CONFIG.activeFiltersSchoolLevel }`);
  const inputs = document.querySelectorAll(`${ CONFIG.filters } input:checked`);

  inputs.forEach((input) => {
    input.checked = false;
  });

  activeFilters.forEach((activeFilter) => {
    activeFilter.innerHTML = '';
  });

  _dispatchFiltersChange();
  _updateNbActiveFilters();
};

/**
 * @private
 */
const _displayActiveFilters = () => {
  const activeFilters = document.querySelectorAll(CONFIG.activeFilters);
  activeFilters.forEach((filter) => {
    filter.innerHTML = '';
  });

  document.querySelectorAll(`${ CONFIG.activeFiltersThematics }, ${ CONFIG.activeFiltersSchoolLevel }, ${ CONFIG.activeFiltersCollections }`).forEach((container) => {
    container.innerHTML = '';
  });

  const inputs = document.querySelectorAll(`${ CONFIG.filters } input:checked`);

  inputs.forEach((input) => {
    if (input.id.includes('not_geoblocked') && !isUserAbroad()) {
      return;
    }

    input.ariaChecked = true;
    const clone = input.parentNode.cloneNode(true);
    const cloneLabel = clone.querySelector('label').innerText;
    clone.classList.remove('form-group', 'dropdown--item');
    clone.classList.add('search__item--cloned');
    clone.setAttribute('tabindex', '0');

    if (clone.classList.contains('filter__content-type')) {
      const typeImg = clone.querySelector('img');
      typeImg.remove();
    }

    const cancelBtn = document.createElement('button');
    cancelBtn.className = 'cancel-btn';
    cancelBtn.ariaLabel = `Supprimer le filtre ${ cloneLabel }`;
    clone.appendChild(cancelBtn);

    if (input.id.includes('not_geoblocked')) {
      clone.classList.remove('search__filters__list__item', 'search__geoblocked');
    }

    let activeFiltersPopin;
    if (input.id.includes('thematic')) {
      activeFiltersPopin = document.querySelector(CONFIG.activeFiltersThematics);
    } else if (input.id.includes('schoolLevel')) {
      activeFiltersPopin = document.querySelector(CONFIG.activeFiltersSchoolLevel);
    } else if (input.id.includes('type')) {
      activeFiltersPopin = document.querySelector(CONFIG.activeFiltersCollections);
    }

    if (activeFiltersPopin) {
      activeFiltersPopin.appendChild(clone.cloneNode(true));
    }

    activeFilters.forEach((filter) => {
      filter.appendChild(clone.cloneNode(true));
    });
  });

  // Update count of active filters
  _updateNbActiveFilters();

  // Dispatch event to refresh results
  _dispatchFiltersChange();

  // Deal with focus when a filter is removed
  _cancelFilter();
};

/**
 * @param {HTMLInputElement} currentTarget
 * @private
 */
const _onChangeSchoolLevel = ({ currentTarget }) => {
  const { group, establishment } = currentTarget.dataset;
  const schoolLevels = document.querySelector(CONFIG.schoolLevels);

  // Case: click on sub item
  if (establishment) _onChangeLevel(schoolLevels, establishment);

  // Case: click on group
  if (group) _onChangeEstablishment(schoolLevels, currentTarget);

  // Display thematics for the selected schoolLevels
  _updateAllowedThematics(schoolLevels);

  // Display active filters
  _displayActiveFilters();
};

/**
 * @private
 */
const _onChangeThematic = () => {
  _displayActiveFilters();
};

/**
 * @private
 */
const _onChangeGeoblocked = () => {
  _displayActiveFilters();
};

/**
 * @param {HTMLInputElement} currentTarget
 * @private
 */
const _onChangeOrder = ({ currentTarget }) => {
  const { value } = currentTarget;
  document.querySelector(`${ CONFIG.order } span`).innerHTML = ORDER_MESSAGE[value];

  if (currentOrder === value) return;

  currentOrder = value;
  sessionStorage.setItem(SEARCH_ORDER_SESSION, currentOrder);

  // Dispatch event to refresh results
  _dispatchFiltersChange();
};

/**
 * @param {boolean} closeFilter
 * @param {boolean} closeOrder
 * @private
 */
const _closeFilters = (closeFilter, closeOrder) => {
  if (closeFilter) {
    document.querySelectorAll(CONFIG.filter).forEach((filter) => {
      filter.setAttribute('data-active', 'false');
    });
  }

  if (closeOrder) {
    document.querySelector(CONFIG.order)?.setAttribute('data-active', 'false');
  }
};

/**
 * @param {HTMLElement} target
 * @private
 */
const _onClickOutsideOfDropdown = ({ target }) => {
  const filters = target.closest('.search__filters');
  const order = target.closest('#searchOrderBtn');

  _closeFilters(filters === null, order === null);
};

/**
 * @param {Event} event
 * @private
 */
const _onEscape = (event) => {
  if (event.key === 'Escape') {
    _closeFilters();
  }
};

/**
 * @param {string} inputSelector
 * @param {string} filterValue
 * @private
 */
const _activateFilter = (inputSelector, filterValue) => {
  document.querySelector(`${ inputSelector }[value='${ filterValue }']`).click();
};

/**
 * @param {string|null} establishment
 * @param {string|null} schoolLevel
 * @param geoblocked
 * @private
 */
const _activateFilters = (establishment, schoolLevel) => {
  if (establishment && !schoolLevel) {
    // Activate all school level filters if user comes from an Establishment page
    _activateFilter(CONFIG.schoolLevelsInputs, establishment);
  } else if (schoolLevel) {
    // Activate school level filter if user comes from a school level page
    _activateFilter(CONFIG.schoolLevelsInputs, schoolLevel);
  }

  // Activate geoblocked filter if the user is abroad
  if (isUserAbroad()) {
    _displayActiveFilters();
  }
};

/**
 * @private
 */
const _initFilters = () => {
  const isBack = isBackNavigation();

  // Get query from last search
  const lastQuery = isBack ? sessionStorage.getItem(SEARCH_QUERY_SESSION) : null;

  if (lastQuery !== null && lastQuery.length > 0) {
    _updateAllowedThematics(document.querySelector(CONFIG.schoolLevels));
    _displayActiveFilters();
  } else {
    const cookies = getCookies(['establishment', 'schoolLevel', 'thematic']);
    const { establishment, schoolLevel } = cookies;
    _activateFilters(establishment, schoolLevel);
  }

  // Dispatch event to refresh results
  _dispatchFiltersChange();

  // Unlock to refresh results with activated filters
  lockInitPage = false;

  if (isBack) {
    _dispatchFiltersChange();
  }
};

/**
 * @private
 */
const _onSearchRefresh = () => {
  // seeAllResult Btn
  document.querySelector(CONFIG.seeAllResult)?.addEventListener('click', _onClickSeeAllResult, false);

  // Order Btn
  document.querySelector(CONFIG.order)?.addEventListener('click', _onClickOrder, false);

  // Order inputs
  document.querySelectorAll(CONFIG.orderInputs).forEach((input) => {
    input.addEventListener('change', _onChangeOrder, false);
  });

  // See more Btn
  document.querySelectorAll(CONFIG.seeMoreBtn).forEach((input) => {
    input.addEventListener('click', _onClickSeeAllResult, false);
  });
};

const _openPopin = () => {
  const popin = document.querySelector('.popin_filter--search');
  if (popin.dataset.active === 'true') return;
  document.querySelector('body').classList.add('body-blocked');
  openPopup(popin);
};

const _closePopin = () => {
  const popin = document.querySelector('.popin_filter--search');
  if (popin.dataset.active === 'false') return;
  document.querySelector('body').classList.remove('body-blocked');
  closePopup(popin);
};

/**
 * @constructor
 */
export const Filters = () => {
  // close filters list when click on modal
  document.querySelector(CONFIG.filters).addEventListener('click', _closeFiltersList.bind(null), false);

  // reset all filters
  document.querySelector(CONFIG.resetBtn).addEventListener('click', _resetFilters, false);

  // hide popin filters
  document.querySelector('.search__btn__container button').addEventListener('click', _closePopin, false);

  // display popin filters
  document.querySelectorAll(CONFIG.filterPopinBtn).forEach((btn) => {
    btn.addEventListener('click', _openPopin, false);
  });

  // Filters Button
  document.querySelectorAll(CONFIG.filter).forEach((filter) => {
    filter.addEventListener('click', _onClickFilter, false);
  });

  // SchoolLevel inputs
  document.querySelectorAll(CONFIG.schoolLevelsInputs).forEach((input) => {
    input.addEventListener('change', _onChangeSchoolLevel, false);
  });

  // Thematic inputs
  document.querySelectorAll(CONFIG.thematicsInputs).forEach((input) => {
    input.addEventListener('change', _onChangeThematic, false);
  });

  document.querySelectorAll(CONFIG.collectionsInputs).forEach((input) => {
    input.addEventListener('change', _onChangeThematic, false);
  });

  // Geoblocked input
  document.querySelector(CONFIG.geoblockedInput).addEventListener('change', _onChangeGeoblocked, false);

  document.querySelector('body').addEventListener('click', _onClickOutsideOfDropdown, false);
  document.querySelector('body').addEventListener('keyup', _onEscape, false);

  document.addEventListener('Search:refresh', _onSearchRefresh, false);

  // Init filters with values from cookies
  _initFilters();
};
