// Lumni
import QuizExam from '@global-js/QuizExam';
import { filterContentWithSchoolLevel } from '@global-js/utils/filters';
import { updateSchoolLevelPopin } from '@v2/organisms/popup/back_to_school/popup_back_to_school';
import ButtonAction from '@lumni/molecules/button_action/button_action';
import ButtonPlaylist from '@lumni/molecules/button_action/button_playlist';
import PopinAccompagny from '@v2/organisms/popup/accompany/popin_accompany';
import { SetIsUserAbroad } from '@global-js/modules/localization/SetIsUserAbroad';
import { sameSubject } from '@global-js/modules/same-subject/same-subject';
import { myHistory } from '@v2/pages/user/my_history/user_my_history';
import { PopupLocalization } from '../../../v2/components/organisms/popup/localization/popup_localization';
import { LoginPopin } from '../../../projects/lumni/components/molecules/security/popin/popin';
import { SliderV2 } from '../../../v2/components/organisms/slider/v2/slider';
import { Search } from '../../../v2/components/pages/search/search';

// V2
import { Base } from './modules/base';
import { isConnected, subDomain } from './modules/security/session/user';
import { RestrictedCards, noResposiveGameCards } from '../../../v2/components/molecules/card/restricted_cards';
import { FormLogin } from '../../../v2/components/pages/security/v2/login';
import { FormRegistration } from '../../../v2/components/pages/security/v2/registration';
import { FormForgotPassword } from '../../../v2/components/pages/security/v2/forgot_password';
import { FormResetPassword } from '../../../v2/components/pages/security/v2/reset_password';
import {
  FormBannerNL,
  trackingBannerNl,
  checkUserLumniNlRegistered,
  neswletterConnected,
} from '../../../v2/components/organisms/banner/newsletter/banner_newsletter';
import { displayWarningRegistrationEmailExists } from '../../../v2/components/organisms/warning/warning';
import { enablePlayerOnfullwidth, hasInterlayer } from '../../../v2/components/pages/content/content';
import { archiveFilterBySubject } from '../../../v2/components/pages/archive/v2/archive';
import { Social } from '../../components/molecules/social/social';
import { Popup } from '../../../v2/components/organisms/popup/popup';
import SavedItems from '../../../projects/lumni/assets/scripts/favorite/SavedItems';
import ViewedItems from '../../../projects/lumni/assets/scripts/favorite/ViewedItems';
import { pdfTracking } from '../../../v2/components/molecules/description/article/pdf';
import { trackingSliderImpression } from '../../../v2/components/organisms/slider/v2/trackingSliderImpression';
import { getCookie, checkInterval } from './modules/tracking/Usabilla/cookiesTracking';
import { SearchBeta } from '../../../v2/components/pages/search/v2/search';
import { quizIos } from '../../../v2/components/organisms/quiz/quiz_ios';
import { hiddenIframes, accessibilityWysiwygLinks } from '../../../projects/lumni/assets/scripts/accessibility/accessibility';
import { SearchPager } from '../../../v2/components/pages/search/v2/pager';
import { Suggestion } from '../../../v2/components/pages/search/v2/suggestion';
import HeaderSlideMenu from '../../../v2/components/organisms/header/header-level/header-level';
import { initCardPreview } from '../../../v2/components/molecules/card/card_preview/card_preview';
import { Pagination } from '../../../v2/components/molecules/pagination/pagination';
import { showBubbles } from '../../../v2/components/molecules/chat_bubble/chat_bubble';
import { cardInfoLumniz } from '../../../v2/components/molecules/card/card_lumniz_info/card_lumniz_info';
import { successCard } from '../../../v2/components/molecules/card/card_success/card_success';
import { featuredCard } from '../../../v2/components/molecules/card/card_featured/card_featured';
import { userRanking } from '../../../v2/components/pages/user/my_profil/userRanking';

import { btnLumniz } from '../../../v2/components/molecules/button/btn_lumniz/btn_lumniz';
import { ThematicTitleEmoji } from '../../../v2/components/pages/thematic/thematic/thematic_title';
import { initializeTabProfile } from '../../../v2/components/organisms/incitation_profile/incitation_profile';

import { slideToCurentLevel } from '../../../v2/components/pages/user/my_profil/slideToCurrentLevel';
import { displayBubble } from '../../../v2/components/organisms/incentive_bubble/incentive_bubble';
import PopinAvatar from '../../../v2/components/organisms/popup/avatar/popin__avatar';
import { createCookie, removeCookie } from './utils/cookies';

const STYLEGUIDE = 'styleguide';
const ENV_PROD = 'production';

/**
 * @param {Object} cookies
 * @constructor
 */
export const AppLumni = (cookies) => {
  if (typeof process !== 'undefined' && process.env.NODE_ENV === ENV_PROD && subDomain !== STYLEGUIDE) {
    SetIsUserAbroad();
  }

  Base(cookies);
  SliderV2();

  if (!document.querySelector('.security-container')) {
    window.enableVideoCardPreview = initCardPreview;
  }

  updateSchoolLevelPopin(isConnected());

  if (document.querySelector('.slider_card__success')) {
    successCard();
  }

  // Get Quiz Exam based on cookies/item
  if (document.querySelector('#quizExam') !== null) {
    new QuizExam(cookies).setQuizExam();
  }

  if (document.querySelector('#containerItems')) {
    filterContentWithSchoolLevel();
  }

  new ButtonPlaylist(isConnected()).init();
  new ButtonAction(isConnected()).init();

  LoginPopin();

  if (document.querySelector('.popin-accompany')) {
    new PopinAccompagny().init();
  }

  if (document.querySelector('.popup--localization')) {
    PopupLocalization();
  }

  if (document.querySelector('#resume')) {
    myHistory();
  }

  RestrictedCards();

  noResposiveGameCards();

  if (document.querySelector('.ckeditor-sameSubject')) {
    sameSubject(cookies);
  }

  if (document.querySelector('.formLoginV2')) {
    FormLogin();
  }

  if (document.querySelector('.formRegistrationV2')) {
    FormRegistration();
  }

  if (document.querySelector('.formForgotPasswordV2')) {
    FormForgotPassword();
  }

  if (document.querySelector('.formResetPasswordV2')) {
    FormResetPassword();
  }

  if (document.querySelector('.banner-nl__form')) {
    FormBannerNL();
    checkUserLumniNlRegistered();
    trackingBannerNl();
  }

  if (document.querySelector('.slider__slide')) {
    trackingSliderImpression();
  }

  if (document.querySelector('#warningEmailExists')) {
    displayWarningRegistrationEmailExists();
  }

  if (document.querySelector('.wrapper--grid-playlist')) {
    window.displayFullWidthPlayer = enablePlayerOnfullwidth;
  }

  if (document.querySelector('.incitation-profile')) {
    initializeTabProfile();
  }

  if (document.querySelector('.archive-content--v2')) {
    archiveFilterBySubject();
  }

  if (document.querySelector('.social__link')) {
    Social();
  }

  if (document.querySelector('.card--card-button .cardButton--pdf')) {
    pdfTracking();
  }

  if (window.LUMNI_CONTEXT?.route === 'search') {
    if (window.ENABLE_SEARCH_BETA) {
      SearchBeta();
    } else {
      Search();
    }
  }

  if (getCookie('lumni_user_first_time')) {
    checkInterval('lumni_user_first_time', 'registration');
  }

  if (getCookie('lumni_user_time_connect')) {
    checkInterval('lumni_user_time_connect', 'login');
  }

  if (document.querySelector('#quiz')) {
    quizIos();
  }

  if (document.querySelector('.search__filters')) {
    SearchPager();
    Suggestion();
  }

  Popup();

  (new SavedItems()).init();
  (new ViewedItems()).init();

  hiddenIframes();
  if (document.querySelector('.cke_editable')) {
    accessibilityWysiwygLinks();
  }

  if (document.querySelector('.slider--header-level')) {
    (new HeaderSlideMenu()).init();
  }

  if (document.querySelector('.pagerfanta-v3')) {
    Pagination();
  }

  if (document.querySelector('.display-chat')) {
    showBubbles();
  }

  if (document.querySelector('.card-lumniz')) {
    cardInfoLumniz();
  }

  if (document.querySelector('.btn_lumniz')) {
    btnLumniz();
  }

  if (document.querySelector('.wrapper--grid-playlist')) {
    hasInterlayer();
  }

  if (document.querySelector('.bubble--newsletter')) {
    neswletterConnected();
  }

  if (document.querySelector('.content__title-v2.crooked-title-multi')) {
    ThematicTitleEmoji();
  }

  if (document.getElementById('cards-tab')) {
    slideToCurentLevel();
  }

  if (document.querySelector('.incentive_bubble')) {
    displayBubble();
  }

  if (document.querySelector('.featured__segment')) {
    featuredCard();
  }

  if (document.querySelector('#rank')) {
    userRanking();
  }

  if (document.querySelector('.avatar__edit')) {
    new PopinAvatar().init();
  }

  // set the referrer on user security routes
  if (
    (window.location.href.indexOf('inscription') > -1)
    || (window.location.href.indexOf('connexion') > -1)
    || (window.location.href.indexOf('mot-de-passe-oublie') > -1)
    || (window.location.href.indexOf('reinitialiser-le-mot-de-passe') > -1)
  ) {
    createCookie('referrer', document.referrer, 1);
  } else {
    removeCookie('referrer');
  }
};
