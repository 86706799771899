export default {
  'avatar-1.alt': 'Personnage avec les cheveux bruns. Modifier votre avatar',
  'avatar-2.alt': 'Personnage avec une caquette bleue. Modifier votre avatar',
  'avatar-3.alt': 'Personnage avec un bob blanc. Modifier votre avatar',
  'avatar-4.alt': 'Personnage avec une tenue de cosmonaute. Modifier votre avatar',
  'avatar-5.alt': 'Personnage avec un chapeau de sorcière. Modifier votre avatar',
  'avatar-6.alt': 'Personnage avec un chapeau mexicain. Modifier votre avatar',
  'avatar-7.alt': 'Personnage avec les cheveux blonds et des lunettes. Modifier votre avatar',
  'avatar-8.alt': 'Personnage avec les cheveux bruns et des boucles d’oreilles. Modifier votre avatar',
  'avatar-9.alt': 'Personnage avec les cheveux roses et des boucles d’oreilles. Modifier votre avatar',
  'avatar-10.alt': 'Personnage avec un bonnet bleu. Modifier votre avatar',
  'avatar-11.alt': 'Personnage avec une tenue de chat. Modifier votre avatar',
  'avatar-12.alt': 'Personnage avec un chapeau de sorcière et une boucle d’oreille. Modifier votre avatar',
  'avatar-13.alt': 'Fantôme rigolo. Modifier votre avatar',
  'avatar-14.alt': 'Fusée. Modifier votre avatar',
  'avatar-15.alt': 'Fleur de cerisier. Modifier votre avatar',
  'avatar-16.alt': 'Citrouille. Modifier votre avatar',
  'avatar-17.alt': 'Raquette et balle de ping-pong. Modifier votre avatar',
  'avatar-18.alt': 'Ballon de football. Modifier votre avatar',
  'avatar-19.alt': 'Arc-en-ciel. Modifier votre avatar',
  'avatar-20.alt': 'Livre. Modifier votre avatar',
  'avatar-21.alt': 'Rouge à lèvres. Modifier votre avatar',
  'avatar-22.alt': 'Coeur. Modifier votre avatar',
  'avatar-23.alt': 'Manette de console. Modifier votre avatar',
  'avatar-24.alt': 'Couronne. Modifier votre avatar',
};
