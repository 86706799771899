import { SCHOOLLEVELS } from '@lumni/organisms/filter/search/filter_constants';
import { getCookies } from './cookies';

const ACTIVE_FILTER = 'filter--active';

/**
 * @param {HTMLElement} container
 * @param {HTMLElement} filter
 * @param {boolean} toggleMode
 */
const addActiveFilter = (container, filter, toggleMode = false) => {
  if (toggleMode) {
    filter.classList.toggle(ACTIVE_FILTER);
    return;
  }

  const activeFilter = container.querySelector('.filter--active');

  if (activeFilter) {
    activeFilter.classList.remove(ACTIVE_FILTER);
  }

  filter.classList.add(ACTIVE_FILTER);
};

/**
 * @param {string} rubricTitle
 */
const toggleChapter = (rubricTitle) => {
  [].forEach.call(document.querySelectorAll('.slick-slide'), (chapter) => {
    const chapterTitle = chapter.querySelector('p').innerHTML;
    if (rubricTitle === chapterTitle) {
      chapter.remove();
    }
  });

  const allChapters = document.querySelectorAll('.chapter:not(.hidden)');
  const selectorChapter = document.querySelector('.selector-chapter');

  if (allChapters.length === 0 && selectorChapter !== null) {
    selectorChapter.classList.add('hidden');
  }

  if (allChapters.length < 5) {
    [].forEach.call(document.querySelectorAll('.slick-arrow'), (arrow) => {
      arrow.remove();
    });
  }
};

const toggleRubrics = () => {
  [].forEach.call(document.querySelectorAll('.rubrique'), (rubric) => {
    rubric.closest('.wrapper').classList.remove('hidden');
    const cards = rubric.querySelectorAll('.cart:not(.hidden), .card--capsule:not(.hidden)');

    if (cards.length === 0) {
      const rubricTitle = rubric.querySelector('.rubrique-title > h2 > span');

      rubric.closest('.wrapper').classList.add('hidden');

      if (rubricTitle !== null) {
        toggleChapter(rubricTitle.innerHTML);
      }
    }
  });
};

/**
 * @param {string} rubricTitle
 */
const toggleChapterV2 = (rubricTitle) => {
  [].forEach.call(document.querySelectorAll('.chapter-item'), (chapter) => {
    const chapterTitle = chapter.querySelector('p').innerHTML;
    if (rubricTitle === chapterTitle) {
      chapter.remove();
    }
  });

  const allChapters = document.querySelectorAll('.chapter-item:not(.hidden)');
  const selectorChapter = document.querySelector('.slider--chapter');

  if (allChapters.length === 0 && selectorChapter !== null) {
    selectorChapter.classList.add('hidden');
  }
};

const toggleRubricsV2 = () => {
  [].forEach.call(document.querySelectorAll('.list'), (rubric) => {
    const cards = rubric.querySelectorAll('.card:not(.hidden)');

    if (cards.length === 0) {
      const rubricTitle = rubric.querySelector('h2');

      rubric.classList.add('hidden');

      if (rubricTitle !== null) {
        toggleChapterV2(rubricTitle.innerHTML.trim());
      }
    }
  });
};

/**
 * @param {Array} filters
 * @param {NodeListOf} cards
 */
const toggleCards = (filters, cards) => {
  const typeFilters = filters;

  [].forEach.call(cards, (card) => {
    // Get data attributes
    const { type, audio } = card.dataset;

    // Handle card video as audio
    const isAudio = (audio || 'false') === 'true';
    const typeCard = isAudio ? 'audio' : type;

    // Hide all card by default
    card.classList.add('hidden');

    if (typeFilters.length > 0) {
      if (typeFilters.includes(typeCard)
        && (filters.length === typeFilters.length)) {
        card.classList.remove('hidden');
      }
    } else if ((filters.length === 1 && filters.includes('all'))) {
      card.classList.remove('hidden');
    }
  });
};

/**
 * @param {Element} container
 */
const toggleContainer = (container) => {
  if (!container) {
    return;
  }

  const containers = container.querySelectorAll('.list:not(.hidden)');

  if (containers.length === 0) {
    container.classList.add('hidden');
  }
};

export const filterContentWithSchoolLevel = () => {
  const container = document.querySelector('#containerItems');
  const cards = document.querySelectorAll('.card');
  const { schoolLevel } = getCookies();

  if (!schoolLevel) {
    return;
  }

  [].forEach.call(cards, (card) => {
    let { filters } = card.dataset;

    filters = filters || '';
    filters = filters.split(',');

    card.classList.add('hidden');

    if (filters.includes(SCHOOLLEVELS[schoolLevel])) {
      card.classList.remove('hidden');
    }
  });

  toggleRubricsV2();
  toggleContainer(container);
};

/**
 * @param {HTMLElement} container
 * @param {HTMLElement} filter
 * @param {boolean} toggleMode
 * @private
 */
export const onClickFilter = (container, filter, toggleMode) => {
  const parent = filter.parentNode;
  let filters = [];

  if (parent.hasAttribute('href')) {
    return;
  }

  addActiveFilter(container, filter, toggleMode);

  const parentElement = parent.closest('.filters') || parent.closest('.filters-v2');
  parentElement.querySelectorAll('.filter--active').forEach((filterElement) => {
    filters.push(filterElement.dataset.type);
  });

  filters = filters.join(',').split(',');

  const cards = document.querySelector('.card')
    ? document.querySelectorAll('.card')
    : document.querySelectorAll('.cart, .card--capsule');

  toggleCards(filters, cards);
  toggleRubrics();
};
