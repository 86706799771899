import Lazy from './Lazy';
import { ajaxHTML } from './utils/ajax';
import { getParameters } from './utils/cookiesParameters';
import { getChapter } from './utils/chapter';
import { isConnected } from './modules/security/session/user';

class Playlist {
  /**
   * @param {Object} cookies
   */
  constructor(cookies) {
    this.cookies = cookies;
    this.playlistVideoSelector = '#playlistVideo';
    this.playlistQuizSelector = '#playlistQuiz';
    this.playlistArticleSelector = '#playlistArticle';
    this.playlistContentSelector = '#playlistContent';
    this.titleContainer = '.block-video-four--title';
    this.baseUrl = window.location.origin;
    this.path = '/ajax/playlist';
    this.userPath = '/ajax/user/playlist';
  }

  setPlaylist() {
    const url = this.getUrl();

    if (url === null) {
      return;
    }

    ajaxHTML(url)
      .then((response) => response.text())
      .then((template) => {
        const playlistVideo = document.querySelector(this.playlistVideoSelector);
        const playlistQuiz = document.querySelector(this.playlistQuizSelector);
        const playlistArticle = document.querySelector(this.playlistArticleSelector);
        const playlistContent = document.querySelector(this.playlistContentSelector);
        const playlistContainer = playlistVideo || playlistQuiz || playlistArticle || null;

        let container;

        if (playlistContainer !== null) {
          const idElement = playlistContainer.getAttribute('id');
          playlistContainer.innerHTML += template;

          const titleContainer = document.querySelector(this.titleContainer);
          if (titleContainer !== null) {
            titleContainer.classList.remove('hidden');
          }

          Lazy.init(`#${idElement} .lazy`, `#${idElement} .lazy-picture`);
          container = playlistContainer;

          if (playlistContent !== null) {
            playlistContent.remove();
          }
        } else {
          // For Content Playlist
          playlistContent.querySelector('.container').innerHTML = template;
          Lazy.init('#playlistContent .lazy', '#playlistContent .lazy-picture');
          playlistContent.classList.remove('hidden');
          container = playlistContent;
        }

        Playlist.dispatchEvents(container);
      })
      .catch((e) => console.log(e.message));
  }

  /**
   * @return {string|null}
   */
  getUrl() {
    const { itemSlug, itemType, schoolLevel } = getParameters(this.cookies);
    const { establishment, thematic } = this.cookies || '';
    const context = `${establishment}/${schoolLevel}/${thematic}`;

    if (typeof context === 'undefined' || typeof itemType === 'undefined' || typeof itemSlug === 'undefined') {
      return null;
    }

    const pathUrl = isConnected() ? this.userPath : this.path;
    let url = `${this.baseUrl}${pathUrl}/${context}/${itemType}/${itemSlug}`;

    let chapter = getChapter(schoolLevel, thematic);
    chapter = chapter.slug || '';

    if (chapter.length > 0) {
      url += `?chapter=${chapter}`;
    }

    if (window.location.hash) {
      const param = {};
      const hash = window.location.hash.substr(1);
      const splited = hash.split('&');
      for (let i = 0; i < splited.length; i += 1) {
        const item = splited[i].split('=');
        param[item[0]] = decodeURIComponent(item[1]);
      }

      if (param.containerType !== undefined && param.containerSlug !== undefined) {
        if (param.containerType === 'folder') {
          url = `${this.baseUrl}${pathUrl}/${establishment}/${schoolLevel}/${thematic}/folder/${param.containerSlug}/${itemType}/${itemSlug}`;
        } else if (param.containerType !== null && param.containerType !== 'chapter') {
          url = `${this.baseUrl}${pathUrl}/${establishment}/${schoolLevel}/${thematic}/${param.containerType}/${param.containerSlug}/video/${itemSlug}`;
        }
      }
    }

    return url;
  }

  static dispatchEvents(container) {
    document.dispatchEvent((new CustomEvent('refreshAddPlaylist', { detail: { container } })));
    document.dispatchEvent((new CustomEvent('refreshCardRedirection', { detail: { container } })));
    document.dispatchEvent((new CustomEvent('blockedVideosInList', { detail: { container } })));
  }
}

export default Playlist;
