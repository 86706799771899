import { getProgress } from '../../../assets/scripts/modules/historic/getVideoProgress';
import { getPageTypeValue, getPreviousPageType } from '../../../assets/scripts/modules/tracking/Piano/utils/pageType';
import { _getLastSegment, HOMEPAGE } from '../../../assets/scripts/modules/tracking/Piano/events/load/base';
import { isConnected, getId } from '../../../assets/scripts/modules/security/session/user';

let lockDispatchEvent = false;
let isResuming = false;

/**
 * @See http://magneto.build.ftven.net/#/options/
 */
const OPTIONS = {
  intro: false,
  adRequireFocus: false,
  showAd: false,
  pip: true,
  origin: 'france.tv',
  preload: true,
  mute: false, // to remove mute of card preview
  webservices: {
    gateway: window.PLAYER_WEBSERVICE || 'https://k7.ftven.fr/videos/',
  },
  userLoggedIn: isConnected(),
  publicId: isConnected() ? getId() : null,
};

/**
 * @See https://confluence.ftven.net/pages/viewpage.action?pageId=167849977
 * @returns {{analytics: (string), estat: (string)}}
 */
const getConsents = () => {
  const analyticsOptOut = window.localStorage.getItem('optoutAT') === 'true' ? 'optout' : null;
  const analyticsOptIn = window.localStorage.getItem('consent_at') === 'true' && !analyticsOptOut ? 'optin' : null;

  return {
    estat: window.localStorage.getItem('consent_estat') === 'true' ? 'optin' : 'exempted',
    analytics: analyticsOptOut || analyticsOptIn || 'exempt',
    npaw: window.localStorage.getItem('consent_youbora') === 'true' ? 'optin' : 'exempted',
  };
};

const VIDEO_PLAYBACK_RECORDING_FREQUENCY_IN_SECONDS = 5;

const getTrackingOptions = () => {
  const referrer = document.referrer ? new URL(document.referrer).pathname : '';
  const pageType = getPageTypeValue(window.LUMNI_CONTEXT);
  const previousPageType = getPreviousPageType();
  const cardInfos = localStorage.getItem('lumni_video_card_info');
  const tracking = {
    page: pageType === HOMEPAGE ? HOMEPAGE : _getLastSegment(window.location.pathname),
    pageType,
    pageProvenance: previousPageType === HOMEPAGE ? HOMEPAGE : _getLastSegment(referrer),
    pageProvenanceType: previousPageType,
  };

  if (cardInfos) {
    const { position, zone } = JSON.parse(cardInfos);
    tracking.zoneProvenance = zone;
    tracking.positionVignette = position;
  }

  return tracking;
};

class PlayerJs {
  constructor(isContentProtected, isUserAuthenticated) {
    this.element = document.querySelector('#player');
    this.ckPlayers = document.querySelectorAll('.player--ckeditor');
    this.player = document.querySelector('.player');
    this.cancelBtn = document.querySelector('.cancel-button');
    this.partnersContainer = document.querySelector('.player__partner');
    this.popinSSOSelector = '.popin-content';
    this.nextVideoBtn = null;
    this.rightsValid = true;
    this.durationTimer = 3000;
    this.durationTimerPartner = 150;
    this.isContentProtected = isContentProtected;
    this.isUserAuthenticated = isUserAuthenticated;
    this.timer = null;
    this.isTimer = false;
    this.replay = false;
    this.timeoutPlayer = null;
    this.isPlayling = false;
    this.Magnetoscope = window.magnetoscope;
    this.lastCurrentTime = -1;
  }

  init() {
    if (this.ckPlayers.length > 0) {
      [].forEach.call(this.ckPlayers, (ckPlayer) => {
        this.play(ckPlayer, false);
      });
    }

    if (this.element === null) {
      return;
    }

    this.nextVideoBtn = this.element.querySelector('.player__next-video');

    if (this.cancelBtn) {
      this.cancelBtn.addEventListener('click', () => this.cancelNext());
    }

    if (this.partnersContainer) {
      this.player.addEventListener('mousemove', () => this.displayPartners());
      this.player.addEventListener('mouseleave', () => this.hidePartners(this.durationTimerPartner));
    }

    this.play(this.element);

    document.addEventListener('video:seek', ({ detail }) => {
      OPTIONS.startTimecode = Math.floor(detail.time);
      isResuming = OPTIONS.startTimecode > 0;

      this.initPlayer(this.element);
    });
  }

  play(element, autostart = false) {
    this.setRightsValidity(element);

    if (!this.hasRightsToPlay() || !this.rightsValid) {
      this.displayPopin(element);

      return;
    }

    this.removePopin(element);

    OPTIONS.autostart = autostart;
    OPTIONS.consent = getConsents();

    if (this.isUserAuthenticated) {
      getProgress(window.LUMNI_CONTEXT.item.slug);
    } else {
      this.initPlayer(element);
    }
  }

  initPlayer(element) {
    const Player = new this.Magnetoscope(element, OPTIONS);

    const _dispatchRecord = () => {
      if (!lockDispatchEvent) {
        lockDispatchEvent = true;

        document.dispatchEvent(
          new CustomEvent('navigation:video:record', {
            detail: {
              time: Player.getCurrentTime(),
              progress: Player.getCurrentProgress(),
              options: {
                isResuming,
              },
            },
          }),
        );

        setTimeout(() => {
          lockDispatchEvent = false;
        }, 500);
      }
    };

    Player.load({
      src: element.getAttribute('data-factoryid'),
      config: {
        tracking: getTrackingOptions(),
      },
    });

    Player.on('video_start', () => {
      this.onPlay();
    });

    Player.on('play', () => {
      _dispatchRecord();
      this.onPlay();

      const windowOrientation = window.matchMedia('(orientation: landscape)');
      setTimeout(() => {
        if (windowOrientation.matches && window.innerWidth < 1200) {
          Player.fullscreen(true);
        }
      }, 200);
    });

    Player.on('pause', () => {
      _dispatchRecord();
      this.onPause();
      document.dispatchEvent(new CustomEvent('player:video:pause'));
    });

    Player.on('seeked', () => {
      _dispatchRecord();
    });

    Player.on('timeupdate', () => {
      const currentTime = parseInt(Player.getCurrentTime(), 10);

      if (this.lastCurrentTime > 0
        && this.lastCurrentTime !== currentTime
        && currentTime % VIDEO_PLAYBACK_RECORDING_FREQUENCY_IN_SECONDS === 0) {
        _dispatchRecord();
      }

      this.lastCurrentTime = currentTime;
    });

    Player.on('ended', () => {
      this.onEnded(element);
    });

    document.addEventListener('fullscreenchange', () => this.onFullscreenchange());

    window.addEventListener('orientationchange', () => {
      Player.fullscreen(window.orientation !== 0);
    });

    localStorage.removeItem('lumni_video_card_info');
  }

  onPlay() {
    this.isPlayling = true;
    this.hidePartners();
  }

  onPause() {
    this.isPlayling = false;
    this.displayPartners();
  }

  onEnded(element) {
    this.isPlayling = false;
    this.displayTimer(element);
  }

  onFullscreenchange() {
    if (!this.partnersContainer) {
      return;
    }

    document.querySelector('.player__partner').classList.toggle('player__partner--fullscreen');
    document.querySelector('.proposed-by').classList.toggle('proposed-by--fullscreen');
    document.querySelector('.partner-with').classList.toggle('partner-with--fullscreen');

    if (!document.fullscreenElement && !document.webkitFullscreenElement) {
      this.displayPartners();
    }
  }

  displayPartners() {
    if (!this.partnersContainer) {
      return;
    }

    clearTimeout(this.timeoutPlayer);

    this.partnersContainer.classList.remove('player__partner--hide');

    this.timeoutPlayer = setTimeout(() => {
      this.partnersContainer.classList.add('player__partner--hide');
    }, 2700);
  }

  hidePartners(duration = 1000) {
    if (!this.partnersContainer) {
      return;
    }

    clearTimeout(this.timeoutPlayer);

    this.timeoutPlayer = setTimeout(() => {
      if (this.isPlayling) {
        this.partnersContainer.classList.add('player__partner--hide');
      }
    }, duration);
  }

  setRightsValidity(element) {
    const startRightsDate = element.getAttribute('data-startRightsDate');
    const endRightsDate = element.getAttribute('data-endRightsDate');

    if (!startRightsDate && !endRightsDate) {
      return;
    }

    const currentDate = new Date();
    const startDate = new Date(startRightsDate);
    const endDate = new Date(endRightsDate);

    if (endDate < startDate || startDate > currentDate || currentDate > endDate) {
      this.rightsValid = false;
    }
  }

  hasRightsToPlay() {
    return this.isContentProtected ? this.isUserAuthenticated : true;
  }

  displayTimer(element) {
    const playlistVideo = document.querySelector('#playlistVideo');

    if (!playlistVideo) {
      return;
    }

    const nextVideo = playlistVideo.querySelector('.playlist--container .card a').href;

    if (nextVideo) {
      document.querySelector('.player__overlay').classList.add('player__overlay--active');

      this.replay = false;
      this.nextVideoBtn.classList.add('player__next-video--active');
      this.nextVideoBtn.querySelector('a').href = nextVideo;
      this.setTimer(nextVideo);

      window.addEventListener('scroll', () => this.onScroll(element, nextVideo));
    }
  }

  setTimer(nextVideo) {
    this.isTimer = true;
    this.timer = setTimeout(() => {
      window.location.href = nextVideo;
    }, this.durationTimer);
  }

  clearTimer() {
    this.isTimer = false;
    clearTimeout(this.timer);
  }

  cancelNext() {
    this.replay = true;
    this.clearTimer();
    this.nextVideoBtn.classList.remove('player__next-video--active');
  }

  onScroll(element, nextVideo) {
    const rect = element.getBoundingClientRect();
    const top = rect.top + window.pageYOffset - document.documentElement.clientTop;
    const svg = element.querySelector('svg');

    if (element.getBoundingClientRect().top + top < 0) {
      this.clearTimer();
      svg.classList.remove('svg-autoplay-ring');
    } else {
      svg.classList.add('svg-autoplay-ring');
      if (!this.isTimer && !this.replay) this.setTimer(nextVideo);
      if (this.replay) this.clearTimer();
    }
  }

  displayPopin(element) {
    const popin = element.querySelector(this.popinSSOSelector);

    if (!popin) {
      return;
    }

    popin.classList.add('popin-content--show');
    popin.classList.remove('hidden');

    if (!this.hasRightsToPlay()) {
      popin.querySelector('.popin-title').innerHTML = 'Pour regarder la vidéo, connectez-vous';
    } else if (this.hasRightsToPlay() && !this.rightsValid) {
      popin.querySelector('.popin-title').innerHTML = 'Vidéo non disponible.';
      popin.querySelector('.popin-description').innerHTML = '';
      popin.querySelector('.connexion').innerHTML = '';
    }
  }

  removePopin(element) {
    const popin = element.querySelector(this.popinSSOSelector);

    if (popin !== null) {
      popin.remove();
    }
  }
}

export default PlayerJs;
